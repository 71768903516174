import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from '../../../Context/AuthUserContext';
import { Form, Input, Button, Alert, Space } from 'antd';
import { GoogleOutlined,MailOutlined,UserOutlined } from "@ant-design/icons";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier, updateProfile } from 'firebase/auth';
import { signInWithCredential, PhoneAuthProvider } from 'firebase/auth';
import axios from 'axios';
import { BASE_URL } from "../../../../config/axios-config";
import toastr from "toastr";


const CreateUserPhone = ({onButtonClick}) => {
  
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const[name,setName] = useState('');
  const [verificationId,setVerificationId] = useState(null);
  const { Fbuser,googleSignIn } = useUserAuth();
  const navigate = useNavigate();



  useEffect(() => {
    // Ensure Firebase Auth is initialized and reCAPTCHA container is ready
    if (!window.recaptchaVerifier) {
        const initializeRecaptcha = () => {
            const authDebug = getAuth();
          
            window.recaptchaVerifier = new RecaptchaVerifier(getAuth(),'recaptcha-container', {
              size: 'invisible',
              callback: (response) => {
                // reCAPTCHA verified
              }})}
      initializeRecaptcha()
    }
  }, []);
  
  const handleSendOtp = async() => {
    const auth = getAuth();

    const appVerifier = window.recaptchaVerifier;
    const phoneNumberInput = number; // Ensure phoneNumber includes country code
    const phoneExsist =  await checkifPhoneNumberExists (phoneNumberInput);
    
     if(phoneExsist != ''){
         console.error("PhoneNumber exsist");
         toastr.error("Phone Number exists")
       }
    else{
    signInWithPhoneNumber(auth, phoneNumberInput, appVerifier)
      .then((confirmationResult) => {
      
   
        setVerificationId(confirmationResult.verificationId);
      }).catch((error) => {
   
        console.log(error);
      });
    }
  
  };

  const verifyOtp = () => {
    const auth = getAuth();

    const credential = PhoneAuthProvider.credential(verificationId, otp);
    signInWithCredential(auth, credential)
        .then((result) => {
         
            const user = result.user
             updateProfile(user,{ displayName: name });
           
            user.reload().then(() => {
                console.log("reloaded user:" ,user); 
              });
        }).catch((error) => {
            
            console.error(error);
        });
};
const checkifPhoneNumberExists = async(phone)=>{
  try{
        
      const response = await axios.get(`${BASE_URL}/user/pn/${phone}`)
      
       return response.data;
  }
  catch(error){
    console.log("ErrorChecking Phone Number")
  }
}

  const handleGoogleSignIn = async() =>{
     await googleSignIn();
  }
  
  

  return (
    <>
     <div className="p-4 box">
        <h2 className="mb-3">Sign Up with Phone</h2>
        <Form
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input your Name!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} 
        placeholder="Enter your Name" 
        value={name}
        onChange={(e)=>setName(e.target.value)}/>
      </Form>
          <div id="recaptcha-container"></div>
     
        <Form layout="vertical"  >
          <Form.Item label="Phone Number">
            <Input
              value={number}
              onChange={(e)=>setNumber(e.target.value)}
           
              placeholder="Enter Phone Number"
             

            />
          
          </Form.Item>

          <Button type="primary" htmlType="submit" onClick={handleSendOtp}>
              Send Otp
            </Button>
            <Form.Item label='OTP'>
              
              <Input
              
              value={otp}
              onChange={(e)=>setOtp(e.target.value)}
              placeholder="Enter OTP"
              />
             
            </Form.Item>

            <Form.Item>
              <Button onClick={verifyOtp}>Verify OTP</Button>
            </Form.Item>
           
        <Form.Item>
        
        <Button
          type="default"
          icon={<GoogleOutlined />}
          className="login-form-google-button"
          onClick={handleGoogleSignIn}
        >
          Sign Up with Google
        </Button>
          
      </Form.Item>

      <Form.Item>
        
        <Button
          type="default"
          icon={<MailOutlined />}
          className="login-form-google-button" 
          onClick={() =>onButtonClick('signinemail')}
        >
          Sign Up with Mail
        </Button>
        
      </Form.Item>
      <div style={{ textAlign: 'left' }}>
          Already Have an Account?{" "}
          <Link onClick={() =>onButtonClick('phone')}>
            Sign In </Link>
            </div>
            </Form>
       
      </div>
    </>
  );
};

export default CreateUserPhone;
