import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DishService from "../../../services/DishService";
import {
  MainContainer,
  Container,
  List,
  DishImage,
  DishTitle,
  SubContainer,
  Chef,
  ChefName,
  ChefPhoto,
  ButtonContainer,
  NavigationButton,
  LikeContainer,
  LikeCount
} from "./StyledGrid";
import chefImage from "../../../assets/chef.png";
import { LikeOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useUserAuth } from "../../Context/AuthUserContext";
import toastr from "toastr";
import axios from "axios";
import { BASE_URL } from "../../../config/axios-config";
import MapModal from "../dish-map/MapModal";

const client = axios.create({
  baseURL: BASE_URL
});

function DishCard({ dish, route }) {
  const pathRoute = route ? route : "";
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [likedDishes, setLikedDishes] = useState([]);
  const [likesCount, setLikesCount] = useState({});
  const [mapModalIsOpen, setMapModalIsOpen] = useState(false);
  const { Fbuser } = useUserAuth();
  const currentUser = Fbuser ? Fbuser : null;
  const userId = currentUser ? currentUser.uid : null;

  useEffect(() => {
    const storedLikedDishes = localStorage.getItem("likedDishes");
    if (storedLikedDishes) {
      setLikedDishes(JSON.parse(storedLikedDishes));
    }

    return () => {
      localStorage.removeItem("likedDishes");
    };
  }, []);

  useEffect(() => {
    if (dish && dish._id) {
      const fetchLikesCount = async () => {
        try {
          const response = await client.get(`/dishes/${dish._id}/likes`);
          setLikesCount((prevLikesCount) => ({
            ...prevLikesCount,
            [dish._id]: response.data.likesCount
          }));
        } catch (error) {
          console.error(`Error fetching likes count for dish ${dish._id}:`, error);
        }
      };

      fetchLikesCount();
    }
  }, [dish]);

  useEffect(() => {
    localStorage.setItem("likedDishes", JSON.stringify(likedDishes));
  }, [likedDishes]);

  if (!dish) {
    return null;
  }

  const handleOpenMapModal = (e) => {
    e.preventDefault();
    setMapModalIsOpen(true);
  };

  const handleCloseMapModal = () => {
    setMapModalIsOpen(false);
  };

  const filteredImages = dish.thumbnail
    ? dish.thumbnail.filter((image) => image)
    : dish.images
    ? dish.images.filter((image) => image)
    : [];

  const totalImages = filteredImages.length;

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? totalImages - 1 : prevIndex - 1
    );
  };

  const handleLikeClick = (event, dishId) => {
    event.preventDefault();
    event.stopPropagation();
    if (currentUser) {

      if (!likedDishes.includes(dishId)) {
        client
          .post("/dishes/likeDish", { dishId, userId })
          .then((response) => {
            if (response.data.alreadyLiked) {
              toastr.error("You have already liked this dish!");
            } else if (response.data.firstLike) {
              toastr.success("Dish liked successfully!");
              setLikesCount((prevLikesCount) => ({
                ...prevLikesCount,
                [dishId]: (prevLikesCount[dishId] || 0) + 1
              }));
              setLikedDishes((prevLikedDishes) => [...prevLikedDishes, dishId]);
            } else {
             
              setLikesCount((prevLikesCount) => ({
                ...prevLikesCount,
                [dishId]: (prevLikesCount[dishId] || 0) + 1
              }));
            }
          })
          .catch((error) => {
            console.error("Error saving like:", error);
          });
      } else {
        toastr.error("You have already liked this dish!");
      }
    } else {
      console.log("Anonymous user clicked like");
    }
  };

  return (
    <MainContainer>
      <Container>
        <List>
          <Link
            to={{
              pathname: `${pathRoute}/dish/${dish._id}/${DishService.convertToSlug(dish.title)}`
            }}
            style={{ textDecoration: "none" }}
          >
            <SubContainer>
              <DishImage
                src={filteredImages[currentImageIndex]}
                alt={`Dish Thumbnail ${currentImageIndex}`}
              />
              <ButtonContainer>
                <NavigationButton
                  onClick={(e) => {
                    e.preventDefault();
                    handlePrevClick();
                  }}
                >
                  &lt;
                </NavigationButton>
                <NavigationButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleNextClick();
                  }}
                >
                  &gt;
                </NavigationButton>
              </ButtonContainer>
              <DishTitle>{dish.title}</DishTitle>
            </SubContainer>
            <Chef>
              <ChefName>
                By <b>{dish.chef.name}</b>
              </ChefName>
              <ChefPhoto
                src={dish.chef.photo || chefImage}
                alt={dish.chef.photo ? `Chef ${dish.chef.name}` : "Chef"}
              />
            </Chef>
            <LikeContainer>
              <LikeOutlined
                style={{ marginRight: "5px" }}
                onClick={(event) => handleLikeClick(event, dish._id)}
              />
              <LikeCount>{likesCount[dish._id] || 0}</LikeCount>
              <Tooltip title="View dish in map">
                <EnvironmentOutlined
                  style={{ marginLeft: "auto", cursor: "pointer" }}
                  onClick={handleOpenMapModal}
                />
              </Tooltip>
            </LikeContainer>
          </Link>
        </List>
      </Container>
      <MapModal
        isOpen={mapModalIsOpen}
        onRequestClose={handleCloseMapModal}
        dishLocation={dish.location}
      />
    </MainContainer>
  );
}

export default DishCard;
