import { PayPalButton } from "react-paypal-button-v2";
import React, { useContext, useState } from "react";
import PaymentService from "../../services/PaymentRazorService";
import OrderService from "../../services/OrderService";
import { DishContext } from "../../providers/DishProvider";

const PayPal = prop => {
  const [paid, setPaid] = useState(false);
  const value = useContext(DishContext);
  const [cart, setCart] = value.cart;
  const data = prop.datas;
  const total_price = prop.total;
  const saveOrder = details => {
    const orderData = {};
    orderData.payment = { id: details.id, method: "paypal" };
    orderData.data = data;
    OrderService.saveOrders(orderData).then(() => {
      setCart([]);
    });
  };
  const showMessage = () => {
    const currency = prop.currency_type;
    if (currency && currency !== "AUD") {
      alert(
        `your country currency type is ${currency},which is not activated,you are using AUD `
      );
    } else {
      return null;
    }
  };
  return (
    <PayPalButton
      amount={total_price}
      onButtonReady={() => showMessage()}
      onApprove={(data, actions) => {
        return actions.order.capture().then(function(details) {
         
          
          saveOrder(details);

          setPaid(true);
          PaymentService.savePayments(details);
          alert("payment succesfull");
        });
      }}
      onError={err => {
        alert(err);
      }}
      options={{
        clientId:
          "ATYrLrQTT-yYcc4RQJkvsZUM5-4XhDu5FdROkIY8UtL81ZTMYrHsLHsSzKxlL6C4bGXAbDsAaay688qX",
        currency: "AUD"
      }}
    ></PayPalButton>
  );
};
export default PayPal;
