
import React, { useState } from 'react';
import LogInFormEmail from '../components/LogIn_PopUp/LogInwithEmail/LogInFormEmail';
import SignUpFormEmail from '../components/LogIn_PopUp/SignUp/SignUpFormEmail';
import CreateUserPhone from '../components/LogIn_PopUp/Phone/PhoneSignUp/CreateUserPhone';
import SignInPhone from '../components/LogIn_PopUp/Phone/SignInUser/SignInPhone';
import ForgotPassword from '../components/LogIn_PopUp/ResetPassword/ForgotPassword';


const Display = ({ onClose }) => { 
  const [displayComponent, setDisplayComponent] = useState('');

  const handleButtonClick = (event) => {
    switch (event) {
        case 'signinemail':
        setDisplayComponent('signinemail');
        break;
      case 'phone':
        setDisplayComponent('phone');
        break;
      case 'signup':
        setDisplayComponent('signup');
        break;
      
      case 'signupPhone':
        setDisplayComponent('signupPhone');
        break;
      case 'forgotpassword':
        setDisplayComponent('forgotpassword')
      default:
        break;
    }
  };

  const handlePasswordReset = () => {
    
    setDisplayComponent('signinemail'); 
  };

  return (
    <div>
      {displayComponent === '' && (
        <LogInFormEmail onButtonClick={handleButtonClick} onClose={onClose} />
      )}
      {displayComponent === 'signup' && <SignUpFormEmail onButtonClick={handleButtonClick} />}
      {/* {displayComponent === 'phone' && <PhoneSignUp onButtonClick={handleButtonClick}/>} */}
      {displayComponent === 'phone' && <SignInPhone onButtonClick={handleButtonClick} />}
      {displayComponent === 'signupPhone' && <CreateUserPhone onButtonClick={handleButtonClick} />}
      {displayComponent === 'signinemail' && (<LogInFormEmail onButtonClick={handleButtonClick} onClose={onClose} />)}
      {displayComponent === 'forgotpassword' && (<ForgotPassword onButtonClick={handleButtonClick} onClose={onClose}  onPasswordReset={handlePasswordReset}/>)}
    </div>
  );
};

export default Display;
