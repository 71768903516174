import styled from "styled-components";

export const GridLayout = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 30rem);
  margin: auto;
  @media only screen and (max-width: 750px) {
    width: 100%;
    margin: auto;
  }
`;
export const Container = styled.div`
display: grid;
margin:1rem;
grid-template-columns: 2fr 3fr;
box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
padding: 3px;
}   
`;
export const DataContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 11px;
  ul {
    list-style: none;
  }
  span {
    font-size: 14px;
    margin: 0px;
    list-style: none;
    font-family: "Open Sans", sans-serif;
  }
`;
export const DishImageContainer = styled.img`
  width: 100%;
  height: 80%;
  margin: auto;
`;
export const ButtonContainer = styled.div`
  // padding-top: 150px;
  button {
    margin: 2px;
    background-color: #ff8c00;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    margin: 2px 1px;
    cursor: pointer;
  }
`;
