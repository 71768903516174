import axios from 'axios';
import { BASE_URL } from '../config/axios-config'

const client = axios.create({
    baseURL: BASE_URL,
})

export const updateUser = payload => client.post(`/user`, payload)
export const getAllUsers = () => client.get(`/users`)
export const updateUserById = (id, payload) => client.put(`/user/${id}`, payload)
export const getUserByUid = id => client.get(`/user/${id}`)
export const getUserByPhone = id => client.get(`/user/pn/${id}`)

const UsersClientApi = {
    updateUser,
    getAllUsers,
    getUserByUid,
    updateUserById,
    getUserByPhone
}

export default UsersClientApi