import styled from "styled-components";

export const ChatContainer = styled.div`
  width: 70%;
  height: 500px;
  margin: auto;
  background: whitesmoke;
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;
export const Header = styled.div`
  width: 100%;
  height: 70px;
  margin: auto;
  background: #ff8c00;
  color: #fff;
  text-align: center;
  h4 {
    font-size: 30px;
    margin-top: 10px;
  }
`;
export const Container = styled.div`
  height: 500px;
  width: 400px;
  background: #ffdead;
  margin: auto;
  overflow-y: scroll;
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;
export const SubContainer = styled.div`
  overflow: hidden;
`;

export const ChatList = styled.div`
  height: 60px;
  background: white;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid black;
  box-shadow: 3px 3px 3px #888888;
`;

export const ChatImg = styled.img`
  width: 50px;
  height: 50px;
  color: green;
  border-radius: 50%;
  border: 1px solid green;
`;

export const ChatLeft = styled.div`
  float: left;
  width: 50px;
  height: 50px;
  color: green;
  border-radius: 50%;
  background: orange;
`;
export const ChatRight = styled.div``;
export const ChatRightTop = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 2px;
`;

export const ContactName = styled.div`
  font-size: 1.2rem;
  color: green;
  list-style: none;
`;

export const ChatDate = styled.span`
  color: #777;
  display: block;
  font-size: 12px;
`;
export const Notification = styled.span`
  background: green;
  border-radius: 50%;
  color: white;
  text-align: center;
  width: 20px;
  height: 20px;
`;

export const ChatRightBottom = styled.div`
  overflow: hidden;
  i {
    width: auto;
    height: 20px;
  }
`;

/* ====================chat room styled components start here ==========================*/

export const RoomContainer = styled.div`
  max-width: 600px!important;
  height: auto;
  margin: auto;
  font-family: sans-serif;
  letter-spacing: 0.5ox;
  @media only screen and (max-width: 500px) {
    .container {
      width: 100%;
    }
`;
export const MsgHeader = styled.div`
  border: 1px solid #ccc;
  width: 100%;
  height: 80px;
  border-bottom: none;
  display: inline-flex;
  background-color: orange;
`;

export const LeftArrow = styled.div`
  width: 25px;
`;

export const BackButton = styled.img`
  margin-top: 40px;
`;
export const MsgHeaderImg = styled.div`
  border-radius: 50%;
  width: 40px;
  margin-top: 12px;
  float: left;
`;

export const Profile = styled.img`
  width: 40px;
  height: 40px;
  border: 2px solid green;
  border-radius: 50%;
`;

export const Active = styled.div`
  width: auto;
  float: left;
  margin-top: 10px;

  h4 {
    font-size: 20px;
    margin-left: 10px;
    margin-top: 15px;
    color: black;
  }
`;
export const ChatPage = styled.div`
  padding: 0 0 0 0;
  overflow-y: scroll;
  height: 350px;
`;
export const ReceivedMsg = styled.div`
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
`;
export const ReceivedMsgInbox = styled.div`
  width: 52%;

  p {
    display: block;
    background: #fff none repeat scroll;
    border: 2px solid green;
    border-radius: 10px;
    color: black;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
    overflow-wrap: break-word;
  }
  span {
    color: #777;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
  }
`;
export const OutGoingChats = styled.div`
  margin: 26px 20px;
`;

export const OutGoingChatMsg = styled.div`
  float: left;
  width: 46%;
  margin-left: 45%;
  p {
    background: #fff none repeat scroll 0 0;
    border: 2px solid orange;
    color: black;
    border-radius: 10px;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
    overflow-wrap: break-word;
  }
  span {
    color: #777;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
  }
`;

export const InputDiv = styled.div`
  background: green;
  height: 90px;
`;
export const MessageInput = styled.input`
  margin-top: 15px;
  outline: none !important;
  border-radius: 5px;
  width: 80%;
  height: 40px;
  background: #fff;
`;
