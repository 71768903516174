import React, { useState, useEffect, useContext } from "react";
import DialogLogin, { LOGIN_ID } from "../dialogs/DialogLogin/DialogLogin";
import { SpinnerContext } from "../../providers/SpinnerProvider";
import UserService from "../../services/UserService";

const LoginComponent = ({ modalOpen, isModalOpen }) => {
  const [tempData, setTempData] = useState(null);
  const spinner = useContext(SpinnerContext);

  useEffect(() => {
    if (!modalOpen) {
      setTempData(null);
    }
  }, [modalOpen]);

  const handleSubmit = (id, data) => {
    switch (id) {
      case LOGIN_ID.SIGNIN:
        if (data.otpCode) {
          UserService.verifyPhoneOtp(data.otpCode, null, spinner);
        } else if (data.phoneNumber) {
          sendPhoneOTP(id, data.phoneNumber);
          setTempData(data);
        } else if (data.email && data.password) {
          UserService.signInViaEmail(data, spinner);
        }
        break;
      case LOGIN_ID.SIGNUP:
        var suEmail = data.email && data.password;
        var suPhone = data.phoneNumber;
        var suName = data.firstName && data.lastName;
        if (data.otpCode) {
          UserService.verifyPhoneOtp(data.otpCode, tempData, spinner);
        } else if (suName && suEmail) {
          UserService.signUpViaEmail(data, spinner);
        } else if (suName && suPhone) {
          sendPhoneOTP(id, data.phoneNumber);
          setTempData(data);
        }
        break;
      case LOGIN_ID.RESET:
        if (data.email) {
          (async () => {
            await UserService.sendResetPwEmail(data.email, spinner);
            isModalOpen(false);
          })();
        }
        break;
      default:
        isModalOpen(false);
        break;
    }
  };

  const sendPhoneOTP = (id, phoneNumber) => {
    var pn = phoneNumber
      ? phoneNumber
      : tempData && tempData.phoneNumber
      ? tempData.phoneNumber
      : null;
    if (!pn) return;
    if (id === LOGIN_ID.SIGNIN) {
      (async () => {
        const otpSent = await UserService.signInViaPhone(pn, spinner);
        if (!otpSent) {
          isModalOpen(false);
        }
      })();
    } else {
      UserService.sendPhoneVerification(pn, spinner);
    }
  };

  return (
    <DialogLogin
      modalOpen={modalOpen}
      handleModal={isModalOpen}
      handleSubmit={handleSubmit}
      sendPhoneOTP={sendPhoneOTP}
      googleSignIn={() => UserService.signInWithGoogle(spinner)}
    />
  );
};

export default LoginComponent;
