import React from "react";
import styled from "styled-components";
import { HeartOutlined, HeartFilled } from '@ant-design/icons';

const Default = styled.button.attrs({
  className: "btn"
})`
  margin-left: 0.5em;
  padding: 0.5vw 3vw;
  font-weight: 500;
  font-size: 1vw;
  outline: none;
  box-shadow: none !important;
  transition: 0.3s;
  ${props =>
    `
    border: 1px solid ${props.tertiary ? "#28a745" : "#f09133"};
    background: ${
      props.secondary ? "white" : props.tertiary ? "#28a745" : "#f09133"
    };
    color: ${props.secondary ? "#f09133" : "white"};
  `}

  :hover:not(:disabled) {
    opacity: 0.8;
    ${props =>
      `
    background: ${props.tertiary ? "#28a745" : "#f09133"};
    color: white;
  `}
  }

  :disabled {
    opacity: 0.5;
    color: ${props => (props.secondary ? "#f09133" : "white")};
  }

  @media screen and (max-width: 768px) {
    font-size: 4vw;
    padding: 1vw 5vw;
  }
`;

const Icon = styled.button.attrs({
  className: "btn"
})`
  margin: 0 !important;
  background-color: #ffffff;
  padding: 0;
  width: 100%;
  max-width: 40px;
  height: auto;
  color: #f09133;
  font-size: calc(12px + 1vw);
  outline: none;
  box-shadow: none !important;

  :hover {
    opacity: 0.8;
    color: #f09133;
  }

  :disabled {
    opacity: 0.5;
  }
  @media screen and (max-width: 768px) {
    max-width: 25px;
  }
`;

const Span = styled.span`
  font-size: calc(12px + 0.2vw);
  float: right;
  color: gray;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: #f09133 !important;
  }
`;

const Button = ({ ...props }) => {
  return (
    <Default {...props}>
      {props.children ? props.children : props.label}
    </Default>
  );
};

// const IconButton = ({ icon, ...props }) => {
//   return (
//     <Icon {...props}>
//       <i className={icon} />
//       {props.label}
//     </Icon>
//   );
// };

const IconButton = ({ icon, ...props }) => {
  return (
    <Default {...props}>
      {icon === "heart" ? 
        (props.liked ? <HeartFilled /> : <HeartOutlined />) 
        : <i className={icon} />
      }
      {props.label}
    </Default>
  );
};

const IconLink = ({ icon, ...props }) => {
  return (
    <Span {...props}>
      <i className={icon} />
      &ensp;{props.label}
    </Span>
  );
};

export { Button, IconButton, IconLink };
