import axios from "axios";
import { BASE_URL } from "../config/axios-config";

const client = axios.create({
  baseURL: BASE_URL,
});

export const savePayments = (payload) => client.post(`/payments`, payload);
export const getPaymentDetails = () => client.get(`/payments`);

const PaymentClientApi = {
  savePayments,
  getPaymentDetails
};

export default PaymentClientApi;
