
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import UserService from "../../../services/UserService";

Modal.setAppElement("#root");

const MapModal = ({ isOpen, onRequestClose, google,dishLocation}) => {
  const [center, setCenter] = useState(null);
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  useEffect(() => {
    setNetwork(window.navigator.onLine);
  }, []);

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const userLocation = await UserService.getUserLocation();
        setCenter(userLocation);
      } catch (error) {
        console.error("Error fetching user location:", error);
      }
    };

    if (isOpen) {
      fetchUserLocation();
    }
  }, [isOpen]);

  // console.log('dishlocation in map',dishLocation)


  const mapStyles = {
    width: "100%",
    height: "100%"
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Map Modal"
      style={{
        content: {
          width: "500px",
          height: "300px"
        }
      }}
    >
      {center && isOnline ? (
        <Map
          google={google}
          zoom={14}
          style={mapStyles}
          initialCenter={center}
          center={center}
        >
          <Marker
            position={center}
            icon={{
              url: require("../../../assets/user_marker.png"),
              anchor: new window.google.maps.Point(32, 32),
              scaledSize: new window.google.maps.Size(60, 60)
            }}
          />
          {dishLocation && (
            <Marker
              position={dishLocation}
              icon={{
                url: require("../../../assets/dish_marker.png"),
                anchor: new window.google.maps.Point(32, 32),
                scaledSize: new window.google.maps.Size(60, 60)
              }}
            />
          )}
        </Map>
      ) : (
        <div>No user location available</div>
      )}
    </Modal>
  );
};

export default GoogleApiWrapper({
  // apiKey: "AIzaSyAczB1_9C0dgfYS6iW4HTcVF0Vs8lWYVxc"
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
 
})(MapModal);









