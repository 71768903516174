import React, { useEffect, useState } from "react";
import ChatRoom from "./ChatRoom";
import ChatService from "../../services/ChatService";
import {
  ChatPopup,
  ChatContainer,
  MsgHeader,
  MsgHeaderImg,
  Profile,
  Active,
  BackArrow
} from "./StyledChat";
import { CHAT_ROOM, database } from "../../config/firebase";
import {ref,query,orderByChild,equalTo} from "firebase/database"

function Chat(props) {
  const config = props.config;
  const [showChatBox, setShowChatBox] = useState(false);
  const toggleChatBox = () => setShowChatBox(!showChatBox);
  const [room, setRoom] = useState({ roomId: "", users: [{}] });
  const [chef, setChef] = useState({});
 const Roomref = ref(database,CHAT_ROOM)
  useEffect(() => {
    const user = config.currentUser;
    const chef = config.chef;

    const room = {};
    room.roomId = [user.uid,chef.uid]
      .sort()
      .join("")
      // .toLocaleLowerCase();
    room.users = [user, chef].sort();
   
    setRoom(room);
    setShowChatBox(config.visible);
    setChef(config.chef);
  }, [config]);

  useEffect(() => {
    if (showChatBox) {
     
      ChatService.enterRoom(room);
     ;
    }
  }, [showChatBox]);

 

  return (
    <div>
      {showChatBox ? (
        <ChatPopup>
          <ChatContainer>
            <MsgHeader>
              <MsgHeaderImg>
                <Profile
                  className="profile"
                  src="https://joeschmoe.io/api/v1/user"
                />
              </MsgHeaderImg>
              <Active>
                <h4>
                  <b>{chef.name}</b>
                </h4>
              </Active>
              <BackArrow onClick={toggleChatBox}>
                <img src="https://www.flaticon.com/svg/static/icons/svg/25/25415.svg" />
              </BackArrow>
            </MsgHeader>
            <ChatRoom
              receiverId = {chef.uid}
              roomId={room.roomId}
              userId={config.currentUser.uid}
            ></ChatRoom>
          </ChatContainer>
        </ChatPopup>
      ) : null}
    </div>
  );
}

export default Chat;
