import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Geocode from "../../config/geocode";
import SettingsService from "../../services/SettingsService";

const InputContainer = styled.div`
  margin: 15px 0;
  position: relative;

  .form-control {
    padding: 0;
  }

  .btn-outline-secondary {
    box-shadow: none;
    :hover {
      color: white;
      background-color: green;
    }
  }

  input {
    font-size: 0.9em !important;
    width: 100%;
    height: calc(1.5em + 0.65rem + 2px);
    padding: 0.35rem 0.75rem;
    border: 0;
    outline: none;
    border-radius: 0.3rem;
  }

  ${props =>
    props.focused &&
    `
      .input-group-text, .form-control, .btn-outline-secondary {
        border: 1px solid ${props.error ? "red" : "green"} !important;
      }
      .btn-outline-secondary{
        color: ${props.error ? "red" : "green"};
      }
    `}

  @media screen and (max-width: 768px) {
    input {
      font-size: 0.8em !important;
    }
  }
`;

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  position: relative;

  & > textarea {
    height: auto;
    border: 1px solid
      ${props => (props.focused ? (props.error ? "red" : "green") : "#ced4da")};
    border-radius: 0.25rem;
    background-color: transparent;
    outline: none;
    padding: 12px 3px 12px 15px;
    font-size: 1em;
    transition: all 0.2s ease;
    z-index: 500;

    :disabled {
      background-color: #f5f5f5b9;
    }
  }

  @media screen and (max-width: 768px) {
    textarea {
      font-size: 0.8em !important;
    }
  }
`;

const Label = styled.label`
  color: #757575;
  position: absolute;
  top: 7px;
  left: 15px;
  transition: all 0.2s ease;
  z-index: 1;

  ${props =>
      props.focused &&
      `
      top: 12px;
      font-size: 13px;
      transform: translateY(-23px) translateX(-5px);
      z-index: 1;
      background: white;
      padding: 0 8px;
      font-weight: 500;
      color: ${props.error ? "red" : "#f09133"};
    `}
    :after {
    ${props =>
      props.focused &&
      props.required &&
      !props.error &&
      ` content: '*';
          color:red;
      `}
  }
`;

const Append = props => (
  <div className="input-group-append">
    {props.text ? (
      <span className="input-group-text" id="basic-addon1">
        {props.text}
      </span>
    ) : props.type === "password" ? (
      <button
        className="btn btn-outline-secondary"
        type="button"
        title={props.show ? "Hide password" : "Show password"}
        onClick={props.onClick}
      >
        <i className={props.show ? "fa fa-eye" : "fa fa-eye-slash"}></i>
      </button>
    ) : (
      <button
        className="btn btn-outline-secondary"
        type="button"
        title="Current Location"
        {...props}
      >
        <i className="fa fa-location-arrow"></i>
      </button>
    )}
  </div>
);

const Prepend = props => {
  const type = props.type;
  const initTxt = props.text ? props.text : "";
  const [text, setText] = useState(initTxt);

  useEffect(() => {
    (async () => {
      if (type === "tel") {
        const data = await SettingsService.getCountryByCode();
        setText(data.phone_code ? `+${data.phone_code}` : `+61`);
        return;
      }
    })();
  }, [type]);

  return (
    <div className="input-group-prepend">
      <span className="input-group-text">{text}</span>
    </div>
  );
};

/**
 * A custom input component
 * @param {string} label - the label used to designate info on how to fill out the input
 * @param {string} type - the type of the current field
 * @param {string} value - the value of the current field
 * @param {string} placeholder - the placeholder of the current field when on blur
 * @param {function} onChange - function called when the input value changes
 * @param {function} onFocus - function called when the input is focused
 * @param {function} onBlur - function called when the input loses focus
 * @param {function} setRef - function used to add this input as a ref for a parent component
 */
const Input = ({
  value,
  type,
  label,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  setRef,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState(null);
  const [showPw, setShowPw] = useState(false);

  const handleOnFocus = e => {
    e.target.placeholder = placeholder ? placeholder : "";
    setFocused(true);
    onFocus();
  };

  const handleOnBlur = e => {
    e.target.placeholder = "";
    setFocused(false);
    onBlur();
  };

  const handleOnChange = e => {
    validateValue(e);
    onChange(e);
  };

  const validateValue = e => {
    e.preventDefault();
    let value = e.target.value;

    if (props.required && !value) {
      setError(`${label} is required.`);
    } else {
      switch (type) {
        case "email": {
          if (value.indexOf("@") === -1) {
            return setError("Email is invalid");
          } else {
            return setError(null);
          }
        }
        case "percent": {
          if (value && (value > 100 || value < 1)) {
            return setError("Value must be less than or equal to 100.");
          } else {
            return setError(null);
          }
        }
        case "location": {
          if (value) {
            return Geocode.fromAddress(value).then(
              () => setError(null),
              () => setError("Please provide a valid location.")
            );
          }
          return setError(null);
        }
        default: {
          return setError(null);
        }
      }
    }

    // ... any other validation you could think of
    // ... maybe even pass in an additional validation function as a prop?
  };

  const handleLocationClick = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        let coords = position.coords;
        Geocode.fromLatLng(coords.latitude, coords.longitude).then(
          response => {
            const address = response.results[0].formatted_address;
            onChange({ [props.name]: address });
          },
          error => {
            setError("Error in getting location.");
            console.error(error);
          }
        );
      },
      error => {
        setError("Error in getting location.");
        console.error(error);
      }
    );
  };

  const renderLabel = () => {
    if (label) {
      return (
        <Label focused={isFocused} error={error} required={props.required}>
          {error ? error : label}
        </Label>
      );
    }
    return null;
  };

  const renderAppend = () => {
    switch (type) {
      case "percent": {
        return <Append text="%" />;
      }
      case "location": {
        return <Append type={type} onClick={handleLocationClick} />;
      }
      case "password": {
        return (
          <Append
            type={type}
            show={showPw}
            onClick={() => setShowPw(!showPw)}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderPrepend = () => {
    switch (type) {
      case "tel": {
        return <Prepend type={type} />;
      }
      default: {
        return null;
      }
    }
  };

  const validateType = () => {
    switch (type) {
      case "percent": {
        return {
          type: "number",
          min: "0",
          max: "100",
          step: "1",
          maxLength: "3"
        };
      }
      case "location": {
        return {
          type: "text"
        };
      }
      case "password": {
        return {
          type: showPw ? "text" : "password"
        };
      }
      default: {
        return { type: type };
      }
    }
  };

  const isFocused = focused || String(value).length || type === "date";

  return type === "textarea" ? (
    <TextAreaContainer
      focused={isFocused}
      error={error}
      required={props.required}
    >
      {renderLabel()}
      <textarea
        rows="2"
        value={value}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        ref={ref => setRef(ref)}
        {...props}
      />
    </TextAreaContainer>
  ) : (
    <InputContainer focused={isFocused} error={error} required={props.required}>
      <div className="input-group">
        {renderPrepend()}
        <div className="form-control">
          {renderLabel()}
          <input
            value={value || ""}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            ref={ref => setRef(ref)}
            {...validateType()}
            {...props}
          />
        </div>
        {renderAppend()}
      </div>
    </InputContainer>
  );
};

Input.defaultProps = {
  value: "",
  type: "text",
  label: "",
  placeholder: "",
  onChange: text => {
    console.error(`Missing onChange prop: ${text}`);
  },
  onFocus: () => {},
  onBlur: () => {},
  setRef: () => {}
};

export default Input;
