import OrdersClientApi from "../client-api/OrdersClientApi";
import UserService from "./UserService";
import AuditLogService from "./AuditLogService";
import { OPERATION_ID, STATUS } from "../constants/AuditLogs.constants";
import { USER_TYPE } from "../constants/User.constants";
import Order from "../models/Order.model";
import toastr from "toastr";
import Moment from "moment";

const OrderService = {
  getOrdersByUser: async type => {
    const user = await UserService.getFbCurrentUser();

    if (type === USER_TYPE.CHEF) {
      const data = await OrdersClientApi.getOrdersByChef(user.uid)
        .then(result => {
          AuditLogService.sendLog(STATUS.SUCCESS, OPERATION_ID.GET_ORDERS_CHEF);
          return result.data;
        })
        .catch(error => {
          AuditLogService.sendLog(
            STATUS.FAILED,
            OPERATION_ID.GET_ORDERS_USER,
            error
          );
          return null;
        });
      return data;
    } else {
      
      const data = await OrdersClientApi.getOrdersByUser(user.uid)
        .then(result => {
          AuditLogService.sendLog(STATUS.SUCCESS, OPERATION_ID.GET_ORDERS_CHEF);
  
          return result.data;
        })
        .catch(error => {
          AuditLogService.sendLog(
            STATUS.FAILED,
            OPERATION_ID.GET_ORDERS_USER,
            error
          );
          return null;
        });
      return data;
    }
  },


  saveOrders: async (data) => {
    console.log('saveOrders function called');
    console.log('orderdata',data)

    const user = await UserService.getFbCurrentUser();
    let payment = data.payment;
    let orders = data.data;
    let currency = data.currency
    console.log('currency',currency)
    console.log('orders',orders)

    for (let i = 0; i < orders.length; i++) {
      let item = orders[i];
     
      let total = parseInt(item.price) * item.count;
      const order = new Order(
        item._id,
        user.uid,
        Moment(new Date()).format("DD/MM/YYYY | hh:mm:ss"),
        item.count,
        total,
        currency,
        { id: payment.id, method: payment.method },
        item.chef.uid,
        "",
        "NEW",
        "Not Paid",
        " "
      );
  
      const success = await OrdersClientApi.saveOrders(order)
        .then(result => {
          AuditLogService.sendLog(STATUS.SUCCESS, OPERATION_ID.CREATE_ORDER);
          toastr.success("You order the dish successfully!");
          return result.data;
        })
        .catch(error => {
          AuditLogService.sendLog(
            STATUS.FAILED,
            OPERATION_ID.CREATE_ORDER,
            error
          );
          console.log("***Error from saveDish:", error);
          toastr.error("***Error from saveDish : check console");
          return null;
        });
    }
  },

  updateOrderStatus: async (id, status, spinner) => {
    spinner.show();
    const success = await OrdersClientApi.updateOrderStatus(id, status)
      .then(result => {
        AuditLogService.sendLog(
          STATUS.SUCCESS,
          OPERATION_ID.UPDATE_ORDER_STATUS,
          `${OPERATION_ID.UPDATE_ORDER_STATUS} -- Order ID:${id} = ${status.status}`
        );
        spinner.hide();
        toastr.success(`Order ID: ${id} has been updated.`);
        return result.data;
      })
      .catch(error => {
        AuditLogService.sendLog(
          STATUS.FAILED,
          OPERATION_ID.UPDATE_ORDER_STATUS,
          error
        );
        spinner.hide();
        toastr.error("An error occured in updating order status.");
        return false;
      });
    return success;
  }
};

export default OrderService;
