import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "toastr/build/toastr.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AppRoute from "./AppRoute";
import UserProvider from "./providers/UserProvider";
import DishProvider from "./providers/DishProvider";
import SpinnerProvider from "./providers/SpinnerProvider";
import { UserAuthContextProvider } from "./components/Context/AuthUserContext";


function App() {
  
  return (
    <div>
      
       <SpinnerProvider>
        <UserAuthContextProvider>
        <UserProvider>
          <DishProvider>
            <div className="grid">
              <AppRoute />
            </div>
          </DishProvider>
        </UserProvider>
        </UserAuthContextProvider>
      </SpinnerProvider>  
    </div>
  );
}

export default App;
