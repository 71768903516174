import profile from "./UserProfile.route";
import landingPage from "./Home.route";
import dishDetails from "./DishDetails.route";
import dishesNearMe from "./DishesNearMe.route";
import AddDish from "./AddDish.route";
import Cart from "./Cart.route";
import MyKitchen from "./MyKitchen.route";
import MyDishDetails from "./MyDishDetails.route";
import Checkout from "./Checkout.route";
import MyOrder from "./MyOrder.route";
import MySales from "./MySales.route";
import ChatMessage from "./ChatMessage.route";
import MyChatRoom from "./MyChatRoom.route";
import MyFavourite from "./MyFavourite.route";
import TermsAndConditions from "./TermsConditions.route";

export default [
  profile,
  landingPage,
  dishDetails,
  dishesNearMe,
  AddDish,
  Cart,
  MyKitchen,
  MyDishDetails,
  Checkout,
  MyOrder,
  MySales,
  ChatMessage,
  MyChatRoom,
  MyFavourite,
  TermsAndConditions
];