export const emailPw = [
  {
    name: "email",
    label: "Email",
    type: "email",
    required: true
  },
  {
    name: "password",
    label: "password",
    type: "password",
    pattern: ".{8,16}",
    title: "8 characters minimum to 16 charecters maximum",
    required: true
  }

];

export const email = [
  {
    name: "email",
    label: "Email",
    type: "email",
    required: true
  }
];

export const phone = [
  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "tel",
    required: true,
    pattern: "^[0-9]*$",
    title: "Numbers only"
  }
];

export const otp = [
  {
    name: "otpCode",
    label: "Verification Code",
    type: "text",
    required: true,
    maxLength: "6",
    minLength: "6",
    pattern: "\\d*",
    title: "Enter the 6 digit code"
  }
];

export const name = [
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    required: true,
    maxLength: "30",
    minLength:"2",
    pattern: "^[A-Za-z ]+$",
    title:"Please enter minimum 2 characters and 30 characters maximum"
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    required: true,
    maxLength: "30",
    minLength:"2",
    pattern: "^[A-Za-z ]+$",
    title:"Please enter minimum 2 characters and 30 characters maximum"
  }
];
