import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { DishContext } from "../../../providers/DishProvider";

function PaginationComponent({ dishLength, setPageNumber }) {
  const value = useContext(DishContext);
  const [refresh] = value.refresh;
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [pageNumberLimit] = useState(3);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(3);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = event => {
    setcurrentPage(Number(event.target.id));
  };

  useEffect(() => {
    setPageNumber(currentPage - 1);
  }, [currentPage]);
  const pages = [];
  for (let i = 1; i <= Math.ceil(dishLength / itemsPerPage); i++) {
    pages.push(i);
  }
  useEffect(() => {
    setcurrentPage(1);
  }, [refresh]);

  const renderPageNumbers = pages.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;

  let pageDecrementBtn = null;

  return (
    <>
      <ul className="pageNumbers">
        <li>
          {currentPage !== 1 && (
            <button
              onClick={handlePrevbtn}
              disabled={currentPage === pages[0] ? true : false}
            >
              Prev
            </button>
          )}
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

        <li>
          {currentPage !== pages[pages.length - 1] && (
            <button
              onClick={handleNextbtn}
              disabled={currentPage === pages[pages.length - 1] ? true : false}
            >
              Next
            </button>
          )}
        </li>
      </ul>
    </>
  );
}

export default PaginationComponent;
