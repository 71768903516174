import React, { useState, useContext } from "react";
import {
  IconButton,
  Input,
  InputList,
  InputFile,
  Button
} from "../styled-components";
import styled from "styled-components";
import { SpinnerContext } from "../../providers/SpinnerProvider";
import DishService from "../../services/DishService";

const InputCellTitle = ({ value, handleInputChange }) => {
  const [edit, showEdit] = useState(false);

  return (
    <>
      <td colSpan="2">
        {edit ? (
          <Input
            label="Title"
            name="title"
            required={true}
            value={value}
            onChange={handleInputChange}
          />
        ) : (
          <span className="title-text">{value}</span>
        )}
      </td>
      <td>
        <IconButton
          icon={edit ? "fa fa-window-close" : "fas fa-edit"}
          onClick={() => showEdit(!edit)}
        />
      </td>
    </>
  );
};

const InputCell = ({ label, value, handleInputChange, ...props }) => {
  const [edit, showEdit] = useState(false);

  return (
    <>
      {edit ? (
        <td colSpan="2">
          <Input
            label={label}
            value={value}
            onChange={handleInputChange}
            {...props}
          />
        </td>
      ) : (
        <>
          <td>
            <b>{label}:</b>
          </td>
          <td>{value}</td>
        </>
      )}
      <td>
        <IconButton
          icon={edit ? "fa fa-window-close" : "fas fa-edit"}
          onClick={() => showEdit(!edit)}
        />
      </td>
    </>
  );
};

const InputListCell = ({ label, value, handleInputChange, ...props }) => {
  const [edit, showEdit] = useState(false);

  return (
    <>
      {edit ? (
        <td colSpan="2">
          <InputList
            label={label}
            value={value}
            onChange={handleInputChange}
            {...props}
          />
        </td>
      ) : (
        <>
          <td>
            <b>{label}:</b>
          </td>
          <td>
            <ul>
              {value.map((ing, i) => (
                <li key={i}>{ing}</li>
              ))}
            </ul>
          </td>
        </>
      )}
      <td>
        <IconButton
          icon={edit ? "fa fa-window-close" : "fas fa-edit"}
          onClick={() => showEdit(!edit)}
        />
      </td>
    </>
  );
};

const InputFileCell = ({ label, value, handleInputChange, ...props }) => {
  const [edit, showEdit] = useState(false);
  const spinner = useContext(SpinnerContext);
  const [collectedFiles, setCollectedFiles] = useState([]);

  // const config = {
  //   bucketName: "contextflow",
  //   dirName: "image",
  //   region: "us-east-2",
  //   accessKeyId: "AKIAZJZHX25XX7LE6UEQ",
  //   secretAccessKey: "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"
  // };

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  };

  const handleUploadToAws = async () => {
    const fileList = collectedFiles;
    const uploadedImageUrls = [];

    if (fileList && fileList.length > 0) {
      for (const blobUrl of fileList) {
        const response = await fetch(blobUrl);
        const blob = await response.blob();

        const file = new File([blob], "filename");

        const imgurl = await DishService.uploadImageToaws(
          config,
          file,
          spinner
        );

        uploadedImageUrls.push(imgurl);
      }

      const updatedValue = [...value, ...uploadedImageUrls];

      const customEvent = {
        target: { name: "images", value: updatedValue }
      };
      handleInputChange(customEvent);
    }
  };

  return (
    <>
      {edit ? (
        <td colSpan="2">
          <InputFile
            label={label}
            value={value}
            multiple={true}
            limit={4}
            collection={collectedFiles}
            setCollection={setCollectedFiles}
            {...props}
          />
          <Button label="Upload" onClick={handleUploadToAws} />
        </td>
      ) : (
        <>
          <td>
            <b>{label}:</b>
          </td>
          <td></td>
        </>
      )}
      <td>
        <IconButton
          icon={edit ? "fa fa-window-close" : "fas fa-upload"}
          onClick={() => showEdit(!edit)}
        />
      </td>
    </>
  );
};

const NutrientsCell = ({ nutrients, handleInputChange }) => {
  const [edit, showEdit] = useState(false);
  const [localNutrients, setLocalNutrients] = useState({ ...nutrients });

  const handleNutrientChange = e => {
    const { name, value } = e.target;

    const parsedValue =
      name === "proteins" || name === "fats" || name === "carbs"
        ? parseFloat(value)
        : value;

    setLocalNutrients(prev => ({
      ...prev,
      [name]: parsedValue
    }));
  };

  const handleSave = () => {
    const numericNutrients = {
      ...localNutrients,
      proteins: parseFloat(localNutrients.proteins),
      fats: parseFloat(localNutrients.fats),
      carbs: parseFloat(localNutrients.carbs)
    };

    const customEvent = {
      target: { name: "nutrients", value: numericNutrients }
    };
    handleInputChange(customEvent);
    showEdit(false);
  };

  return (
    <>
      {edit ? (
        <>
          <td colSpan="2">
            <label>
              Proteins:
              <input
                type="number"
                name="proteins"
                value={localNutrients.proteins}
                onChange={handleNutrientChange}
              />
            </label>
            <label>
              Fats:
              <input
                type="number"
                name="fats"
                value={localNutrients.fats}
                onChange={handleNutrientChange}
              />
            </label>
            <label>
              Carbs:
              <input
                type="number"
                name="carbs"
                value={localNutrients.carbs}
                onChange={handleNutrientChange}
              />
            </label>
            <Button label="Save" onClick={handleSave} />
          </td>
        </>
      ) : (
        <>
          <td>
            <b>Nutrients:</b>
          </td>
          <td>
            <ul>
              <li>
                <b>Proteins: </b>
                {nutrients.proteins}%
              </li>
              <li>
                <b>Fats: </b>
                {nutrients.fats}%
              </li>
              <li>
                <b>Carbs: </b>
                {nutrients.carbs}%
              </li>
            </ul>
          </td>
        </>
      )}
      <td>
        <IconButton
          icon={edit ? "fa fa-window-close" : "fas fa-edit"}
          onClick={() => showEdit(!edit)}
        />
      </td>
    </>
  );
};

const Table = styled.table`
  tr {
    box-shadow: none;
  }

  td {
    border-bottom: 1px solid #ced4da;
    text-align: left;
    padding: 8px;
  }
`;

function UpdateDishTable({ dish, handleInputChange }) {
  return (
    <Table>
      <tbody>
        <tr>
          <InputCellTitle
            value={dish.title}
            handleInputChange={handleInputChange}
          />
        </tr>
        <tr>
          <td>
            <b>Status:</b>
          </td>
          <td>{dish.status}</td>
          <td></td>
        </tr>
        <tr>
          <td>
            <b>Published:</b>
          </td>
          <td>{dish.publishDate}</td>
          <td></td>
        </tr>
        <tr>
          <InputCell
            label="Category"
            name="category"
            value={dish.category}
            handleInputChange={handleInputChange}
          />
        </tr>
        <tr>
          <InputCell
            label="Description"
            type="textarea"
            name="description"
            value={dish.description}
            handleInputChange={handleInputChange}
          />
        </tr>
        <tr>
          <InputFileCell
            label="Images"
            name="images"
            value={dish.images}
            handleInputChange={handleInputChange}
          />
        </tr>
        <tr>
          <InputCell
            label="Calories"
            name="calories"
            value={dish.calories}
            handleInputChange={handleInputChange}
          />
        </tr>
        <tr>
          <NutrientsCell
            nutrients={dish.nutrients}
            handleInputChange={handleInputChange}
          />
        </tr>
        <tr>
          <InputListCell
            label="Ingredients"
            name="ingredients"
            value={dish.ingredients}
            handleInputChange={handleInputChange}
          />
        </tr>
        <tr>
          <InputListCell
            label="Instructions"
            name="instructions"
            value={dish.instructions}
            numbered={true}
            handleInputChange={handleInputChange}
          />
        </tr>
      </tbody>
    </Table>
  );
}

export default UpdateDishTable;
