import React, { useContext, useState, useEffect } from "react";
import toastr from "toastr";
import DishService from "../../../services/DishService";
import { useParams } from "react-router-dom";
import { DishModel } from "../../../models/Dish.model";
import { DishContext } from "../../../providers/DishProvider";
import { useUserAuth } from "../../Context/AuthUserContext";
import { PieChart } from "react-minimal-pie-chart";
import chefIcon from "../../../assets/default-chef-icon.png";
import DishCard from "../dish-grid/DishCard";
import Chat from "../../chat";
import {
  DishDetailsContainer,
  LeftSection,
  RightSection,
  SliderContainer,
  DetailsContainer,
  SideBarDetails,
  ButtonHolder
} from "../../styled-components/StyledDishDetails";
import { Button, IconButton } from "../../styled-components";
import axios from "axios";
import { BASE_URL } from "../../../config/axios-config";

const client = axios.create({
  baseURL: BASE_URL
});

export default function DishDetails(props) {
  const params = useParams();
  const id = params.id;
  // const user = useContext(UserContext);
  const { Fbuser } = useUserAuth();
  const dishContext = useContext(DishContext);
  const currency = dishContext.currency
  const addToCart = dishContext.addCart;

  // console.log("Current dish ID in dishdetail:", id);

  var chatDefault = {
    visible: false,
    currentUser: { name: "", uid: "" },
    chef: { name: "", uid: "" }
  };
  const [dish, setDish] = useState(DishModel);
  const [chatConfig, setChatConfig] = useState(chatDefault);
  const [authUser, isAuthUser] = useState(false);
  const [displayChatBtn, showChatBtn] = useState(false);
  const [likedDish, setLikedDish] = useState(false);

  useEffect(() => {
    var dish = {};
    (async () => {
      dish = await DishService.getDishById(id);
      
      setDish(dish);
    })();
  }, [id]);

  // console.log('dishes dishdetail',dish)

  useEffect(() => {
    if (Fbuser && dish) {
      isAuthUser(true);
      var chef = dish.chef ? dish.chef : {};

      if (Fbuser.uid !== chef.uid) {
        var chatUsers = {
          currentUser: { name: Fbuser.displayName, uid: Fbuser.uid },
          chef: { name: chef.name, uid: chef.uid }
        };
        setChatConfig(chatConfig => ({ ...chatConfig, ...chatUsers }));
        showChatBtn(true);
      } else {
        showChatBtn(false);
      }
    } else {
      setChatConfig(chatDefault);
      showChatBtn(false);
      isAuthUser(false);
    }
  }, [Fbuser, dish]);

  const pickDishFn = (name, id) => {
    //console.log("pick dish"+ {name} + {id})
    addToCart(id);
    toastr.success(`${name} successfully added to your dish cart!`);
  };

  const favouriteDish = (id, userId) => {
    client
      .post(`/users/favouriteDishes`, { dishId: id, userId: userId })
      .then(response => {
        if (response.data.alreadyLiked) {
          toastr.error("Already added in your Favourites");
        } else {
          toastr.success("Successfully added to your Favourites ");
        }
      })
      .catch(error => {
        console.error("Error updating favourite dishes:", error);
        toastr.error("please update you profile");
      });
  };

  const pieData = [
    { title: "Proteins", value: dish.nutrients.proteins, color: "#50c23c" },
    { title: "Carbs", value: dish.nutrients.carbs, color: "#f09133" },
    { title: "Fats", value: dish.nutrients.fats, color: "#f0b771" }
  ];
  const chef_photo =
    dish.chef.photo !== null &&
    dish.chef.photo !== undefined &&
    dish.chef.photo !== ""
      ? dish.chef.photo
      : chefIcon;
  return (
    <DishDetailsContainer>
      <LeftSection>
        <SliderContainer>

          <DishCard dish={dish} />

        </SliderContainer>
        <DetailsContainer>
          <div className="dish-header">
            <div className="dish-title">
              <span className="title-text">{dish.title} </span>
              <IconButton
                icon="heart"
                liked={likedDish}
                onClick={() => favouriteDish(dish._id, Fbuser.uid)}
              />
            </div>
            <span>{dish.category}</span>
            <br />
            <hr />
            <span>{dish.description}</span>
            <br />
            <br />
          </div>
          <div className="list-details">
            <span>
              <b>Prep and Cook Time :</b> {dish.cookTime}
            </span>
            <br />
            <b>Ingredients:</b>
            <br />
            <li>
            <ul>
              {dish.ingredients.map((ing, i) => (
                <li key={i}>{ing}</li>
              ))}
            </ul>
            </li>
            <b>Instructions:</b>
            <br />
            <li>
            <ol>
              {dish.instructions.map((ints, i) => (
                <li key={i}>{ints}</li>
              ))}
            </ol>
            </li>
          </div>
        </DetailsContainer>
      </LeftSection>
      <RightSection>
        <SideBarDetails>
          <div className="piechart-container">
            <PieChart
              segmentsShift={0.5}
              data={pieData}
              label={({ dataEntry }) =>
                `${dataEntry.title} ${dataEntry.value}%`
              }
              labelStyle={{ fontSize: "7px", fill: "#fff" }}
            />
          </div>
          <div className="side-details">
            <span>
              <b>Calories :</b> {dish.calories}
            </span>
            <br />
            <span>
              <b>Location :</b> {dish.address}
            </span>
            <br />
            <span>
              <b>Meters away :</b> 20m
            </span>
            <br />
            <span>
              <b>Est. Serving Time :</b> 8:00 pm
            </span>
            <br />
            <span>
              <b>Price :</b> {currency} {dish.price}
            </span>
            <br />
          </div>
        </SideBarDetails>
      </RightSection>
      <ButtonHolder>
        {!authUser ? (
          <div className="buttons">
            <Button
              label="Pick this dish"
              onClick={() => pickDishFn(dish.title, dish._id)}
            />
          </div>

        ) : displayChatBtn ?(
          <div className="buttons">
            <Button
              label="Pick this dish"
              onClick={() => pickDishFn(dish.title, dish._id)}
            />
            <Button
              secondary
              label="Ask about dish"
              onClick={() => setChatConfig({ ...chatConfig, visible: true })}
            />
            <Chat config={chatConfig}></Chat>
          </div>
        ) : null}
      </ButtonHolder>
    </DishDetailsContainer>
  );
}
