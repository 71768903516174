import React from "react";
import { MainContainer } from "./StyledGrid";

import DishCard from "./DishCard";


function DishGrid({ dishes, route }) {
  const dishArray = dishes ? dishes : [];
  return (
    <MainContainer>
      {dishArray.map(dish => (
        <DishCard key={dish._id} dish={dish} route={route} />
      ))}
    </MainContainer>
  );
}

export default DishGrid;
