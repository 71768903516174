import axios from 'axios';
import { BASE_URL } from '../config/axios-config'

const client = axios.create({
    baseURL: BASE_URL,
})

export const saveLog = payload => client.post(`/audit-log`, payload)
export const getAllLogs = () => client.get(`/audit-logs`)

const AuditLogClientApi = {
    saveLog
}

export default AuditLogClientApi