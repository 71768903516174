export default class Order {
  constructor(
    dishId,
    customerId,
    orderDate,
    quantity,
    total,
    currency,
    payments,
    chefId,
    deliveredAt,
    status,
    sailor_payment_status,
    sailor_payment_amount
  ) {
    this.dishId = dishId;
    this.customerId = customerId;
    this.orderDate = orderDate;
    this.quantity = quantity;
    this.total = total;
    this.currency = currency;
    this.payments = payments ? payments : { id: "", paymentMethod: "" };
    this.chefId = chefId ? chefId : "";
    this.deliveredAt = deliveredAt;
    this.status = status;
    this.sailor_payment_status = sailor_payment_status;
    this.sailor_payment_amount = sailor_payment_amount;
  }
}
