import DishesClientApi from "../client-api/DishesClientApi";
import UserService from "./UserService";
import SettingsService from "./SettingsService";
import AuditLogService from "./AuditLogService";
import { OPERATION_ID, STATUS } from "../constants/AuditLogs.constants";
import { DISH_STATUS } from "../constants/Dish.constants";
import toastr from "toastr";
import Moment from "moment";
import Dish from "../models/Dish.model";
import Geocode from "../config/geocode";
import S3 from "react-aws-s3";

const geolib = require("geolib");

const DishService = {
  uploadImageToaws: async (config, file, spinner) => {
    if (file !== undefined) {
      const ReactS3Client = new S3(config);
      spinner.show();

      const date = Date.now();
      const filename = `${file.name}${date}`;

      const result = ReactS3Client.uploadFile(file, filename)
        .then(data => {
          console.log("returned  from aws:" + JSON.stringify(data));
          spinner.hide();
          return data.location;
        })
        .catch(err => {
          spinner.hide();
          console.log("***Error from AWS:", err);
        });
      return result;
    }
  },

  getAllDishes: async payload => {
    const data = await DishesClientApi.getAllDishes(payload)
      .then(result => {
        DishService.getAllDishesById();
        return result.data;
      })
      .catch(error => {
        console.log("***Error from getAllDishes:", error);
        return null;
      });
    return data;
  },

  deleteDish: async (id, spinner) => {
    spinner.show();
    const success = await DishesClientApi.deleteDishById(id)
      .then(() => {
        spinner.hide();
        toastr.success("Dish deleted successfully!");
        return true;
      })
      .catch(error => {
        spinner.hide();
        console.log("***Error from delete dish:", error);
        toastr.error("Failed to delete dish!");
        return false;
      });
    return success;
  },

  getAllDishesById: async () => {
    const data = await DishesClientApi.getAllDishesById()
      .then(result => {
        return result.data;
      })
      .catch(error => {
        console.log("***Error from getAllDishes:", error);

        return null;
      });
    return data;
  },

  getDishById: async id => {
    const data = await DishesClientApi.getDishById(id)
      .then(result => {
        return result.data;
      })
      .catch(error => {
        console.log("***Error from getDishById:", error);
      });
    return data;
  },

  getCountryCode: async () => {
    const code = await fetch("https://extreme-ip-lookup.com/json/")
      .then(res => res.json())
      .then(response => {
        return response.countryCode;
      })
      .catch((data, status) => {
        return null;
      });
    return code;
  },
  saveDish: async (dish, currency) => {
    try {
      const user = await UserService.getFbCurrentUser();

      // Fetch coordinates based on the address
      const userLocation = await UserService.getUserAddressLocation(
        dish.address
      );
      // console.log(`Fetched coordinates: Latitude = ${userLocation.latitude}, Longitude = ${userLocation.longitude}`);

      // Check if userLocation has finite coordinates
      if (
        userLocation &&
        typeof userLocation.latitude === "number" &&
        typeof userLocation.longitude === "number" &&
        isFinite(userLocation.latitude) &&
        isFinite(userLocation.longitude)
      ) {
        // Update dish with fetched coordinates
        dish.status = DISH_STATUS.PENDING;
        dish.chefId = user.uid;
        dish.location = {
          lng: userLocation.longitude,
          lat: userLocation.latitude
        };

        // Additional dish properties
        dish.nutrients = {
          proteins: parseInt(dish.proteins),
          carbs: parseInt(dish.carbs),
          fats: parseInt(dish.fats)
        };
        const currentDateTime = new Date();
        const formattedDateTime = Moment(currentDateTime).format(
          "DD/MM/YYYY | hh:mm:ss"
        );
        dish.createdAt = formattedDateTime;
        dish.updatedAt = formattedDateTime;
        dish.publishDate = Moment(currentDateTime).format("DD/MM/YYYY");
        dish.currency = currency;

        var newDish = DishService.createDish(dish);

        var form_data = new FormData();
        form_data.append(
          "data",
          JSON.stringify(newDish, (key, value) =>
            typeof value === "undefined" ? null : value
          )
        );
        if (dish.images) {
          const imageArr = Array.from(dish.images);
          for (var key in imageArr) {
            form_data.append("image", imageArr[key]);
          }
        }

        const success = await DishesClientApi.saveDish(newDish)
          .then(() => {
            AuditLogService.sendLog(
              STATUS.SUCCESS,
              OPERATION_ID.DISH_ADD_NEW,
              `${user.uid} - ${OPERATION_ID.DISH_ADD_NEW} ::SUCCESS `
            );
            return true;
          })
          .catch(error => {
            AuditLogService.sendLog(
              STATUS.FAILED,
              OPERATION_ID.DISH_ADD_NEW,
              `${user.uid} - ${OPERATION_ID.DISH_ADD_NEW} ::FAILED : error = ${error} `
            );
            return false;
          });

        return success;
      } else {
        console.error("Invalid coordinates:", userLocation);
        return false;
      }
    } catch (error) {
      console.error("Error in saveDish:", error);
      return false;
    }
  },

  updateDish: async (id, dish, spinner) => {
    spinner.show();
    dish.updatedAt = Moment(new Date()).format("DD/MM/YYYY | hh:mm:ss");
    if (dish.images) {
      var newDish = await DishService.createDish(dish);
      var form_data = new FormData();
      form_data.append(
        "data",
        JSON.stringify(newDish, (key, value) =>
          typeof value === "undefined" ? null : value
        )
      );
      const imageArr = Array.from(dish.images);
      for (var key in imageArr) {
        form_data.append("image", imageArr[key]);
      }
    }
    const success = await DishesClientApi.updateDishById(id, dish)
      .then(result => {
        spinner.hide();
        toastr.success("Update dish successful!");
        return result.data;
      })
      .catch(error => {
        spinner.hide();
        console.log("***Error from update dish:", error);
        toastr.error("***Error from saveDish : check console");
        return false;
      });
    return success;
  },

  getLatLng: async address => {
    const location = (await address)
      ? Geocode.fromAddress(address).then(
          response => {
            return response.results[0].geometry.location;
          },
          () => {
            return null;
          }
        )
      : {};
    return location;
  },

  filterDishesByDistance: async (dishes, meters, location) => {
    if (!meters && !dishes && !location) return dishes;

    let selectedDishes = [];
    for (var dish of dishes) {
      if (DishService.getDistance(location, dish.location) < meters) {
        selectedDishes.push(dish);
      }
    }
    return selectedDishes;
  },

  getDistance: (location, destination) => {
    return location && destination
      ? geolib.getDistance(location, destination)
      : 0;
  },

  getDishesByChefId: dishes => {
    return dishes.filter(x => x.chef.id === UserService.getFbCurrentUser().uid);
  },

  convertToSlug: url => {
    return url
      .toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  },
  updateDishStatus: async (id, dish) => {
    await DishesClientApi.updateDishById(id, dish)
      .then(result => {
        console.log("=== Dish has been updated successfully.", result);
      })
      .catch(error => {
        console.log("***Error from userHttpClient.updateUserById: ", error);
        toastr.error(
          "***Error from userHttpClient.updateUserById : check console"
        );
      });
  },

  createDish: dish => {
    let newDish = new Dish(
      dish.title,
      dish.description,
      dish.category,
      dish.address,
      dish.country,
      dish.city,
      dish.location,
      dish.images,
      dish.ingredients,
      dish.instructions,
      dish.nutrients,
      dish.calories,
      dish.price,
      dish.currency,
      dish.cookTime,
      dish.createdAt,
      dish.updatedAt,
      dish.publishDate,
      dish.chefId,
      dish.status
    );
    return newDish;
  }
};

export default DishService;