import styled from "styled-components";

export const ChooseBtnsDiv = styled.div`
  border: 1px dashed rgba(0, 128, 0, 0.3);
  border-radius: 0.5em;
  > div {
    display: inline-block;
    padding: 1em 0;
    text-align: center;
    width: 50%;
  }
  .first {
    border-right: 1px dashed rgba(0, 128, 0, 0.3);
  }
`;

export const TextLink = styled.span`
  font-size: calc(12px + 0.2vw);
  color: ${props => (props.disabled ? "gray" : "green")};
  ${props =>
    !props.disabled &&
    `   
        cursor: pointer;

        :hover {
          color: #f09133 !important;
        }
      `}
`;
