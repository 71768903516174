import React, { useState, useEffect } from "react";
import OrderService from "../../services/OrderService";
import OrdersTable from "./OrdersTable";
import { USER_TYPE } from "../../constants/User.constants";
import { BreadCrumbs } from "../styled-components";

function MySales() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await OrderService.getOrdersByUser(USER_TYPE.CHEF);
      setOrders(data);
    })();
  }, []);

  return (
    <div className="p-4">
      <BreadCrumbs values={["My Kitchen", "My Sales"]} />
      <OrdersTable data={orders} />
    </div>
  );
}

export default MySales;
