import React from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components";

const SpinnerContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 9999;

  .spinner {
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 30%;
    left: 45%;
  }
`;

const Spinner = () => {
  return (
    <SpinnerContainer>
      <Loader
        className="spinner"
        type="Puff"
        color="#8fc28f"
        height={100}
        width={100}
      />
    </SpinnerContainer>
  );
};

export default Spinner;
