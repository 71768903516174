import React from "react";
import "./StyledModal.css";
import ReactModal from "react-modal";
import styled from "styled-components";

ReactModal.setAppElement("#root");

const ModalContent = styled.div`
  font-size: calc(12px + 0.2vw);
  .modal-footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 10px;
  }
  .modal-footer > button {
    float: right;
  }
`;

const StyledModal = ({ isOpen, height, width, ...props }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={300}
      style={{
        content: {
          height: height,
          width: width
        }
      }}
      {...props}
    >
      <ModalContent>{props.children}</ModalContent>
    </ReactModal>
  );
};

export default StyledModal;
