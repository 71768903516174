import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StepWizard from "react-step-wizard";
import { Button, BreadCrumbs } from "../../styled-components";
import DishService from "../../../services/DishService";
import SettingsService from "../../../services/SettingsService";
import SimpleDynamicForm from "../../dynamic-form/SimpleDynamicForm";
import dishIcon from "../../../assets/dish_icon.png";
import NavIndicator from "./NavIndicator";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { SpinnerContext } from "../../../providers/SpinnerProvider";
import { DishContext } from "../../../providers/DishProvider";
import UserService from "../../../services/UserService";
import UsersClientApi from "../../../client-api/UsersClientApi";
import { Loading } from "notiflix/build/notiflix-loading-aio";
const CenterDiv = styled.div`
  text-align: center;
  . > * {
    display: inline-block;
  }
  .error {
    color: red;
  }
`;
const defaultCountry = ["Australia"];
// const config = {
//   bucketName: "contextflow",

//   dirName: "image",
//   region: "us-east-2",
//   accessKeyId: "AKIAZJZHX25XX7LE6UEQ",
//   secretAccessKey: "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"
// };

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: process.env.REACT_APP_DIR_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
};


const AddDishWizard = () => {
  const initialValue = {
    title: "",
    description: "",
    category: "",
    price: "",
    address: "",
    images: [],
    cookTime: "",
    ingredients: [],
    instructions: [],
    proteins: 0,
    carbs: 0,
    fats: 0,
    country: "",
    calories: "",
    city: ""
  };
  const [state, updateState] = useState({
    form: initialValue,
    confirm: false
  });
  const [countries, setCountries] = useState(null);
  const spinner = useContext(SpinnerContext);
  const dishcontext = useContext(DishContext)
  const currency = dishcontext.currency
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const user = await UserService.getFbCurrentUser();
      const user_details = await UsersClientApi.getUserByUid(
        user ? user.uid : null
      );
      if (user_details) {
        var { form } = state;
        form = {
          ...form,
          ["address"]: user_details.data.address,
          ["country"]: user_details.data.country
        };
        updateState({ ...state, form });
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const data = await SettingsService.getCountryList();
      if (data !== null && data.length > 0) {
        setCountries(data.map(x => x.country_name));
      } else {
        setCountries(defaultCountry.map(x => x));
      }
    })();
  }, []);

  const handleOnChange = event => {
    var { form } = state;
    if (event.target) {
      const { name, value } = event.target;
      form = { ...form, [name]: value };
      updateState({ ...state, form });
    } else {
      form = { ...form, ...event };
      updateState({ ...state, form });
    }
  };
  const handleUpload = async e => {
    if (e.images) {
      const len = e.images.length - 1;
      const imgurl = await DishService.uploadImageToaws(
        config,
        e.images[len],
        spinner
      );
      form.images.push(imgurl);
      updateState({ ...state, form });
    }
  };
  const handleOnSubmit = () => {
    
  
    (async () => {

      console.log("invoke dish await service");
      const resl = await DishService.saveDish(state.form,currency);
      console.log("returned dish:" + resl);

      if (resl) {
        updateState({ ...state, ...{ confirm: true, form: initialValue } });
      }
    })();
  };

  const setInstance = SW =>
    updateState({
      ...state,
      SW
    });
  const { SW, confirm, form } = state;

  return (
    <div className="p-4">
      <BreadCrumbs values={["My Kitchen", "New Dish"]} />
      <div className="col-md-8 offset-md-2 pt-4">
        <h5>
          {state.form.title.length > 1
            ? `Adding ${state.form.title} `
            : "Add a dish!"}{" "}
        </h5>
        <hr />
        <StepWizard nav={<NavIndicator />} instance={setInstance}>
          <BasicDetails value={form} onChange={handleOnChange} />
          <UploadImages value={form} onChange={handleUpload} />
          <OrderDetails
            value={form}
            onChange={handleOnChange}
            onSubmit={handleOnSubmit}
            countries={countries}
          />
          <RecipeDetails
            value={form}
            onChange={handleOnChange}
            onSubmit={handleOnSubmit}
          />
          <NutritionalProfile
            value={form}
            onChange={handleOnChange}
            onSubmit={handleOnSubmit}
          />
          {/* <Loading /> */}
          <Result confirm={confirm} onSubmit={handleOnSubmit} />
        </StepWizard>
      </div>
    </div>
  );
};

export default AddDishWizard;

/** Steps */

const BasicDetails = props => {
  const formId = "basicForm";
  const onSubmit = e => {
    e.preventDefault();
    props.nextStep();
  };

  const fieldConfig = [
    {
      name: "title",
      label: "Dish Title",
      type: "text",
      onChange: props.onChange,
      value: props.value.title,
      required: true
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      onChange: props.onChange,
      value: props.value.description
    },
    {
      name: "category",
      label: "Category",
      type: "text",
      onChange: props.onChange,
      value: props.value.category
    }
  ];

  return (
    <div>
      <h6 className="text-center">Basic Details</h6>
      <SimpleDynamicForm
        formId={formId}
        fieldConfig={fieldConfig}
        handleSubmit={onSubmit}
      />
      <Button form={formId} type="submit" label="Next" />
    </div>
  );
};

const UploadImages = props => {
  const formId = "imagesForm";

  const onSubmit = e => {
    e.preventDefault();
    if (props.value.images.length !== 0) {
      props.nextStep();
    }
  };

  const fieldConfig = [
    {
      name: "images",
      label: "Images",
      type: "file",
      multiple: true,
      limit: 5,
      // required: true,
      onChange: props.onChange,
      value: props.value.images
    }
  ];

  return (
    <div>
      <h6 className="text-center">Upload Images</h6>
      <SimpleDynamicForm
        formId={formId}
        fieldConfig={fieldConfig}
        handleSubmit={onSubmit}
      />
      <Button
        tertiary
        type="button"
        onClick={() => props.previousStep()}
        label="Previous"
      />
      <Button form={formId} type="submit" label="Next" />
    </div>
  );
};

const OrderDetails = props => {
  const formId = "orderForm";

  const onSubmit = e => {
    e.preventDefault();
    props.goToStep(6);
    props.onSubmit();
  };

  const fieldConfig = [
    {
      name: "price",
      label: "Price",
      type: "number",
      required: true,
      onChange: props.onChange,
      value: props.value.price
    },
    {
      name: "address",
      label: "Address",
      type: "location",
      required: true,
      onChange: props.onChange,
      value: props.value.address
    },
    {
      name: "country",
      label: "Country",
      type: "select",
      required: true,
      options: props.countries,
      onChange: props.onChange,
      value: props.value.country
    },
    {
      name: "city",
      label: "City/Region",
      type: "text",
      required: true,
      onChange: props.onChange,
      value: props.value.city
    }
  ];

  return (
    <div>
      <h6 className="text-center">Order Details</h6>
      <SimpleDynamicForm
        formId={formId}
        fieldConfig={fieldConfig}
        handleSubmit={onSubmit}
      />
      <Button
        tertiary
        type="button"
        onClick={() => props.previousStep()}
        label="Previous"
      />
      <Button form={formId} type="submit" label="Submit" />
      <Button
        secondary
        type="button"
        onClick={() => props.nextStep()}
        label="Add recipe"
      />
    </div>
  );
};

const RecipeDetails = props => {
  const formId = "recipeForm";

  const onSubmit = e => {
    e.preventDefault();
    props.goToStep(6);
    props.onSubmit();
  };

  const fieldConfig = [
    {
      name: "cookTime",
      label: "Prep and Cook Time",
      type: "text",
      placeholder: "eg. 20 minutes, 1 hour",
      onChange: props.onChange,
      value: props.value.cookTime
    },
    {
      name: "ingredients",
      label: "Ingredients",
      type: "list",
      onChange: props.onChange,
      value: props.value.ingredients
    },
    {
      name: "instructions",
      label: "Instructions",
      type: "list",
      numbered: true,
      onChange: props.onChange,
      value: props.value.instructions
    }
  ];

  return (
    <div>
      <h6 className="text-center">Recipe Details</h6>
      <SimpleDynamicForm
        formId={formId}
        fieldConfig={fieldConfig}
        handleSubmit={onSubmit}
      />
      <Button
        tertiary
        type="button"
        onClick={() => props.previousStep()}
        label="Previous"
      />
      <Button form={formId} type="submit" label="Submit" />
      <Button
        secondary
        type="button"
        onClick={() => props.nextStep()}
        label="Add nutritional details"
      />
    </div>
  );
};

const NutritionalProfile = props => {
  const formId = "nutritionalForm";

  const onSubmit = e => {
    e.preventDefault();
    props.goToStep(6);
    props.onSubmit();
  };

  const fieldConfig = [
    {
      name: "calories",
      label: "Calories",
      type: "number",
      onChange: props.onChange,
      value: props.value.calories
    },
    {
      name: "proteins",
      label: "Proteins",
      type: "percent",
      onChange: props.onChange,
      value: props.value.proteins
    },
    {
      name: "carbs",
      label: "Carbs",
      type: "percent",
      onChange: props.onChange,
      value: props.value.carbs
    },
    {
      name: "fats",
      label: "Fats",
      type: "percent",
      onChange: props.onChange,
      value: props.value.fats
    }
  ];

  return (
    <div>
      <h6 className="text-center">Nutritional Profile</h6>
      <SimpleDynamicForm
        formId={formId}
        fieldConfig={fieldConfig}
        handleSubmit={onSubmit}
      />
      <Button
        tertiary
        type="button"
        onClick={() => props.previousStep()}
        label="Previous"
      />
      <Button form={formId} type="submit" label="Submit" />
    </div>
  );
};


const Result = props => {
  const navigate = useNavigate();
  const sucessful = props.confirm;

  return sucessful ? (
    <CenterDiv>
      <h6>Done creating a Dish!</h6>
      <p>
        New dish is saved and pending. Wait the admin for approval. <br />
        Thank you.
      </p>
      <img src={dishIcon} alt="Dish thumbnail" />
      <hr />
      <Button
        type="button"
        onClick={() => props.goToStep(1)}
        label="Create another dish"
      />
      <Button
        secondary
        type="button"
        label="Go to My Kitchen"
        onClick={() => navigate("/my-kitchen")}
      />
      <hr />
    </CenterDiv>
  ) : (
    <CenterDiv>
      <h6></h6>
      <p className="error">
        Oops! There was an error occured in creating your dish. Please try
        again.
      </p>
      <img src={dishIcon} alt="Dish thumbnail" />
      <hr />
      <Button
        tertiary
        type="button"
        onClick={() => props.goToStep(1)}
        label="Edit dish"
      />
      <Button type="button" onClick={() => props.onSubmit()} label="Submit" />
      <Button
        secondary
        type="button"
        label="Go back to My Kitchen"
        onClick={() => navigate("/my-kitchen")}
      />
      <hr />
    </CenterDiv>
  );
};

