import PaymentClientApi from "../client-api/PaymentClient";
import toastr from "toastr";
import AuditLogService from "./AuditLogService";
import { OPERATION_ID, STATUS } from "../constants/AuditLogs.constants";

const CatchPayment = {
  savePayments: async data => {
    const success = await PaymentClientApi.savePayments(data)
      .then(result => {
        AuditLogService.sendLog(STATUS.SUCCESS, OPERATION_ID.PAYMENT_IN_PAYPAL);
        return result.data;
      })
      .catch(error => {
        AuditLogService.sendLog(
          STATUS.FAILED,
          OPERATION_ID.PAYMENT_IN_PAYPAL,
          error
        );
        console.log("***Error from Payment:", error);
        return null;
      });
    return success;
  },
  getAllPaymentList: async () => {
    const data = await PaymentClientApi.getPaymentDetails()
      .then(result => {
        return result.data;
      })
      .catch(error => {
        console.log("***Error from getAllDishes:", error);
        toastr.error("***Error from getAllDishes : check console");
        return null;
      });
    return data;
  }
};
export default CatchPayment;
