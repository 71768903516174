import React, { useContext } from "react";
import UserMenu from "./UserMenu";
import { Link } from "react-router-dom";

import "./title-bar.css";
import dishIcon from "../../assets/dish_icon.png";
import logo from "../../assets/logo.jpg";
import colabKitchenTitle from '../../assets/colabKitchenTitle.jpg';
import { DishContext } from "../../providers/DishProvider";
import {useUserAuth} from '../Context/AuthUserContext'
import UpdatedLoginMethod from "../LogIn_PopUp/UpdatedLoginMethod";
import { auth } from "../../config/firebase";

function TitleBar() {
  const value = useContext(DishContext);
  const [cart] = value.cart;
  const [refresh, setRefresh] = value.refresh;
  const {Fbuser} = useUserAuth();
  // const user = auth.currentUser;
  const Refresh = async () => {
    setRefresh(!refresh);
  };
  return (
    <div className="title-bar">
      <Link to="/">
        <img onClick={() => Refresh()} className="logo" src={logo} alt="logo" />

      </Link>
{/* 
      <img src={colabKitchenTitle} className="colab-kitchen-title" alt='colabkitchen' /> */}
      <div className="title-container">
        <img src={colabKitchenTitle} className="colab-kitchen-title" alt='colabkitchen' />
      </div>
      
      <div className="right-container">

        <div className="dish_icon_container">
          <Link style={{ textDecoration: "none" }} to="/add-to-cart">
            {cart.length > 0 ? (
              <span className="cart-icon">{cart.length}</span>
            ) : null}
            <img className="dish_img" src={dishIcon} alt="dish_icon" />
          </Link>
        </div>
       
       {!auth.currentUser ? (
        <div>
            <UpdatedLoginMethod/>
        </div>
       ):<UserMenu />}
        
       
      </div>
      
    </div>
  );
}

export default TitleBar;
