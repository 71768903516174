import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification,
  
} from "firebase/auth";
import {User} from "../../models/User.model.js";
import { auth } from "../../config/firebase";
import  toastr from 'toastr';
import AuditLogService from "../../services/AuditLogService";
import { STATUS,OPERATION_ID } from "../../constants/AuditLogs.constants.js";
import {generateToken} from "../../config/firebase"
import axios from 'axios';
import { BASE_URL } from "../../config/axios-config";
import UsersClientApi from "../../client-api/UsersClientApi.js";


export const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {

  const [Fbuser, setFbUser] = useState({});

 async function logIn(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const fcmtoken = await generateToken();
   

      const loginIdentifier = userCredential.user.email;
      const idToken = await userCredential.user.getIdToken();
      const displayname = userCredential.user.displayName;
      const email_id = userCredential.user.email;
      const userid = userCredential.user.uid;
     
    

      if(userCredential.user.emailVerified){
        toastr.success(`User Signed In successfully`);
        AuditLogService.sendLog(STATUS.SUCCESS, OPERATION_ID.USER_LOGIN);
      
      }
      else{
        toastr.error("Cannot sign in. Please verify your email.");
        logOut();
      }
        
    
      if (idToken) {
        storeUserwithEmail(idToken,loginIdentifier, fcmtoken,displayname,email_id,userid); 
        
      } else {
        toastr.error(`Unable to get authentication token.`);
      }
  
    } catch (error) {
      toastr.error(`Unable to login. Please try again. ${error.message}`);
      AuditLogService.sendLog(STATUS.FAILED, OPERATION_ID.USER_LOGIN, error);
    }
  }

  const storeUserwithEmail = async (idToken, loginIdentifier,fcmtoken,displayname,email,userid) => {
   
    
    var userdata ={...User}
  
    userdata.uid = userid
    userdata.name = displayname
    userdata.email = email
    userdata.loginIdentifier=loginIdentifier
    userdata.fcmToken=fcmtoken

    var user_details = await UsersClientApi.getUserByUid(userid);

    if(!user_details.data.uid){
    try {
        const response = await axios.post(`${BASE_URL}/user`,userdata,
           { headers: { 'Authorization': `Bearer ${idToken}` } }  

        );
    } catch (error) {
        console.error('Error in storing FCM token:', error);
      
    }}
};
 

async function signUpUser(email, password,name) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(userCredential.user);
    await updateProfile(userCredential.user, { displayName: name });
    toastr.success("User Created Successfully. Verification email has been sent.");
    logOut();
  } catch (error) {
    console.error("Error signing up:", error);
    toastr.error(`Unable to Create User. Please try again. ${error.message}`);
    
  }
}

  function logOut() {
    AuditLogService.sendLog(STATUS.SUCCESS, OPERATION_ID.USER_LOGOUT);
    return signOut(auth); 
  }
  
 async  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    googleAuthProvider.setCustomParameters({ prompt: 'select_account' });
  
    return signInWithPopup(auth, googleAuthProvider)
    .then(() =>  AuditLogService.sendLog(STATUS.SUCCESS, OPERATION_ID.USER_LOGIN_GOOGLE))
    .catch(error => {
      AuditLogService.sendLog(
        STATUS.FAILED,
        OPERATION_ID.USER_LOGIN_GOOGLE,
        error
      );
      throw error;
    });
  }

  async function resetPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email)
      AuditLogService.sendLog(
        STATUS.SUCCESS,
        OPERATION_ID.EMAIL_RESET,
        email
      );
      toastr.success("Password reset email sent successfully. Please check your email inbox.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      toastr.error(`Unable to send password reset email. Please try again. ${error.message}`);
      AuditLogService.sendLog(
        STATUS.FAILED,
        OPERATION_ID.EMAIL_RESET,
        `${email} :: Error in sendPasswordResetEmail = ${error}`
      );
    }
  }

  
 

  const savePhoneNumberAsLoginIdentifier = async(user,fcmToken) => {

    var userdata ={...User}
  
    userdata.uid = user.uid
    userdata.phoneNumber=user.phoneNumber
    userdata.loginIdentifier=user.phoneNumber
    userdata.fcmToken=fcmToken

    var user_details = await UsersClientApi.getUserByUid(user.uid);
    
    if(!user_details.data.uid){
    try {
      const response = await axios.post(`${BASE_URL}/user`, userdata);
    
    } catch (error) {
      
      console.error('Error saving phone number as login identifier:', error);
    }}
    
  };
  async function updateUserPhoto(photoURL) {
    setFbUser((prevUser) => ({
      ...prevUser,
      photoURL: photoURL
    }));
  }
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
     
      setFbUser(currentuser);
     
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        Fbuser,
        logIn,
        signUpUser,
        logOut,
        updateUserPhoto,
        googleSignIn,
       resetPassword,
      savePhoneNumberAsLoginIdentifier
      }}
    >
      {children}
    
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
