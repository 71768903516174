export const OPERATION_ID = {
  USER_UPDATED: "user_updated",
  USER_ADDED: "user_added_in_db",
  USER_SIGNUP: "user_signup",
  USER_SIGNUP_PHONE: "user_signup_phone",
  USER_DELETED: "user_deleted",
  USER_LOGIN: "user_login",
  USER_LOGIN_PHONE: "user_login_phone",
  USER_LOGIN_GOOGLE: "user_login_google",
  USER_LOGOUT: "user_logout",
  EMAIL_RESET: "user_email_reset",
  FB_UPLOAD_DISH_IMG: "firebase_upload_dish_image",
  FB_UPLOAD_USER_IMG: "firebase_upload_user_image",
  GET_ORDERS_CHEF: "Get order list by chef",
  GET_ORDERS_USER: "Get order list by user",
  UPDATE_ORDER_STATUS: "Update order status by chef",
  CREATE_ORDER: "order created by user",
  DISH_ADD_NEW: "Added new dish",
  DISH_STATUS: "dish status changed by admin",
  PAYMENT_IN_PAYPAL: "payment done with paypal by user",
  PAYMENT_IN_RAZORPAY: "payment done with razorpay by user",    // Added payment_in_razorpay
  GET_COUNTRY_CODE: "Get country code ::",
  RETRIEVE_COUNTRY_LIST: "Retrieve country list from db ::",
  RETRIEVE_COUNTRY_DATA: "Retrieve country data from db ::"
};

export const STATUS = {
  SUCCESS: "successful",
  FAILED: "failed"
};
