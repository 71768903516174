import React, { useState, useEffect } from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin: 15px 0;
  position: relative;

  label {
    position: relative;
    top: 7px;
    left: 15px;
    transition: all 0.2s ease;
    z-index: 500;
    opacity: 0.6;
  }

  textarea {
    height: 40px;
    font-size: 1em;
  }

  .clear {
    position: relative;
    top: 7px;
    right: 5px;
    display: block;
    float: right;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .btn-outline-secondary {
    box-shadow: none;
    :hover {
      color: white;
      background-color: green;
    }
  }

  ${props =>
    props.focused &&
    `
    border: 1px solid green;

    .input-group-text, .form-control, .btn-outline-secondary{
      border: .2px solid green;
    }

    .btn-outline-secondary{
      color: ${props.error ? "red" : "green"};
    }
  
    .has-float-label label {
      background-color: white !important;
      padding: 0px 5px;
      color: green;
    }

    .form-control {
      outline: 0 !important;
      box-shadow: none;
    }

    label{
      top: 12px;
      font-size: 13px;
      transform: translateY(-23px) translateX(-5px);
      z-index: 501;
      background: white;
      padding: 0 8px;
      opacity: 1;
      font-weight: 500;
      color: #f09133;
    }

    .clear{
      opacity: 0.6;
      visibility: visible;
    }

    .clear:hover{
      opacity: 1;
      color: green;
    }

}
  `}
`;
/**
 * A Plaid-inspired custom input component
 *
 * @param {string} value - the value of the controlled input
 * @param {string} type - the type of input we'll deal with
 * @param {string} label - the label used to designate info on how to fill out the input
 * @param {function} onChange - function called when the input value changes
 * @param {function} onFocus - function called when the input is focused
 * @param {function} onBlur - function called when the input loses focus
 * @param {function} setRef - function used to add this input as a ref for a parent component
 * @param {function} numbered - boolean value which checks if list is numbered or bulleted
 */
const InputList = ({
  value,
  type,
  label,
  onChange,
  onFocus,
  onBlur,
  setRef,
  numbered,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [inputList, setInputList] = useState([{ value: "" }]);

  useEffect(() => {
    var initialValue = [];
    if (value && value.length !== 0) {
      value.forEach(element => {
        initialValue.push({ value: element });
      });
      setInputList(initialValue);
    } else {
      setInputList([{ value: "" }]);
    }
  }, [value]);

  const handleOnFocus = () => {
    setFocused(true);
    onFocus();
  };

  const handleOnBlur = () => {
    setFocused(false);
    onBlur();
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { value: "" }]);
  };

  const reset = () => {
    onChange({ [props.name]: [] });
    setInputList([{ value: "" }]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    var arr = [];
    for (var i = 0; i < list.length; i++) {
      arr.push(list[i].value);
    }
    const ex ={target:{name:props.name,value:arr.filter(x => x)}}
    onChange(ex);
  };

  const isFocused = focused || String(value).length;

  return (
    <InputContainer focused={isFocused} required={props.required}>
      <label>{label}</label>
      <span className="clear" onClick={reset}>
        <i className="fas fa-eraser" /> clear
      </span>
      {inputList.map((x, i) => (
        <div key={i} className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              {numbered ? <b>{i + 1}</b> : <b>&#x25CF;</b>}
            </span>
          </div>
          <textarea
            rows="2"
            className="form-control"
            placeholder="Add item"
            value={x.value}
            type={type}
            name="value"
            onChange={e => handleInputChange(e, i)}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
          <div className="input-group-append">
            {inputList.length !== 1 && (
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => handleRemoveClick(i)}
              >
                <i className="fa fa-minus" />
              </button>
            )}
            {inputList.length - 1 === i && (
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={handleAddClick}
              >
                <i className="fa fa-plus" />
              </button>
            )}
          </div>
        </div>
      ))}
    </InputContainer>
  );
};

InputList.defaultProps = {
  value: [],
  type: "text",
  label: "",
  onChange: text => {
    console.error(`Missing onChange prop: ${text}`);
  },
  onFocus: () => {},
  onBlur: () => {},
  setRef: () => {}
};

export default InputList;
