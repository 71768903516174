import axios from "axios";
import { BASE_URL } from "../config/axios-config";

const client = axios.create({
  baseURL: BASE_URL
});

export const getCountryList = () => client.get(`/countries`);
export const getCountryByCode = code => client.get(`/countries/${code}`);

const SettingsClientApi = {
  getCountryList,
  getCountryByCode
};

export default SettingsClientApi;
