import React, { useState, useEffect } from "react";
import DishService from "../../services/DishService";
import { Link, useNavigate } from "react-router-dom";
import { Button, BreadCrumbs } from "../styled-components";
import DishGrid from "../dish/dish-grid";
import UserService from "../../services/UserService";
import PaginationComponent from "../landing-page/pagination";

function MyKitchen() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    (async () => {
      const FBuser = await UserService.getFbCurrentUser();
      const NodeUser = await UserService.getUserByUid(FBuser.uid);
    
      setPhoneNumber(NodeUser.phoneNumber);
    })();
  }, []);

  const [myDishes, setMyDishes] = useState([]);
  useEffect(() => {
    (async () => {
      const FBuser = await UserService.getFbCurrentUser();
      const data = await DishService.getAllDishes({
        uid: FBuser.uid,
        search: "",
        pageNumber: 0,
        location: null,
      });
      
      setMyDishes(data.dishData);
      setTotalPage(data.totalPage);
    })();
  }, [pageNumber]);

  const checkPhoneNumber = () => {
    if (phoneNumber && phoneNumber !== "") {
      navigate("/add-dish");
    } else {
      alert("Please add your phone number in the my profile page to sell dish!");
      navigate("/add-dish");
    }
  };

  return (
    <>
      <div className="p-4">
        <BreadCrumbs values={["My Kitchen"]} />
        <Button label="Add dish" onClick={() => checkPhoneNumber()} type="button" />
        <Link to="/my-kitchen/my-sales">
          <Button label="My sales" type="button" />
        </Link>
      </div>

      <DishGrid dishes={myDishes} route="/my-kitchen"></DishGrid>
      {myDishes.length > 0 && (
        <PaginationComponent dishLength={totalPage} setPageNumber={setPageNumber} />
      )}
    </>
  );
}

export default MyKitchen;
