import axios from "axios";
import { BASE_URL } from "../config/axios-config";

const client = axios.create({
  baseURL: BASE_URL
});

export const saveDish = payload => client.post(`/dishes`, payload);
export const uploadDishImg = payload => client.post(`/dish_images`, payload);
export const getAllDishes = payload =>
  client.get(`/dishes`, { params: { payload } });
export const getAllDishesById = () => client.get(`/all_dishes`);
export const getDishById = id => client.get(`/dishes/${id}`);
export const updateDishById = (id, payload) =>
  client.put(`/dishes/${id}`, payload);
export const deleteDishById = id => client.delete(`/dishes/${id}`);

const DishesClientApi = {
  getAllDishes,
  getAllDishesById,
  saveDish,
  uploadDishImg,
  getDishById,
  updateDishById,
  deleteDishById
};

export default DishesClientApi;
