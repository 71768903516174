
import React, { useEffect, createContext, useState } from "react";
import DishService from "../services/DishService";
import SettingsService from "../services/SettingsService";
import { Curency_code } from "../components/paypal/CurrencyCode";

export const DishContext = createContext();

export default function DishProvider(props) {
  const [dishes, setDishes] = useState([]);
  const [currency, setCurrency] = useState();
  const [country, setCountry] = useState();

  // useEffect(() => {
  //   if (!dishes) {
  //     (async () => {
  //       const data = await DishService.getAllDishes();
  //       setDishes(data);
  //     })();
  //   }
  // }, [dishes]);
  useEffect(() => {
    if (!dishes) {
      (async () => {
        const data = await DishService.getAllDishes();
        setDishes(data);
      })();
    }
  }, [dishes]);

  const getInitialCart = () => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  };
  // console.log("Dishproviderx"+JSON.stringify(dishes))
  // const [cart, setCart] = useState([]);
  const [cart, setCart] = useState(getInitialCart);

  const [refresh, setRefresh] = useState(false);
  

  useEffect(() => {
    (async () => {
      const countryData = await SettingsService.getCurrentCountry();
      console.log('country data in dishcontext',countryData)
      setCountry(countryData.country);

      const filteredData = Curency_code.filter(
        items => items.country === countryData.country
      );
      if (filteredData.length > 0) {
        setCurrency(filteredData[0].currency);
      } else {
        setCurrency(null);
      }
    })();
  }, []);

  // console.log('country currency in dish context',currency)
    useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));  //key , value
  }, [cart]);
 
  
  const addCart = id => {
    
    const check = cart.every(item => {
      return item._id !== id;
    });
    if (check) {
      const data = dishes.filter(dish => {
        return dish._id === id;
      });

      setCart([...cart, ...data]);
    } else {
      alert("The dish has been added to cart.");
    }
  };

  useEffect(() => {
    if (cart) setCart(cart);
  }, []);


  const value = {
    dishes: [dishes, setDishes],
    cart: [cart, setCart],
    refresh: [refresh, setRefresh],
    addCart: addCart,
    currency: currency,
    country: country
  };
  
  return (
    <DishContext.Provider value={value}>{props.children}</DishContext.Provider>
  );
}
