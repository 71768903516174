

import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useUserAuth } from '../../Context/AuthUserContext';

const ForgotPassword = ({onPasswordReset}) => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
 
  const { resetPassword } = useUserAuth();

  const handleSubmit = async (e) => {

    try {
      await resetPassword(email);
      setEmailSent(true);
      onPasswordReset(); 
    } catch (error) {
     console.log("Error in sending Reset Password Link",error)
    }
  };

  if (emailSent) {
    return (
      <div>
        <p>Password reset email has been sent. Please check your email inbox.</p>
      </div>
    );
  }

  return (
    <div >
      <h1>Forgot Password</h1>
      <Form onFinish={handleSubmit}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
              type: 'email',
            },
          ]}
        >
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Send Reset Link
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPassword;
