
import React, { useState, useEffect, useContext } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import ChatService from "../../services/ChatService";
import Moment from "moment";
import {ref,orderByChild,equalTo, query, onValue} from "firebase/database"
import {database, CHAT_MSGS } from "../../config/firebase";
import { Link, useLocation } from "react-router-dom";
import UserService from "../../services/UserService";
import { useUserAuth } from "../Context/AuthUserContext";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import {
  RoomContainer,
  MsgHeader,
  LeftArrow,
  BackButton,
  Profile,
  MsgHeaderImg,
  Active,
  ChatPage,
  ReceivedMsg,
  ReceivedMsgInbox,
  OutGoingChats,
  OutGoingChatMsg,
  InputDiv,
  MessageInput
} from "./StyledChat";

function MyChatRoom(props) {
  const{Fbuser} = useUserAuth();
  const [userId, setUserId] = useState();
  const location = useLocation();
  
   const data = location.state;
  
   const chatroomId = data.roomId;
  
   const textname = data.sender_name;
   
  const [id, setId] = useState(chatroomId);
  
  const[photoURL,setPhotoURL] = useState('')
  const [SenderName] = useState(textname);
 
  const [chats, setChats] = useState([]);
  var emptyChat = {
    roomId: "",
    userId: "",
    message: "",
    date: "",
    type: "",
    isRead: ""
  };
  const [newChat, setNewChat] = useState(emptyChat);
   
//  const  room = "isesq61ix6qzdgjwk3xibpc3vei2o7jozd1qe9ssiynvl4jyyggksju2"

  
//  const newUid = room.replace("o7jozd1qe9ssiynvl4jyyggksju2",'')
//  console.log("SenderuserId",newUid);

  useEffect(() => {
    if (Fbuser) {
      setId(location.state.roomId);

    }
  }, [id]);

  useEffect(()=>{
  const getPhoto =async()=>{
    const extractedUid = chatroomId.replace(Fbuser.uid,'');

   await UserService.getUserByUid(extractedUid)
   .then(result => {
    
    const photoURL = result.photo;
    setPhotoURL(photoURL);
   
   
  })
  .catch(error => {
    console.error("Error getting user by UID:", error);
    return null;
  });

return data;
  
  }
  getPhoto();
},[id])

  useEffect(() => {
    (async () => {
      if (Fbuser) {
        setUserId(Fbuser.uid);
         
          const messageRef = ref(database,CHAT_MSGS)
          const q = query(messageRef,orderByChild("roomId"),equalTo(id))
          
          onValue(q,(snapshot)=>{
            setChats([]);
          setChats(ChatService.snapshotToArray(snapshot));
          })
      }
    })();
  }, [id, Fbuser]);

  const onChange = e => {
    const { name, value } = e.target;
    setNewChat({ ...newChat, [name]: value });
  };

  const submitMessage = e => {
    e.preventDefault();
    const chat = newChat;
    chat.roomId = id;
    chat.userId = userId;
    chat.isRead = false;
    chat.date = Moment(new Date()).format("DD/MM/YYYY hh:mm A");
    chat.type = "message";
    console.log("Chat in My-Messages",chat);
    ChatService.sendChat(chat);
    setNewChat(emptyChat);
  };

  return (
    <>
      <RoomContainer>
        <MsgHeader>
          <LeftArrow>
            <Link to={"/my-messages"}>
            
              <FontAwesomeIcon icon={faArrowLeft} />
             
            </Link>
          </LeftArrow>
          <MsgHeaderImg>
            <Profile src={photoURL} />
          </MsgHeaderImg>
          <Active>
            <h4>{SenderName}</h4>
          </Active>
        </MsgHeader>
        <ScrollToBottom>
          <ChatPage>
            {chats.map((chat) =>
              chat.userId === userId ? (
                <div key={chat.key}>
                  <ReceivedMsg key={chat.key}>
                    <ReceivedMsgInbox>
                      <p>{chat.message}</p>
                      <span>{chat.date}</span>
                    </ReceivedMsgInbox>
                  </ReceivedMsg>
                </div>
              ) : (
                <div key={chat.key}>
                  <OutGoingChats key={chat.key}>
                    <OutGoingChatMsg>
                      <p>{chat.message}</p>
                      <span>{chat.date}</span>
                    </OutGoingChatMsg>
                  </OutGoingChats>
                </div>
              )
            )}
          </ChatPage>
        </ScrollToBottom>

        <InputDiv>
          <form onSubmit={submitMessage}>
            <MessageInput
              type="text"
              name="message"
              value={newChat.message}
              onChange={onChange}
              required
              placeholder="write messages.."
            ></MessageInput>

            <button type="submit" className="submit-button">
              <span className="input-group-text">
                {" "}
                <i className="fa fa-paper-plane"></i>
              </span>
            </button>
          </form>
        </InputDiv>
      </RoomContainer>
    </>
  );
}
export default MyChatRoom;
