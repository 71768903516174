import UsersClientApi from "../client-api/UsersClientApi";
import {User} from "../models/User.model";
import {
  auth,
  storage,
} from "../config/firebase";
import toastr from "toastr";
import SettingsService from "./SettingsService";
import { CURRENT_USER } from "../constants/User.constants";
import { OPERATION_ID, STATUS } from "../constants/AuditLogs.constants";
import { LOCATION } from "../constants/Settings.constants";
import Geocode from "../config/geocode";
import { ref,getDownloadURL,uploadBytes } from "firebase/storage";
import { updateProfile } from "firebase/auth";
toastr.options = {
  positionClass: "toast-top-full-width",
  hideDuration: 3000,
  timeOut: 2000
};

const UserService = {
  getFbCurrentUser: () => {
    return auth.currentUser
      ? auth.currentUser
      : JSON.parse(sessionStorage.getItem(CURRENT_USER));
  },

  insertUser: async (user_details, spinner) => {
    const user = auth.currentUser;
    
    var y = {
      loginIdentifier: user.loginIdentifier,
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      phoneNumber: user_details.phoneNumber,
      country: user_details.country,
      address: user_details.address,
      photoURL: user.photoURL,
    };
    var id = await UsersClientApi.updateUser(y).then(result => {
      if (!result.data) {
        return false;
      } else {
        return result.data._id;
      }
    });
    if (user_details.photo) {
      var file = user_details.photo;
      const storageRef = ref(storage, `profile/${user.uid}/profile.jpg`);
  
      try {
        await uploadBytes(storageRef, file[0]);
     
        const imgURL = await getDownloadURL(storageRef);
       
        
      
        await updateProfile(user, { 
          displayName: user_details.Name, 
          photoURL: imgURL,
        });
    
        
        if (user && typeof user.updateProfile === "function") {
          try {
            const updatedProfile = {
              displayName: user_details.Name,
              photoURL: user_details.photoURL
            };
            await user.updateProfile(updatedProfile);
    
            spinner.hide();
          
            // console.log("User profile updated successfully.");
          } catch (error) {
            console.error("Error updating user profile:", error);
          }
        }
      } catch (error) {
        console.error("Error uploading profile photo:", error);
      }
    }
   
  },

  updateUser: async (user_details, spinner) => {
   
    var id = await UsersClientApi.updateUser(user_details)
    .then(result => {
      if (!result.data) {
        return false;
      } else {
        return result.data._id;
      }
    })
    .catch(error=>{
        console.log(error)
       }) 
       
    },
  
    getUserByUid: async payload => {
    const data = await UsersClientApi.getUserByUid(payload)
      .then(result => {
        return result.data;
      })
      .catch(error => {
        console.error("Error getting user by UID:", error);
        return null;
      });
    return data;
  },

  setUserSessionStorage: (uid, user) => {
    sessionStorage.setItem(uid, UserService.simpleStringify(user));
  },

  getUserSessionStorage: uid => {
    return JSON.parse(window.sessionStorage.getItem(uid));
  },

  removeUserSessionStorage: id => {
    sessionStorage.removeItem(id);
  },

  simpleStringify: object => {
    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };

    return JSON.stringify(object, getCircularReplacer());
  },

  getUserLocation: async () => {
    let currPosition = JSON.parse(sessionStorage.getItem(LOCATION.POSITION));
    if (currPosition) {
      return currPosition;
    } else {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          position => {
            let coords = position.coords;
            let center = { lat: coords.latitude, lng: coords.longitude };
            sessionStorage.setItem(LOCATION.POSITION, JSON.stringify(center));
            resolve(center);
          },
          error => {
            sessionStorage.removeItem(LOCATION.POSITION);
            (async () => {
              const data = await SettingsService.getCurrentCountry();
              try {
                Geocode.fromAddress(`${data.city}, ${data.country}`).then(
                  pos => {
                    let center = pos.results[0].geometry.location;
                    sessionStorage.setItem(
                      LOCATION.POSITION,
                      JSON.stringify(center)
                    );
                    resolve(center);
                  }
                );
              } catch (error) {
                resolve(LOCATION.DEFAULT_CENTER);
              }
            })();
          }
        );
      });
    }
  }
};

export default UserService;
