import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PayPal from "../paypal/index";
import Razorpay from "../razorpay";
import OrderService from "../../services/OrderService";
import { DishContext } from "../../providers/DishProvider";
// import SettingsService from "../../services/SettingsService";
// import { Curency_code } from "../paypal/CurrencyCode";
import styled from "styled-components";
import {
  LeftSection,
  RightSection,
  MiddleDetails,
  RightContainer,
  CheckoutContainer,
  RadioButtonContainer,
  DetailContainer,
  DetailSubContainer,
  Label,
  Circle,
  LeftDetails,
  RightDetails
} from "./StyledCheckout";

const Title = styled.span``;
const DishQnty = styled.div`
  font-size: 20px;
  font-weight: 150;
`;
const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
`;

const OrderTitle = styled.h3`
  font-weight: 500;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  font-weight: 600;
`;
const OrderText = styled.span``;

function Checkout() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const location = useLocation();
  const value = useContext(DishContext);
  const [cart , setCart] = value.cart;
  // const [currency, setCurrency] = useState();
  const [selectedBtn, setSelectedBtn] = useState();
  // const country_currency = Curency_code;
  const currency = value.currency; 

  useEffect(() => {
    const getData = () => {
      setData(location.state);
    
    };
    getData();
 
  }, [data]);
 
  // useEffect(() => {
  //   (async () => {
  //     const data = await SettingsService.getCurrentCountry();
  //     const filteredData = country_currency.filter(
  //       items => items.country === data.country
  //     );
  //   console.log('Currency,country picked:' + JSON.stringify(filteredData) + Curency_code)
  //     if (filteredData.length > 0) {
  //       setCurrency(filteredData[0].currency);
  //     } else {
  //       setCurrency(null);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    const getTotal = () => {
      const item = data;
      const res = item.reduce((prev, item) => {
        return prev + item.price * item.count;
      }, 0);
      setTotal(res);
    };
    getTotal();
  }, [data]);

  const saveOrder = (data,currency)=> {
    const orderData = {};     
    orderData.payment = { id: " ", method: paymentMethod };
    orderData.data = data;

    orderData.currency = currency;
    console.log("Order data before saving", orderData); 

    OrderService.saveOrders(orderData).then(() => {
      setCart([]);    // when the order details is saed in server , the cart is emptied.
     
    });
  };

  const onRadioChange = e => {
    setSelectedBtn(e.target.value);
    setPaymentMethod(e.target.value);
  };


  return (
    <div>
      <CheckoutContainer>
        <LeftSection>
          {data.map(order => (
            <DetailContainer key={order._id}>
              <DetailSubContainer>
                <LeftDetails>
                  <li>
                    <Title>
                      <b>Dish Name: </b>
                      {order.title}
                    </Title>
                  </li>
                  <li>
                    <Title>
                      <b>Dish Price: </b>{currency} {order.price}
                    </Title>
                  </li>
                  <li>
                    <Title>
                      <b>Address: </b>
                      {order.address}
                    </Title>
                  </li>
                  <li>
                    <Title>
                      <b>Contact :</b>
                      {order.chef.phoneNumber}
                      {/* {order.chef && order.chef.phoneNumber} */}
                    </Title>
                  </li>
                </LeftDetails>

                <MiddleDetails>
                  <li>
                    <DishQnty>Qty :{order.count}</DishQnty>
                  </li>
                </MiddleDetails>
                <RightDetails>
                  <li>
                    <DishQnty>Price:{currency} {order.price * order.count}</DishQnty>
                  </li>
                </RightDetails>
              </DetailSubContainer>
              <hr></hr>
            </DetailContainer>
          ))}
        </LeftSection>
        <RightSection>
          <Summary>
            <RightContainer>
              <OrderTitle>PAYMENT METHOD</OrderTitle>
              <RadioButtonContainer>
                <Label>
                  <input
                    type="radio"
                    name="radio1"
                    value="paypal"
                    onChange={onRadioChange}
                  />
                  <Circle
                    style={{
                      background: selectedBtn === "paypal" ? "orange" : "#fff"
                    }}
                  />
                  <OrderTitle>Debit and Credit card</OrderTitle>
                </Label>
              </RadioButtonContainer>
              <RadioButtonContainer>
                <Label>
                  <input
                    type="radio"
                    name="radio1"
                    value="razorpay"
                    onChange={onRadioChange}
                  />
                  <Circle
                    style={{
                      background: selectedBtn === "razorpay" ? "orange" : "#fff"
                    }}
                  />
                  <OrderTitle>RazorPay</OrderTitle>
                </Label>
              </RadioButtonContainer>
              <RadioButtonContainer>
                <Label>
                  <input
                    type="radio"
                    name="radio1"
                    value="cash"
                    onChange={onRadioChange}
                  />
                  <Circle
                    style={{
                      background: selectedBtn === "cash" ? "orange" : "#fff"
                    }}
                  />
                  <OrderTitle>Cash</OrderTitle>
                </Label>
              </RadioButtonContainer>

              <OrderText>
                <b>Total Payment:</b>{currency} {total}
              </OrderText>
              <OrderTitle>
                <b>Payment Method:</b>
                {paymentMethod}
              </OrderTitle>
              {paymentMethod === "paypal" ? (
                <div>
                  <PayPal datas={data} total={total} currency_type={currency} />
                </div>
              ) : (
                " "
              )}
              {paymentMethod === "cash" ? (
                <div>
                  <Button onClick={() => saveOrder(data,currency)}>Place Order</Button>
                </div>
              ) : (
                " "
              )}
              {paymentMethod === "razorpay" ? (
                     <div>
                     {/* <img src={RazorpayButton} alt="Button Image" width = "100px" height= "auto"></img> */}
                     <Razorpay datas={data} total={total} currency_type={currency} />
                     </div>
              ) : (" ")}
            </RightContainer>
          </Summary>
        </RightSection>
      </CheckoutContainer>
    </div>
  );
}
export default Checkout;
