import React, { useState, useEffect } from "react";
import { BreadCrumbs } from "../styled-components";
import FavouritesTable from "./FavouritesTable";
import axios from "axios";
import { BASE_URL } from "../../config/axios-config";
import { useUserAuth } from "../Context/AuthUserContext";

const client = axios.create({
  baseURL: BASE_URL
});

const MyFavourite = () => {
  const { Fbuser } = useUserAuth();
  const userId = Fbuser ? Fbuser.uid : null;
  const [favouriteDishes, setFavouriteDishes] = useState([]);

  useEffect(() => {
    if (userId) {
      client.get(`/users/${userId}/favouriteDishes`)
        .then(response => {
          setFavouriteDishes(response.data.favouriteDishes);
      
        })
        .catch(error => {
          console.error("Error fetching favourite dishes:", error);
        });
    }
  }, [userId]);


  return (
    <div className="p-4">
      <BreadCrumbs values={["My Orders", "My Favourite"]} />
      <FavouritesTable data={favouriteDishes}/>
    </div>
  );
};

export default MyFavourite;
