import React, { useContext, useState,useEffect } from "react";
import razorpaybutton from './rzpay_button.png'
import axios from "axios";
import { BASE_URL } from "../../config/axios-config";
import OrderService from "../../services/OrderService";
import { DishContext } from "../../providers/DishProvider";
import dotenv from 'dotenv'
dotenv.config();
function Razorpay(props) {
  // const client = axios.create({
  //   baseURL: BASE_URL,
  // });
                                                                                                  
  const [paid, setPaid] = useState(false);
  const value = useContext(DishContext);
  const [cart, setCart] = value.cart;

  
  const total_price = props.total;

  useEffect(() => {
    showMessage();
  }, []);

  const saveOrder = payid => {
    const orderData = {};
    orderData.payment = { id: payid, method: "razorpay" };
    orderData.data = props.datas;
    OrderService.saveOrders(orderData).then(() => {
      setCart([]);
    });
  };
  const showMessage = () => {
    const currency = props.currency_type;
    if (currency && currency !== "INR") {
      alert(
        `your country currency type is ${currency},which is not activated,you are using INR`
      );
    } else {
      return null;
    }
  };
  
    const buttonStyle = {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
    };
  
    const imageStyle = {
      width: '100px', 
      height: 'auto', 
    };

    function loadScript(src) {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    }

   //on click of button ,first issue post on /razororder 
   //after success return from razor ,issue post on /razorsuccess

        async function invokeRazorpay(rz_amount) 
      {
     
       

      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
  
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
     
      // const result = await client.post("/razororders",  
      const result = await axios.post(`${BASE_URL}/razororders`,  
   
       {
        KH_amount_tobepaid:rz_amount.total_price
      })
     
      if (!result) {
        alert("Server error. Are you online?");
        return;
      }  
      const { amount, id:order_id,currency } = result.data; //result is coming from kitchenhamara server
      
     // Note:  options carries the payment key,amout,curency,order details,handler - which has the post and the saveorder call inside
     //but handler itself is invoked from the loaded razor script
    
     const options = {
        
        key: process.env.REACT_APP_RAZOR_PAYMENT_KEYID, 
        // key:"rzp_test_e515DiDebiuGAB",//key_id
        amount: amount.toString(),
        currency: currency,
        order_id:order_id,
        name: "keybyte test",
        description: "Test Transaction",
      
      
        handler: async function (response) {
          const fromrazor_data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          
    try{
        console.log("Options",options);
          const result = await axios.post(`${BASE_URL}/razorsuccess`, fromrazor_data); 
          // alert(result.data.msg);
          saveOrder(fromrazor_data.razorpayPaymentId);
          // CatchPayment.savePayments(result.data);
        }catch(error){
              console.log("error in razorsuccess",error);
          }
          
        },
     
        prefill: {
          name: "keybytesystem",
          email: "example@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Example Corporate Office",
        },
        theme: {
          color: "#61dafb",
        },
   
     };
  
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } 
 
 
  return (
    <div>
        
      <button style={buttonStyle} 
      onClick = {()=>invokeRazorpay({total_price})} >
      <img src={razorpaybutton}  style = {imageStyle}></img>
      </button>
      
    </div>
   
  );
  }
  
  export default Razorpay