
import React, { useState, useEffect } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import ChatService from "../../services/ChatService";
import { database,CHAT_MSGS } from "../../config/firebase";
import Moment from "moment";
import {orderByChild, equalTo,ref,onValue,query} from "firebase/database"
import {
  ChatPage,
  ReceivedMsg,
  ReceivedMsgInbox,
  OutGoingChats,
  OutGoingChatMsg,
  MessageInput,
  InputDiv
} from "./StyledChat";

function ChatRoom(props) {
  const ChatRoomId = props.roomId;

  const userId = props.userId;
  const msgreceiverID = props.receiverId;
  const [chats, setChats] = useState([]);
  const [read] = useState(false);
  var emptyChat = {
    roomId: "",
    userId: "",
    message: "",
    date: "",
    type: "",
    isRead: "",
    receiverID:""
  };
  const [newChat, setNewChat] = useState(emptyChat);

  useEffect(() => {
    (
       async() => {
        const chatMsgRef = ref(database, CHAT_MSGS);
        // const SendQuery = query(chatMsgRef,orderByChild('roomId'),equalTo(ChatRoomId));
        const SendQuery = query(chatMsgRef,orderByChild('roomId'),equalTo(ChatRoomId));
        //query is designed to retrieve chat messages where the roomId property is equal to the id variable. 
        //This means it fetches chat messages specific to a particular room.
        
        onValue(SendQuery,(snapshot) => {
       
          setChats([]);
          setChats(ChatService.snapshotToArray(snapshot));  
        });
       })
    ();
  }, [ChatRoomId]);

  const onChange = e => {
    const { name, value } = e.target;
    setNewChat({ ...newChat, [name]: value });

  };

  const submitMessage = e => {
    e.preventDefault();
    const chat = newChat;
    chat.roomId = ChatRoomId;
    chat.isRead = read;
    chat.userId = userId;
    chat.date = Moment(new Date()).format("DD/MM/YYYY hh:mm A");
    chat.type = "message";
    chat.receiverID = msgreceiverID;
    
    ChatService.sendChat(chat);
    if(!ChatRoomId){
      ChatService.enterRoom()
    }
  
    setNewChat(emptyChat);
  };

  return (
    <>
      <ScrollToBottom>
        <ChatPage>
          {chats.map((chat) =>
            chat.userId === userId ? (
              <div key={chat.key}>
                <ReceivedMsg>
                  <ReceivedMsgInbox>
                    <p>{chat.message}</p>
                    <span>{chat.date}</span>
                  </ReceivedMsgInbox>
                </ReceivedMsg>
              </div>
            ) : (
              <div key={chat.key}>
                 <OutGoingChats key={chat.key}>
                  <OutGoingChatMsg>
                    <p>{chat.message}</p>
                    <span>{chat.date}</span>
                  </OutGoingChatMsg>
                </OutGoingChats>
              </div>
            )
          )}
        </ChatPage>
      </ScrollToBottom>

      <InputDiv>
        <form onSubmit={submitMessage}>
          <MessageInput
            type="text"
            name="message"
            value={newChat.message}
            onChange={onChange}
            required
            placeholder="write messages.."
          ></MessageInput>

          <button type="submit" className="submit-button">
            <span className="input-group-text">
              {" "}
              <i className="fa fa-paper-plane"></i>
            </span>
          </button>
        </form>
      </InputDiv>
    </>
  );
}

export default ChatRoom;

// import React, { useState, useEffect } from "react";
// import ScrollToBottom from "react-scroll-to-bottom";
// import ChatService from "../../services/ChatService";
// import Moment from "moment";
// import {database,   CHAT_MSGS } from "../../config/firebase";

// import {
//   ChatPage,
//   ReceivedMsg,
//   ReceivedMsgInbox,
//   OutGoingChats,
//   OutGoingChatMsg,
//   MessageInput,
//   InputDiv
// } from "./StyledChat";

// function ChatRoom(props) {
//   const id = props.roomId;
//   const userId = props.userId;
//   const [chats, setChats] = useState([]);
//   const [read] = useState(false);
//   var emptyChat = {
//     roomId: "",
//     userId: "",
//     message: "",
//     date: "",
//     type: "",
//     isRead: ""
//   };
//   const [newChat, setNewChat] = useState(emptyChat);

//   useEffect(() => {
//     (async () => {
//         database
//         .ref(CHAT_MSGS)
//         .orderByChild("roomId")
//         .equalTo(id)
//         .on("value", resp => {
//           setChats([]);
//           setChats(ChatService.snapshotToArray(resp));
//         });
//     })();
//   }, [id]);

//   const onChange = e => {
//     const { name, value } = e.target;
//     setNewChat({ ...newChat, [name]: value });
//   };

//   const submitMessage = e => {
//     e.preventDefault();
//     const chat = newChat;
//     chat.roomId = id;
//     chat.isRead = read;
//     chat.userId = userId;
//     chat.date = Moment(new Date()).format("DD/MM/YYYY hh:mm A");
//     chat.type = "message";
//     ChatService.sendChat(chat);
//     setNewChat(emptyChat);
//   };

//   return (
//     <>
//       <ScrollToBottom>
//         <ChatPage>
//           {chats.map((item, idx) =>
//             item.userId === userId ? (
//               <div key={item.key}>
//                 <ReceivedMsg>
//                   <ReceivedMsgInbox>
//                     <p>{item.message}</p>

//                     <span>{item.date}</span>
//                   </ReceivedMsgInbox>
//                 </ReceivedMsg>
//               </div>
//             ) : (
//               <div key={item.key}>
//                 <OutGoingChats key={item.key}>
//                   <OutGoingChatMsg>
//                     <p>{item.message}</p>
//                     <span>{item.date}</span>
//                   </OutGoingChatMsg>
//                 </OutGoingChats>
//               </div>
//             )
//           )}
//         </ChatPage>
//       </ScrollToBottom>

//       <InputDiv>
//         <form onSubmit={submitMessage}>
//           <MessageInput
//             type="text"
//             name="message"
//             value={newChat.message}
//             onChange={onChange}
//             required
//             placeholder="write messages.."
//           ></MessageInput>

//           <button type="submit" className="submit-button">
//             <span className="input-group-text">
//               {" "}
//               <i className="fa fa-paper-plane"></i>
//             </span>
//           </button>
//         </form>
//       </InputDiv>
//     </>
//   );
// }

// export default ChatRoom;
