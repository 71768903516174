import React, { useState, useEffect,useContext } from "react";
import OrderService from "../../services/OrderService";
import { USER_TYPE } from "../../constants/User.constants";
import { BreadCrumbs, Button } from "../styled-components";
import { useNavigate } from "react-router-dom";
import { DishContext } from "../../providers/DishProvider";
import {
  GridLayout,
  Container,
  DishImageContainer,
  DataContainer,
  ButtonContainer
} from "./StyledOrder";

const MyOrder = () => {
  const [orders, setOrders] = useState([]);
  const value = useContext(DishContext);
  const currency = value.currency
  const [view, setView] = useState("");
  const [ordersFetched, setOrdersFetched] = useState(false);
  const navigate = useNavigate();

  const fetchRecentOrders = async () => {
    try {
      const data = await OrderService.getOrdersByUser(USER_TYPE.USER);
      setOrders(data);
      setOrdersFetched(true);
    } catch (error) {
      console.error("Error fetching recent orders:", error);
      setOrders([]);
      setOrdersFetched(true);
    }
  };

  useEffect(() => {
    if (view === "recentOrders" && !ordersFetched) {
      fetchRecentOrders();
    }
  }, [view, ordersFetched]);

  const handleFavouriteClick = () => {
    navigate("/my-orders/my-favourites");
  };

  return (
    <>
      <div className="p-4">
        <BreadCrumbs values={["My Orders"]} />
        <ButtonContainer>
          <Button
            label="Recent Orders"
            onClick={() => setView("recentOrders")}
            type="button"
          />
          <Button
            label="My Favourite"
            onClick={handleFavouriteClick}
            type="button"
          />
        </ButtonContainer>
      </div>

      {view === "recentOrders" && ordersFetched && orders.length === 0 && (
        <div className="p-4">You have no recent orders.</div>
      )}

      {orders.length > 0 && (
        <GridLayout>
          {orders.map(order => (
            <Container key={order._id}>
              <DishImageContainer
                src={order.dish.thumbnail}
                alt="Dish thumbnail"
              ></DishImageContainer>
              <DataContainer>
                <ul>
                  <li>
                    <span>
                      <b>{order.dish.title}</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      By <b>{order.chef.name}</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      Order ID:<b>{order._id}</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      Order Time:<b>{order.orderDate}</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      Order Total:<b>{currency} {order.total}</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      Quantity:<b>{order.quantity}</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      Payment Method:<b>{order.payment.method}</b>
                    </span>
                  </li>
                  <li>
                    <span>PickUp Time:</span>
                  </li>
                  <li>
                    <span>
                      Status:<b>{order.status}</b>
                    </span>
                  </li>
                </ul>
              </DataContainer>
            </Container>
          ))}
        </GridLayout>
      )}
    </>
  );
};

export default MyOrder;
