import React, { useState, useContext } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import styled from "styled-components";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button } from "../styled-components";
import { ORDER_STATUS } from "../../constants/Order.constants";
import { SpinnerContext } from "../../providers/SpinnerProvider";
import OrderService from "../../services/OrderService";
import { DishContext } from "../../providers/DishProvider";

const Container = styled.div`
  padding: 1em;
`;

const DishImg = styled.img`
  object-fit: cover;
  width: 100%;
  max-width: 130px;
  height: auto;
  margin-right: 1em;

  @media screen and (max-width: 40em) {
    max-width: 150px;
    display: block;
    margin: 0;
  }
`;

const StyledTable = styled(Table)`
  tr {
    box-shadow: none;
  }

  th {
    background-color: white;
    color: gray;
    text-align: center;
    padding: 0.5em 0em;
  }

  td {
    padding: 0.5em 0.5em;
    text-align: center;
  }

  .td-dish {
    text-align: left;
  }

  .item-header {
    background-color: #5050503a;
  }

  .item-box {
    border-radius: 0.25em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .separator {
    background-color: transparent;
    height: 10px;
  }

  .firstTd {
    text-align: left;
  }

  .lastTd {
    text-align: right;
  }

  @media screen and (max-width: 40em) {
    td {
      padding: 0em 0.5em;
    }
    tbody tr {
      border: 0;
    }

    .lastTd {
      text-align: left;
    }
  }
`;

const OrderStatus = ({ id, status }) => {
  const spinner = useContext(SpinnerContext);
  const [_status, setStatus] = useState(status);

  const updateOrder = status => {
    (async () => {
      let obj = { status: status };
      const current = await OrderService.updateOrderStatus(id, obj, spinner);
      if (current) {
        setStatus(current.status);
      }
    })();
  };

  const SwitchStatusBtns = () => {
    switch (_status) {
      case ORDER_STATUS.NEW:
        return (
          <>
            <Button
              label="Start"
              type="button"
              onClick={() => updateOrder(ORDER_STATUS.ONGOING)}
            />
            <br />
            <Button
              secondary
              label="Cancel"
              type="button"
              onClick={() => updateOrder(ORDER_STATUS.CANCELLED)}
            />
          </>
        );
      case ORDER_STATUS.ONGOING:
        return (
          <>
            <Button
              tertiary
              label="Done"
              type="button"
              onClick={() => updateOrder(ORDER_STATUS.FORPICKUP)}
            />
          </>
        );
      default:
        return (
          <>
            <Button label="Details" type="button" />
          </>
        );
    }
  };

  return (
    <>
      <Td>{_status}</Td>
      <Td className="lastTd">
        <SwitchStatusBtns />
      </Td>
    </>
  );
};

const OrdersTable = ({ data }) => {
  const dishContext = useContext(DishContext);
  const currency = dishContext.currency
  const orderArray = data ? data : [];
  return (
    <Container>
      <StyledTable>
        <Thead>
          <Tr>
            <Th>Dishes</Th>
            <Th>Quantity</Th>
            <Th>Date</Th>
            <Th>Order Total</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        {orderArray.map((order, id) => (
          <Tbody key={id}>
            <Tr className="item-header">
              <Td colSpan="5" className="firstTd">
                <i className="fas fa-user-alt" />
                <b>{order.customer.name}</b>
              </Td>
              <Td colSpan="1" className="lastTd">
                <b>Order ID:</b> {order._id}
              </Td>
            </Tr>
            <Tr className="item-box">
              <Td className="td-dish">
                <DishImg src={order.dish.thumbnail} alt="Dish thumbnail" />
                {order.dish.title}
              </Td>
              <Td>{order.quantity}x</Td>
              <Td>{order.orderDate}</Td>
              <Td>{currency} {order.total}</Td>
              <OrderStatus id={order._id} status={order.status} />
            </Tr>
            <Tr className="separator"></Tr>
          </Tbody>
        ))}
      </StyledTable>
    </Container>
  );
};

export default OrdersTable;
