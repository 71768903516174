import styled from "styled-components";

export const ChatPopup = styled.div`
  display: block;
  width: 270px;
  position: fixed;
  background: white;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
`;

export const ChatContainer = styled.div`
  width: 100%;
  border: none;
  background: white;
`;
export const MsgHeader = styled.div`
  border: 1px solid #ccc;
  width: 100%;
  height: 80px;
  border-bottom: none;
  display: inline-flex;
  background-color: orange;
`;

export const MsgHeaderImg = styled.div`
  border-radius: 50%;
  width: 40px;
  margin-top: 12px;
  float: left;
`;
export const Profile = styled.img`
  width: auto;
  border: 2px solid green;
  border-radius: 50%;
`;

export const Active = styled.div`
  width: 150px;
  float: left;
  margin-top: 10px;
  overflow: hidden;

  h4 {
    font-size: 20px;
    margin-left: 5px;
    margin-top: 15px;
    color: black;
  }
`;
export const BackArrow = styled.div`
  width: 25px;
  img {
    width: 25px;
    margin-top: 25px;
    margin-left: 5px;
  }
`;

export const ChatPage = styled.div`
  padding: 0 0 0 0;
  overflow-y: scroll;
  height: 270px;
`;
export const ReceivedMsg = styled.div`
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
`;
export const ReceivedMsgInbox = styled.div`
  width: 52%;

  p {
    display: block;
    background: #fff none repeat scroll;
    border: 2px solid green;
    border-radius: 10px;
    color: black;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
    overflow-wrap: break-word;
  }
  span {
    color: #777;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
  }
`;

export const OutGoingChats = styled.div`
  margin: 26px 20px;
`;

export const OutGoingChatMsg = styled.div`
  float: left;
  width: 50%;
  margin-left: 45%;
  p {
    background: #fff none repeat scroll 0 0;
    border: 2px solid orange;
    color: black;
    border-radius: 10px;
    font-size: 14px;
    margin-right: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
    overflow-wrap: break-word;
  }
  span {
    color: #777;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
  }
`;

export const InputDiv = styled.div`
  background: green;
  height: 90px;
`;
export const MessageInput = styled.input`
  margin-top: 15px;
  outline: none !important;
  border-radius: 5px;
  width: 80%;
  height: 40px;
  background: #fff;
`;
