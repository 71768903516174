import React, { useEffect, createContext, useState, useContext } from "react";
import { auth, storage } from "../config/firebase";
import UserService from "../services/UserService";
//import ChatService from "../services/ChatService";
import { CURRENT_USER } from "../constants/User.constants";
import { SpinnerContext } from "./SpinnerProvider";
import { ref,getDownloadURL } from "firebase/storage";

export const UserContext = createContext();

export default function UserProvider(props) {
  const spinner = useContext(SpinnerContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    spinner.show();
    auth.onAuthStateChanged(async (userAuth) => {
      let user = userAuth;
      if (user) {
        try {
          if (typeof user.updateProfile === "function"){
          const imgUrl = await getDownloadURL(ref(storage, `profile/${user.uid}/profile.jpg`));
          await user.updateProfile({
            photoURL: imgUrl
          });
        }
          setUser(user);
          
          if (user.emailVerified || user.phoneNumber) {
            if (!user.displayName) {
              let localUser = UserService.getUserSessionStorage(user.uid);
              setUser(localUser ? localUser : user);
              UserService.removeUserSessionStorage(user.uid);
            }
            // ChatService.signIn(user.uid, user.displayName);


            // await UserService.checkIfUserExist(user);
          }
        } catch (error) {
          console.error("Error updating profile:", error);
        }
      } else {
        UserService.removeUserSessionStorage(CURRENT_USER);
      }
      spinner.hide();
    });
  }, [spinner]);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
}