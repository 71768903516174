import React, { useState, useEffect } from "react";
import DynamicForm from "../../dynamic-form";
import { Button, Modal } from "../../styled-components";
import { ChooseBtnsDiv, TextLink } from "./StyledDialogLogin";
import { emailPw, email, phone, name, otp } from "./loginFormConfigs";

export const LOGIN_ID = {
  SIGNUP: "SIGNUP",
  SIGNIN: "SIGNIN",
  RESET: "RESET",
  FORM_ID: "loginForm"
};

const DialogLogin = ({ modalOpen, handleModal, ...props }) => {
  const [loading, isLoading] = useState(false);

  const initialState = {
    title: "Sign in",
    btnLabel: "Sign in",
    signInPhone: false,
    signUp: false,
    signUpPhone: false,
    forgotPw: false,
    fieldConfig: emailPw,
    submitId: LOGIN_ID.SIGNIN
  };
  const [state, updateState] = useState(initialState);
  const [timer, setTimer] = useState(10);
  const { fieldConfig } = state;

  useEffect(() => {
    updateState({
      title: "Sign in",
      btnLabel: "Sign in",
      signInPhone: false,
      signUp: false,
      signUpPhone: false,
      forgotPw: false,
      fieldConfig: emailPw,
      submitId: LOGIN_ID.SIGNIN
    });
  }, [modalOpen]);

  useEffect(() => {
    isLoading(false);
    if (fieldConfig === otp) {
      if (timer > 0) {
        setTimeout(() => setTimer(timer - 1), 1000);
      }
    }
  }, [timer, fieldConfig]);

  const onSubmit = data => {
    if (state.signInPhone || state.signUpPhone) {
      isLoading(true);
      updateState({
        ...state,
        ...{
          fieldConfig: otp,
          btnLabel: "Verify"
        }
      });
      props.handleSubmit(state.submitId, data);
      setTimer(10);
      return;
    }
    props.handleSubmit(state.submitId, data);
  };

  const clickForgotPw = () => {
    isLoading(true);
    updateState({
      ...state,
      ...{
        title: !state.forgotPw ? "Reset you Password" : "Sign in",
        btnLabel: !state.forgotPw ? "Send link" : "Sign in",
        forgotPw: !state.forgotPw,
        fieldConfig: !state.forgotPw ? email : emailPw,
        submitId: !state.forgotPw ? LOGIN_ID.RESET : LOGIN_ID.SIGNIN
      }
    });
  };

  const clickSIPhone = () => {
    isLoading(true);
    updateState({
      ...state,
      ...{
        signInPhone: !state.signInPhone,
        fieldConfig: !state.signInPhone ? phone : emailPw
      }
    });
  };

  const clickSignUp = () => {
    isLoading(true);
    !state.signUp
      ? updateState({
          ...state,
          ...{
            title: !state.signUp ? "Sign up" : "Sign in",
            btnLabel: !state.signUp ? "Sign up" : "Sign in",
            signUp: !state.signUp,
            signInPhone: false,
            submitId: !state.forgotPw ? LOGIN_ID.SIGNUP : LOGIN_ID.SIGNIN,
            fieldConfig: !state.signUp ? name.concat(emailPw) : emailPw
          }
        })
      : updateState(initialState);
  };

  const clickSUPhone = () => {
    isLoading(true);
    updateState({
      ...state,
      ...{
        signUpPhone: !state.signUpPhone,
        fieldConfig: !state.signUpPhone
          ? name.concat(phone)
          : name.concat(emailPw)
      }
    });
  };

  const clickResendOtp = () => {
    isLoading(true);
    setTimer(10);
    props.sendPhoneOTP(state.submitId);
  };

  return (
    <Modal
      isOpen={modalOpen}
      height="400px"
      width="50%"
      onRequestClose={() => handleModal(false)}
      shouldCloseOnOverlayClick={true}
    >
      <h5>{state.title}</h5>
      {state.title === "Sign in" && (
        <ChooseBtnsDiv>
          <div className="first">
            <Button label="Sign in with Google" onClick={props.googleSignIn} />
          </div>
          <div>
            <Button
              label={`Sign in with ${!state.signInPhone ? "Phone" : "Email"}`}
              onClick={clickSIPhone}
            />
          </div>
        </ChooseBtnsDiv>
      )}
      {!loading && (
        <DynamicForm
          formId={LOGIN_ID.FORM_ID}
          fieldConfig={state.fieldConfig}
          handleSubmit={onSubmit}
        />
      )}
      {!state.signInPhone && !state.signUp && (
        <TextLink onClick={clickForgotPw}>
          {state.forgotPw ? (
            <span>
              <i className="fas fa-arrow-left" />
              Back to Sign In
            </span>
          ) : (
            <span>
              Forgot your password? <br />
            </span>
          )}
        </TextLink>
      )}
      {fieldConfig === otp && (
        <TextLink
          disabled={timer > 0}
          onClick={timer > 0 ? null : clickResendOtp}
        >{`Resend code ${timer > 0 ? `(${timer})` : ""}`}</TextLink>
      )}
      {state.signUp && fieldConfig !== otp && (
        <TextLink onClick={clickSUPhone}>
          {`Use ${state.signUpPhone ? "email" : "phone"} instead`}
          <br />
        </TextLink>
      )}
      {!state.forgotPw &&
        fieldConfig !== otp &&
        (!state.signUp ? (
          <>
            Don't have an account?{" "}
            <TextLink onClick={clickSignUp}>
              <b>Sign up here</b>
            </TextLink>
          </>
        ) : (
          <>
            You have an account?{" "}
            <TextLink onClick={clickSignUp}>
              <b>Sign in here</b>
            </TextLink>
          </>
        ))}
      {!loading && <div id="recaptcha-container" />}
      <div className="modal-footer">
        <hr />
        <Button form={LOGIN_ID.FORM_ID} type="submit" label={state.btnLabel} />
        <Button secondary onClick={() => handleModal(false)} label="Close" />
      </div>
    </Modal>
  );
};

export default DialogLogin;
