import React, { useState, useEffect } from "react";
import {
  Input,
  InputList,
  InputFile,
  Button,
  IconLink,
  Dropdown
} from "../styled-components";

const Buttons = ({ config }) => (
  <Button
    secondary={config.type === "button" ? true : false}
    label={config.label}
    type={config.type}
    name={config.name}
    {...config}
    onClick={() =>
      config.onClick ? config.onClick[config.functionName].call() : null
    }
  />
);

const DynamicForm = ({ fieldConfig, handleSubmit, displayReset, ...props }) => {
  const configArray = fieldConfig ? fieldConfig : [];
  const initialValue = configArray.reduce(
    (o, key) => ({ ...o, [key.name]: key.value }),
    {}
  );
  const [formFields, setFormFields] = useState(initialValue);

  useEffect(() => {
    let initialValue = configArray.reduce(
      (o, key) => ({ ...o, [key.name]: key.value }),
      {}
    );
    setFormFields(initialValue);
  }, [configArray]);

  const handleInputChange = event => {
    if (event.target) {
      const { name, value } = event.target;
      setFormFields({ ...formFields, [name]: value });
    } else {
      setFormFields({ ...formFields, ...event });
    }
  };

  const formSubmit = event => {
    event.preventDefault();
    handleSubmit(formFields);
    resetForm();
  };

  const resetForm = () => {
    setFormFields(initialValue);
  };

  const renderSwitch = (type, config) => {
    switch (type) {
      case "submit": {
        return <Buttons config={config} />;
      }
      case "url": {
        return (
          <div>
          <label>{config.label}:</label>
          <a href={config.value} target="_blank" rel="noopener noreferrer">
            {config.value}
          </a>
        </div>
        );
      }
      
      case "button": {
        return <Buttons config={config} />;
      }
      case "file": {
        return (
          <InputFile
            label={config.label}
            name={config.name}
            value={formFields[config.name] || []}
            {...config}
            onChange={handleInputChange}
          />
        );
      }
      case "list": {
        return (
          <InputList
            label={config.label}
            name={config.name}
            value={formFields[config.name] || []}
            {...config}
            onChange={handleInputChange}
          />
        );
      }
      case "selects": {
        return (
          <Dropdown
            label={config.label}
            name={config.name}
            value={formFields[config.name] || []}
            {...config}
          />
        );
      }
      default: {
        return (
          <Input
            label={config.label}
            type={config.type ? config.type : "text"}
            name={config.name}
            value={formFields[config.name] || ""}
            {...config}
            onChange={config.onChange ? config.onChange : handleInputChange}
          />
        );
      }
    }
  };

  return (
    <div>
      <form
        id={props.formId}
        onSubmit={formSubmit}
        encType="multipart/form-data"
      >
        {configArray.map((config, i) => (
          <span key={i}>{renderSwitch(config.type, config)}</span>
        ))}
        {displayReset ? (
          <IconLink
            icon="fas fa-sync-alt"
            label="Reset Form"
            onClick={resetForm}
          />
        ) : null}
      </form>
    </div>
  );
};

export default DynamicForm;
