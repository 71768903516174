export const Curency_code = [
  {
    country: "India",
    currency: "INR"
  },
  {
    country: "Brazil",
    currency: "BRL"
  },
  {
    country: "Australia",
    currency: "AUD"
  },
  {
    country: "United states",
    currency: "USD"
  },
  {
    country: "Canada",
    currency: "CAD"
  },
  {
    country: "Japan",
    currency: "JPY"
  },
  {
    country: "Philippines",
    currency: "PHP"
  },
  {
    country: "Russia",
    currency: "RUB"
  }
];
