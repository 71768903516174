import SettingsClientApi from "../client-api/SettingsClientApi";
import AuditLogService from "./AuditLogService";
import { OPERATION_ID, STATUS } from "../constants/AuditLogs.constants";
import { LOCATION } from "../constants/Settings.constants";
import toastr from "toastr";

const SettingsService = {
  getCurrentCountry: async () => {
    let currentCty = JSON.parse(localStorage.getItem(LOCATION.COUNTRY));
    if (currentCty && currentCty.country) {
      return currentCty;
    } else {
      const country = await fetch(LOCATION.IP_URL)
        .then(res => res.json())
        .then(res => {
          AuditLogService.sendLog(
            STATUS.SUCCESS,
            OPERATION_ID.GET_COUNTRY_CODE
          );
          let location = {
            city: res.city,
            continent: res.continent,
            country: res.country,
            countryCode: res.countryCode,
            region: res.region
          };

          localStorage.setItem(LOCATION.COUNTRY, JSON.stringify(location));
          return location;
        })
        .catch(error => {
          AuditLogService.sendLog(
            STATUS.FAILED,
            OPERATION_ID.GET_COUNTRY_CODE,
            `${OPERATION_ID.GET_COUNTRY_CODE} error = ${error} `
          );
          return null;
        });
      return country;
    }
  },

  getCountryList: async () => {
    const data = await SettingsClientApi.getCountryList()
      .then(result => {
        AuditLogService.sendLog(
          STATUS.SUCCESS,
          OPERATION_ID.RETRIEVE_COUNTRY_LIST
        );
        return result.data.countries;
      })
      .catch(error => {
        AuditLogService.sendLog(
          STATUS.FAILED,
          OPERATION_ID.RETRIEVE_COUNTRY_LIST,
          `${OPERATION_ID.RETRIEVE_COUNTRY_LIST} error = ${error} `
        );
        toastr.error("An error occured in getting country list.");
        console.log("Error in getting country List",error)
        return null;
      });
    return data;
  },

  getCountryByCode: async () => {
    const country = await SettingsService.getCurrentCountry();
    const data = await SettingsClientApi.getCountryByCode(country.countryCode)
      .then(result => {
        AuditLogService.sendLog(
          STATUS.SUCCESS,
          OPERATION_ID.RETRIEVE_COUNTRY_DATA
        );
        return result.data;
      })
      .catch(error => {
        AuditLogService.sendLog(
          STATUS.FAILED,
          OPERATION_ID.RETRIEVE_COUNTRY_DATA,
          `${OPERATION_ID.RETRIEVE_COUNTRY_DATA} error = ${error} `
        );
        toastr.error("An error occured in getting country data.");
        return null;
      });
    return data;
  }
};

export default SettingsService;
