import styled from "styled-components";

export const CheckoutContainer = styled.div`
font-size: calc(12px + 1vw)
height: 100%;
display: grid;
grid-template-columns: 1fr 400px;
grid-template-areas:
"left right";
@media screen and (max-width: 768px) {
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 15vh;
  grid-template-areas:
  "left"
  "right"
  "button-holder";
  font-size: 3vw;
  hr {
    margin: 8px 0;
  }
}
h6{
font-size: 15px;
 list-style: none;
 overflow:hidden;
 font-family: "Open Sans", sans-serif;
}
`;

export const LeftSection = styled.div`
  grid-area: left;
`;

export const RightSection = styled.div`
  grid-area: right;
  background-color: #fff5f0;
  h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    top: -14%;
  }
`;
export const RightContainer = styled.div`
  width: 80%;
  margin: auto;
  button {
    background-color: #ff8c00;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 2px 1px;
    cursor: pointer;
  }
`;

export const DetailContainer = styled.ul`
  width: 80%;
  margin: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;

  li {
    list-style-type: none;
  }
`;
export const DetailSubContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const RightDetails = styled.div`
  width: 100px;
`;
export const MiddleDetails = styled.div`
  width: 100px;
`;
export const LeftDetails = styled.div`
  width: 100%;
`;
export const RadioButtonContainer = styled.div`
  width: 80%;
  margin: auto;
  padding: 0;
  box-sizing: border-box;
  font-family: serif;
  font-size: 3em;
  display: flex;
`;

export const Label = styled.label`
  display: flex;
  color: black;
  input {
    display: none;
  }
`;
export const Circle = styled.div`
  position: relative;
  height: 12px;
  width: 12px;
  cursor: pointer;
  display: inline-block;
  right: 15px;
  border: 3px solid #ff8c00;
  display: inline-block;
  border-radius: 50%;
`;
