import React from "react";
import styled from "styled-components";

const Nav = styled.div`
  margin-bottom: 15px;
  text-align: center;

  .dot {
    font-size: 36px;
    line-height: 1;
    margin: 0 15px;
    opacity: 0.4;
    text-shadow: none;
    transition: opacity 1s ease, text-shadow 1s ease;
    will-change: opacity, text-shadow;
  }

  .active {
    color: orange;
    opacity: 1;
    text-shadow: 0 0px 8px;
  }
`;

const NavIndicator = props => {
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    dots.push(
      <span key={`step-${i}`} className={`dot ${isActive ? "active" : ""}`}>
        &bull;
      </span>
    );
  }

  return <Nav>{dots}</Nav>;
};

export default NavIndicator;
