import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../providers/UserProvider";
import UserService from "../../services/UserService";
import UsersClientApi from "../../client-api/UsersClientApi";
import DynamicForm from "../dynamic-form";
import {auth,storage,update_Profile} from "../../config/firebase";
import "./user-profile.css";
import { SpinnerContext } from "../../providers/SpinnerProvider";
import toastr from "toastr";
import { User } from "../../models/User.model";
import SettingsService from "../../services/SettingsService";
import { useUserAuth } from "../Context/AuthUserContext";
import { getDownloadURL,ref,uploadBytes} from "firebase/storage"
function UserProfile(props) {
  const defaultCountry = ["Australia"];
  const [countries, setCountries] = useState(null);
  const initialState = {
    ...User,
   
  };
 
  
  const { Fbuser ,updateUserPhoto} = useUserAuth();
  
  const [user, setUser] = useState(initialState);
 
  const spinner = useContext(SpinnerContext);
  let loginIdentifier="";
 
  useEffect(() => {
    (async () => {
      if (Fbuser) {
        // console.log("newusedata in  ",JSON.stringify(Fbuser));
     
        if (Fbuser.email) {
          loginIdentifier = Fbuser.email;
      } else if (Fbuser.phoneNumber) {
        loginIdentifier = Fbuser.phoneNumber;
      } 
        var user_details = await UsersClientApi.getUserByUid(Fbuser.uid);
       
        setUser({
          uid:Fbuser.uid,
          email: user_details.data.email?user_details.data.email:Fbuser.email,
          name: user_details.data.name?user_details.data.name:Fbuser.displayName,
          phoneNumber: user_details.data.phoneNumber?user_details.data.phoneNumber:Fbuser.phoneNumber,
          photo:user_details.data.photo?user_details.data.photo:Fbuser.photoURL,
          country: user_details.data.country,
          address: user_details.data.address,
          loginIdentifier:user_details.data.loginIdentifier?user_details.data.loginIdentifier:loginIdentifier,
        });
      }
    })();
  }, [Fbuser]);


  useEffect(() => {
    (async () => {
      const data = await SettingsService.getCountryList();
      if (data !== null && data.length > 0) {
        setCountries(data.map(x => x.country_name));
      } else {
        setCountries(defaultCountry.map(x => x));
      }
    })();
  }, []);
 
  

const onSubmitProfile = async data => {
  try {
    // console.log("User Photo",data.photo);
    if (data.photo) {
      const file = data.photo;
      const filePath = `usr_photos/${Fbuser.uid}/profile.jpg`;
      const photoRef = ref(storage,filePath);
      await uploadBytes(photoRef, file[0]);
      const photoURL = await getDownloadURL(photoRef); 
      user.photo = photoURL;
     
      setUser({ ...user, photo: photoURL });

        updateUserPhoto(photoURL);
            update_Profile(Fbuser, {
              photoURL: photoURL
            }).then(() => {
              
             
            }).catch((error) => {
              console.log("failed updating user profile")
            }); }
    await UserService.updateUser(user, spinner);
    toastr.success("Profile updated successfully");
  } catch (error) {
    console.log("Error updating profile:", error);
    toastr.error("Failed to update profile");
  }
};
  const onSubmitPassword = data => {
    var user = auth().currentUser;
    var credential;
    credential = auth.EmailAuthProvider.credential(
      user.email,
      data.current_pw
    );
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        user
          .updatePassword(data.pw)
          .then(() => {
            toastr.success("Successfully password updated");
          })
          .catch(err => {
            toastr.error("Password does not match");
          });
      })
      .catch(err => {
        toastr.error("Error in password reset");
      });
  };
  const closeForm = () => console.log("close form");

  const handleChange = event => {
    const { name, value } = event.target;
    //console.log("handleChange:"+ JSON.stringify(name))
    setUser({ ...user, [name]: value });
  };
  const handlePhone = event => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };
  const handleCountryChange = event => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const handlePhotoChange = event =>{
    const file = event.target.files[0]; 
    setUser({ ...user, photo: file });
  }



  const fieldConfig_profile = [
    {
      name: "email",
      label: "Email Address",
      type: "text",
      value: user.email,
      onChange: handleChange,
      required: true,
      // disabled: user.email.includes('@') ? true : false
     
    },
    {
      name: "name",
      label: "Name",
      type: "text",
      value: user.name,
      onChange: handleChange,
      required: true
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      type: "text",
      value: user.phoneNumber,
      onChange: handlePhone,
      required: true,
      
    },
    {
      name: "country",
      label: "Country",
      type: "selects",
      options: countries,
      onChange: handleCountryChange,
      value: user.country
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      onChange: handleChange,
      value: user.address
    },
   
      {
        name: "Stored photoURL",
        label: "photoURL",
        type: "url",
        value:user.photo
      },
    {
      name: "photo",
      label: "photo",
      type: "file",
      onChange:handlePhotoChange,
      value: ""
      
    },
    {
      name: "submit",
      label: "Submit",
      type: "submit"
    },
    {
      name: "cancel",
      label: "Cancel",
      type: "button",
      onClick: { closeForm },
      functionName: "closeForm"
    }
  ];

  const fieldConfig_password = [
    {
      name: "current_pw",
      label: "Current Password",
      type: "password",
      required: true,
      pattern: ".{8,}",
      title: "8 characters minimum",
      placeholder: "current password"
    },
    {
      name: "pw",
      label: "Password",
      type: "password",
      required: true,
      pattern: ".{8,}",
      title: "8 characters minimum",
      placeholder: "new password"
    },
    {
      name: "submit",
      label: "Submit",
      type: "submit"
    },
    {
      name: "cancel",
      label: "Cancel",
      type: "button",
      onClick: { closeForm },
      functionName: "closeForm"
    }
  ];

  return (
    <div className="col-md-8 offset-md-2">
      <h5>My Profile </h5>
      <hr></hr>
      <DynamicForm
        fieldConfig={fieldConfig_profile}
        handleSubmit={onSubmitProfile}
      />
      <hr></hr>
      <h5>Password </h5>
      <hr></hr>
      <DynamicForm
        fieldConfig={fieldConfig_password}
        handleSubmit={onSubmitPassword}
      />
    </div>
  );
}

export default UserProfile;
