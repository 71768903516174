import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from '../../../Context/AuthUserContext';
import { Form, Input, Button} from 'antd';
import { GoogleOutlined,MailOutlined } from "@ant-design/icons";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { signInWithCredential, PhoneAuthProvider } from 'firebase/auth';
import axios from 'axios';
import { generateToken } from "../../../../config/firebase";
import { getDownloadURL } from "firebase/storage";
const SignInPhone = ({onButtonClick}) => {
  
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [verificationId,setVerificationId] = useState(null);
   const{savePhoneNumberAsLoginIdentifier,googleSignIn} = useUserAuth()
  const navigate = useNavigate();

  useEffect(() => {
    // Ensure Firebase Auth is initialized and reCAPTCHA container is ready
    if (!window.recaptchaVerifier) {
        const initializeRecaptcha = () => {
            const authDebug = getAuth();
           
            window.recaptchaVerifier = new RecaptchaVerifier(getAuth(),'recaptcha-container', {
              size: 'invisible',
              callback: (response) => {
                // reCAPTCHA verified
              }})}
      initializeRecaptcha()
    }
  }, []);

  const handleSendOtp = () => {
    const auth = getAuth();
    const phoneNumberInput = number; // Ensure phoneNumber includes country code
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumberInput, appVerifier)
      .then((confirmationResult) => {
      
     
        setVerificationId(confirmationResult.verificationId);
      }).catch((error) => {
      
        console.error(error);
      });
  };

  const verifyOtp = async() => {
    const auth = getAuth();
   
    const credential = PhoneAuthProvider.credential(verificationId, otp);
    try{
      const result = await  signInWithCredential(auth, credential);
      const user = result.user;
      
      const fcmToken = await generateToken();
    
     await savePhoneNumberAsLoginIdentifier(user,fcmToken);
            user.reload().then(() => {
                console.log("reloaded user:" ,user); 
              });
    }
    catch(error)  {
            
            console.log(error);
        };
};
 


  const handleGoogleSignIn = async() =>{
     await googleSignIn();
  }

  return (
    <>
      <div className="p-4 box">
        <h2 className="mb-3">Sign In with Phone</h2>
          <div id="recaptcha-container"></div>
     
        <Form layout="vertical"  >
          <Form.Item label="Phone Number">
            <Input
              value={number}
              onChange={(e)=>setNumber(e.target.value)}
              placeholder="Enter Phone Number"
            />
          
          </Form.Item>

          <Button type="primary" htmlType="submit" onClick={handleSendOtp}>
              Send Otp
            </Button>
            <Form.Item label='OTP'>
              <Input
              
              value={otp}
              onChange={(e)=>setOtp(e.target.value)}
              placeholder="Enter OTP"
              />
             
            </Form.Item>

            <Form.Item>
              <Button onClick={verifyOtp}>Verify OTP</Button>
            </Form.Item>
          

        <Form.Item>
        
        <Button
          type="default"
          icon={<GoogleOutlined />}
          className="login-form-google-button"
          onClick={handleGoogleSignIn}
        >
          Sign Up with Google
        </Button>
          
      </Form.Item>

      <Form.Item>
        
        <Button
          type="default"
          icon={<MailOutlined />}
          className="login-form-google-button" 
          onClick={() =>onButtonClick('signinemail')}
        >
          Sign Up with Mail
        </Button>
        
      </Form.Item>
      <div style={{ textAlign: 'left' }}>
          Don't have an Account?{" "}
          <Link onClick={() =>onButtonClick('signupPhone')}>
            Sign Up </Link>
            </div>
        </Form>
            <Link to="/">
              <Button>Cancel</Button>
            </Link>
      </div>
    </>
  );
};

export default SignInPhone;
