import React, { useContext, useState, useEffect } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { SpinnerContext } from "../../providers/SpinnerProvider";
import DishService from "../../services/DishService";
import { DishModel } from "../../models/Dish.model";
import UpdateDishTable from "./UpdateDishTable";
import {
  DishDetailsContainer,
  LeftSection,
  RightSection,
  DetailsContainer,
  SideBarDetails,
  ButtonHolder
} from "../styled-components/StyledDishDetails";
import { Button, IconLink, BreadCrumbs } from "../styled-components";
import DynamicForm from "../dynamic-form";

function MyDishDetails() {
  const params = useParams();
  const id = params.id;
  const spinner = useContext(SpinnerContext);
  const [dish, setDish] = useState(DishModel);
  const [prevDish, setPrevDish] = useState({});
  const [updatedDish, setUpdatedDish] = useState({});
  const [disabled, isDisabled] = useState(true);
  const [resetForm, setResetForm] = useState(false);
  const navigate = useNavigate(); 

  const handleInputChange = event => {
    if (event.target) {
      const { name, value } = event.target;
      validateUpdate(name, value);
      setDish({ ...dish, [name]: value });
    } else {
      setDish({ ...dish, ...event });
    }
  };

  const validateUpdate = (key, value) => {
    if (prevDish[key] !== value) {
      isDisabled(false);
      setUpdatedDish({ ...updatedDish, [key]: value });
    } else {
      let state = { ...updatedDish };
      delete state[key];
      setUpdatedDish(state);
    }
  };

  const reset = newDish => {
    setDish(newDish.hasOwnProperty("_id") ? newDish : prevDish);
    setUpdatedDish({});
    setResetForm(true);
  };

  const updateDish = () => {
    (async () => {
      let _updatedDish;
      _updatedDish = await DishService.updateDish(
        dish._id,
        updatedDish,
        spinner
      );
      if (_updatedDish) {
        setPrevDish(_updatedDish);
        reset(_updatedDish);
      }
    })();
  };

  const deleteDish = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this dish?");
    if (confirmDelete) {
      const deleted = await DishService.deleteDish(dish._id, spinner);
      if (deleted) {
        navigate("/my-kitchen");
      }
    }
  };

  const fieldConfig = [
    {
      name: "time",
      label: "Serving Time",
      type: "time",
      value: "13:00",
      readOnly: true,
      onChange: handleInputChange
    },
    {
      name: "availablity",
      label: "Availability",
      type: "text",
      value: "Every Moday",
      readOnly: true,
      onChange: handleInputChange
    },
    {
      name: "address",
      label: "Address",
      type: "location",
      required: true,
      value: dish.address,
      onChange: handleInputChange
    },
    {
      name: "price",
      label: "Price",
      type: "number",
      value: dish.price,
      onChange: handleInputChange
    }
  ];

  useEffect(() => {
    if (Object.keys(updatedDish).length === 0) {
      isDisabled(true);
    }
    if (resetForm) {
      setResetForm(false);
    }
  }, [updatedDish, resetForm]);

  useEffect(() => {
    var dish = {};
    (async () => {
      dish = await DishService.getDishById(id);
      setPrevDish(dish);
      setDish(dish);
    })();
  }, [id]);

  return (
    <DishDetailsContainer>
      <LeftSection>
        <DetailsContainer>
          <BreadCrumbs values={["My Kitchen", "My Dish"]} />
          <IconLink
            icon="fas fa-sync-alt"
            label="reset"
            className="reset-text"
            onClick={reset}
          />
          {!resetForm && (
            <UpdateDishTable
              dish={dish}
              handleInputChange={handleInputChange}
            />
          )}
        </DetailsContainer>
      </LeftSection>
      <RightSection>
        <SideBarDetails>
          <div className="side-details">
            <DynamicForm fieldConfig={fieldConfig} />
            <IconLink
              icon="far fa-trash-alt"
              label="Delete Dish?"
              className="reset-text"
              onClick={deleteDish}
            />
          </div>
        </SideBarDetails>
      </RightSection>
      <ButtonHolder>
        <div className="buttons">
          <Button label="Update" onClick={updateDish} disabled={disabled} />
          <Button
            secondary
            label="Preview"
            onClick={() =>
              window.open(
                `/dish/${dish._id}/${DishService.convertToSlug(dish.title)}`
              )
            }
          />
        </div>
      </ButtonHolder>
    </DishDetailsContainer>
  );
}

export default MyDishDetails;
