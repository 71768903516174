export default class AuditLogModel {
    constructor(status, operationTypeId, modifiedById, modifiedBy, participantId, location, modificationDateTime, description) {
      this.status = status;
      this.operationTypeId = operationTypeId ;
      this.modifiedById = modifiedById;
      this.modifiedBy = modifiedBy;
      this.participantId = participantId;
      this.location = location;
      this.modificationDateTime = modificationDateTime;
      this.description = description;
    }
}

export const AuditLog = {
    status: "",
    operationTypeId : "",
    modifiedBy: "",
    modifiedById: "",
    participantId: "",
    location: {
      lat: "",
      lng: ""
    },
    modificationDateTime: "",
    description: ""
};