import styled from "styled-components";
import { Link } from "react-router-dom";


export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 23rem);
  justify-content: center;
  
  @media screen and (max-width: 480px) {
    justify-content: center;
    margin: auto;
  }
`;

export const Container = styled.div`
  background: #fff;
  margin: 1.5rem;
  width: 85%;
  justify-content: center;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  max-width: 496px;
`;

export const SubContainer = styled.div`
  position: relative;
`;

export const DishImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border: "1px solid orange";
`;

export const DishTitle = styled.p`
  display: inline;
  color: #2f4f4f;
  font-family: "Tinos", serif;
  font-size: 1.2rem;
`;

export const List = styled.li`
  list-style: none;
`;



export const Chef = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // color: #808080;
  color: #FF6C22;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  margin-top:5px;
`;

export const ChefName = styled.span`
  margin-right: 5px;
`;

export const ChefPhoto = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const DishLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`;


export const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  width: 100%;
`;

export const NavigationButton = styled.button`
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #333;
  padding: 5px 5px;
  outline: none;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
  color: #1890ff; 
  font-size: 16px; 
  margin-top:5px;
`;

export const LikeCount = styled.span`
  margin-left: 5px; 
  font-size: 16px; 
  font-weight: bold; 
`;
