// import React, { useState, useEffect } from "react";
// import { Input, Button } from "../../styled-components";
// import styled from "styled-components";

// const Container = styled.div`
//   background-color: #8fc28f;
//   display: grid;
//   width: 1fr;
//   grid-template-columns: auto 1fr auto;

//   .input-container > * {
//     margin: 10px 10px;
//   }

//   button {
//     max-height: 37px;
//     height: 100%;
//     margin: 10px 10px 10px 0;
//     position: relative;
//   }
//   .filter-icon {
//     color: #f09133;
//     margin: 10px 0 10px 10px;
//     cursor: pointer;
//     padding: 5px;
//     border-radius: 0.4em;
//     border: 0.5px solid #f09133;
//     :hover {
//       background-color: #aedaae;
//     }
//   }

//   .btn-txt {
//     margin-right: 0.5em;
//   }

//   @media screen and (max-width: 768px) {
//     .btn-txt {
//       display: none;
//     }
//   }
// `;
// const SearchBar = ({
//   filterOn,
//   setFilterOn,
//   setText,
//   filter,
//   setFilter,
//   setPageNumber,
//   totalPage
// }) => {
//   const initialState = {
//     search: ""
//   };

//   const [searchs, setSearch] = useState(initialState);
//   const handleChange = event => {
//     const { name, value } = event.target;

//     setSearch({ ...searchs, [name]: value });
//     setText(event.target.value);
//   };
//   const updateState = () => {
//     if (searchs.search.length > 0) {
//       setFilter(!filter);
//       setPageNumber(totalPage);
//     }
//   };
//   return (
//     <Container>
//       <div className="filter-icon" onClick={() => setFilterOn(!filterOn)}>
//         <i className="fas fa-filter" />
//       </div>
//       <div className="input-container">
//         <Input
//           label="Search"
//           name="search"
//           value={searchs.search}
//           type="text"
//           onChange={handleChange}
//         />
//       </div>
//       <Button type="button" onClick={() => updateState()}>
//         <span className="btn-txt">Search</span>
//         <i className="fas fa-search" />
//       </Button>
//     </Container>
//   );
// };

// export default SearchBar;





import React, { useState, useEffect } from "react";
import { Input, Button } from "../../styled-components";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: cloumn;

  button {
    width: 10px;
  }

  .input-container {
    width: 300px;
  }

  @media screen and (max-width: 768px) {
    .btn-txt {
      display: none;
    }
  }
`;
const SearchBar = ({
  filterOn,
  setFilterOn,
  setText,
  filter,
  setFilter,
  setPageNumber,
  totalPage
}) => {
  const initialState = {
    search: ""
  };

  const [searchs, setSearch] = useState(initialState);
  const handleChange = event => {
    const { name, value } = event.target;

    setSearch({ ...searchs, [name]: value });
    setText(event.target.value);
  };
  const updateState = () => {
    if (searchs.search.length > 0) {
      setFilter(!filter);
      setPageNumber(totalPage);
    }
  };
  return (
    <Container>
      <div className="input-container">
        <Input
          label="Search"
          name="search"
          value={searchs.search}
          type="text"
          onChange={handleChange}
        />
      </div>
      <Button type="button" onClick={() => updateState()}>
        <span className="btn-txt"></span>
        <i className="fas fa-search" />
      </Button>
    </Container>
  );
};

export default SearchBar;