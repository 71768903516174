
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {database, CHAT_ROOM, CHAT_MSGS} from "../../config/firebase";
import {ref,orderByChild,equalTo,onValue,query} from "firebase/database"
import { useUserAuth } from "../Context/AuthUserContext";
import ChatService from "../../services/ChatService";
import { Link } from "react-router-dom";
import {
  Container,
  ChatList,
  ChatImg,
  ChatLeft,
  ChatRight,
  ChatRightTop,
  ContactName,
  ChatDate,
  ChatRightBottom,
  Header,
  ChatContainer,
  SubContainer
} from "./StyledChat";


function ChatMessage() {
  // const user = useContext(UserContext);
  const {Fbuser} = useUserAuth();
  const [chatDta, setChatData] = useState();
  const [show, setShow] = useState(false);

  let arr = [];
  let lstmsg = [];
  const navigate = useNavigate()
  useEffect(() => {
    if (Fbuser) {                            //user replaced with Fbuser
     
      const chatRef = ref(database,CHAT_ROOM);

      onValue(chatRef,(snapshot)=>{
       
          var data = snapshot.val();
       
           console.log("Data in Snapshot",data);
          var keys = Object.keys(data);
          

          for (var i = 0; i < keys.length; i++) {
            var k = keys[i];
            console.log("K",k);
            var roomId = data[k].roomId;
            
            var users = data[k].users;
            console.log("Users",users);
            
            for (var j = 0; j < users.length; j++) {
              if (Fbuser.uid === users[j].uid) {
                var username;
                var userId;
                for (var m = 0; m < users.length; m++) {
                  if (Fbuser.displayName !== users[m].name) {
                    username = users[m].name;
                    userId = users[m].uid;
                    break;
                  }
                }
                const y = {
                  roomId: roomId,
                  usersname: username,
                  userId: userId
                };
                // push(arr,y);
                arr.push(y);
              }
            }
          }
          getLastMessage(arr);
          setShow(true);
        });
    }
  }, [Fbuser, show]);

  function getLastMessage(arr) {
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        var roomId = arr[i].roomId;
        var username = arr[i].usersname;
        var userId = arr[i].userId;
        let ms = { username: username, roomId: roomId, userId: userId };
        let y = {};
        const messageRef = ref(database,CHAT_MSGS)
        const q = query(messageRef,orderByChild("roomId"),equalTo(roomId))
            onValue(q,(snapshot)=>{
            const msg = ChatService.snapshotToArray(snapshot);
            var l = msg.length - 1;
            if (msg.length > 0) {
              let unseen = msg.filter(
                users => users.userId === ms.userId && users.isRead === false
              );
              var len = unseen.length;
              y = {
                roomId: ms.roomId,
                username: ms.username,
                userId: ms.userId,
                message: msg[l].message,
                date: msg[l].date,
                unseen_message: len,
               
              };
  
              // push(lstmsg,y);
              lstmsg.push(y);
              setChatData(lstmsg);
              setShow(false);
            }
          });
      }
    }
  }

  const data = chatDta ? chatDta : [];
   console.log("Data in my-message",data);
  return (
    <ChatContainer>
      <Header>
        <h4>My messages</h4>
      </Header>
      <Container>
        {data.map((sender, i) => (
          <ChatList key={i}>
            <SubContainer>
              <ChatLeft>
                <ChatImg
                  src={`${sender.username}`}
                  alt="ysyw"
                ></ChatImg>
              </ChatLeft>
              <ChatRight  onClick={() => navigate(`/chat-room`, 
                  {state:{ roomId:sender.roomId,
                    sender_name: sender.username}})} >
                  <ChatRightTop>
                    <ContactName>{sender.username}</ContactName>
                    <ChatDate>{sender.date}</ChatDate>
                  </ChatRightTop>
              
                <ChatRightBottom>
                  <div className="chat-right-bottom-left">
                    <span className="chat-message">{sender.message} </span>
                  </div>
                </ChatRightBottom>
              </ChatRight>
            </SubContainer>
          </ChatList>
        ))}
      </Container>
    </ChatContainer>
  );
}
export default ChatMessage;


