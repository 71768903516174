import React, { useContext, useState, useEffect } from "react";
import { DishContext } from "../../providers/DishProvider";
import { useNavigate } from 'react-router-dom';

import "./cart.css";
import {
  CartHeader,
  Remove,
  Increament,
  Decreament,
  Title,
  List,
  Checkout
} from "./StyledCart";

export default function Cart() {
  const value = useContext(DishContext);
  const [cart, setCart] = value.cart;
  const [SelectedItems, SetSelection] = useState([]);
  const currency = value.currency
  const [total, setTotal] = useState(0);
  const [counts] = useState(1);
  const navigate = useNavigate();

 
  
  useEffect(() => {
    setCart(
      cart.map(d => {
        return {
          select: true,
          _id: d._id,
          title: d.title,
          address: d.address,
          chef: d.chef,
          cookTime: d.cookTime,
          location: d.location,
          count: counts,
          price: d.price,
          thumbnail: d.thumbnail,
          status: d.status
        };
      })
    );
  }, []);

  useEffect(() => {
    const getTotal = () => {
      const item = cart.filter(carts => carts.select === true);
    
      const result = item.reduce((prev, item) => {
        return prev + item.price * item.count;
      }, 0);
      setTotal(result);
     
      SetSelection(item);
   

    };
    getTotal();
  }, [cart]);

  const decrement = id => {
    cart.forEach(item => {
      if (item._id === id) {
        item.count === 1 ? (item.count = 1) : (item.count -= 1);
      }
    });
    setCart([...cart]);
  };

  const increment = id => {
    cart.forEach(item => {
      if (item._id === id) {
        item.count += 1;
      }
    });
    setCart([...cart]);
  };

  const removeProduct = id => {
    if (window.confirm("Do you want to delete this product?")) {
      cart.forEach((item, index) => {
        if (item._id === id) {
          cart.splice(index, 1);
        }
      });
      setCart([...cart]);
    }
  };

  if (cart.length === 0)
    return (
      <div style={{ textAlign: "center", fontSize: "5rem" }}>
        <h3 data-testid="header">Cart is empty</h3>
      </div>
    );

  return (
    <>
      <CartHeader>
        <List>
        
          <p>total:{currency} {total}.00</p>
          <Checkout
            style={{ textDecoration: "none" }}
            onClick={() => {
              navigate("/checkout", { state: SelectedItems });
            }}
          >Checkout</Checkout>
        </List>
      </CartHeader>
      <table>
        <thead>
          <tr>
            <td>
              <input
                type="checkbox"
                onChange={e => {
                  let checked = e.target.checked;
                  setCart(
                    cart.map(d => {
                      d.select = checked;
                      return d;
                    })
                  );
                }}
                defaultChecked="checked"
              />
              <label>Select all</label>
            </td>
          </tr>
        </thead>
        <tbody>
          {cart.map(carts => (
            <tr key={carts._id}>
              <td>
                <input
                  onChange={event => {
                    let checked = event.target.checked;
                    setCart(
                      cart.map(data => {
                        if (carts._id === data._id) {
                          data.select = checked;
                        }
                        return data;
                      })
                    );
                  }}
                  type="checkbox"
                  checked={carts.select}
                />
                <img
                  className="image"
                  src={carts.thumbnail[0]}
                  alt="Dish thumbnail"
                />
              </td>
              <td>
                <Title>
                  <b>{carts.title}</b>
                </Title>
              </td>
              <td>
                <Decreament onClick={() => decrement(carts._id)}>-</Decreament>
                <span>{carts.count}</span>
                <Increament onClick={() => increment(carts._id)}>+</Increament>
                <Remove onClick={() => removeProduct(carts._id)}>
                  <i className="fa fa-trash"> Remove </i>
                </Remove>
              </td>
              <td>
                <Title>
                  <b>price:{currency} {carts.price}</b>
                </Title>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}