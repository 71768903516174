import { database, CHAT_USER, CHAT_ROOM, CHAT_MSGS } from "../config/firebase";
import { ref, orderByChild, equalTo, onValue, push,query } from "firebase/database";
import { CURRENT_USER } from "../constants/User.constants";

const userRef = ref(database, CHAT_USER);
const roomRef = ref(database, CHAT_ROOM);
const messagesRef = ref(database, CHAT_MSGS);

const ChatService = {
  signIn: (uid, name) => {
    const user = { uid: uid, status: "online", displayName: name };

    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      const existingUser = Object.values(data).find((x) => x.uid === uid);
      if (existingUser) {
        let userRef = ref(database, `${CHAT_USER}/${existingUser.key}`);
        // Update user status
        userRef.update({ status: "online" });
      } else {
        // Create new user
        push(userRef, user);
      }
      sessionStorage.setItem(CURRENT_USER, JSON.stringify(user));
    });
  },

  signOut: async () => {
    let uid = JSON.parse(sessionStorage.getItem(CURRENT_USER)).uid;
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      const existingUser = Object.values(data).find((x) => x.uid === uid);
      if (existingUser) {
        let userRef = ref(database, `${CHAT_USER}/${existingUser.key}`);
     
        userRef.update({ status: "offline" });
      }
    });
    sessionStorage.removeItem(CURRENT_USER);
  },


  enterRoom: (room) => {
    const q = query(roomRef, orderByChild('roomId'),equalTo(room.roomId))
    onValue(q, (snapshot) => {
      const data = snapshot.val();
    
       if(!data){
        push(roomRef,room);
       }
    });

    return room.roomId;
  },
 
  

  getChats: (id,callback) => {
    // const snapshot = await onValue(messagesRef,{ orderByChild: "roomId", equalTo: id });
     onValue(messagesRef,orderByChild('roomId'),equalTo(id),(snapshot) => {
     
      callback(snapshot.val());
  })
    
  },

  sendChat: (chat) => {
    
    push(messagesRef, chat);
  },
  //chats sent to database with sendChats

  snapshotToArray: (snapshot) => {
    const chats = [];
   
    snapshot.forEach((childSnapshot) => {
      const chat = childSnapshot.val();
      chat.key = childSnapshot.key;
     
      chats.push(chat);

    });
    return chats;
  },
  
  
  
//   snapshotToArray: (snapshot) => {

//     const returnArr = [];
//     snapshot.forEach((childSnapshot) => {
//       const item = childSnapshot.val();
//       item.key = childSnapshot.key;
//       returnArr.push(item);
//     });
//     // onValue(messagesRef, (snapshot) => {
//     //   snapshot.forEach((childSnapshot) => {
//     //     const childData= childSnapshot.val();
//     //     childData.key = childSnapshot.key;
        
//     //     returnArr.push(
//     //       childData
//     //     )
//     //   });
//     // })
//     return returnArr;  
//   }
};

export default ChatService;




// import { database, CHAT_USER, CHAT_ROOM, CHAT_MSGS } from "../config/firebase";
// import { ref } from "firebase/database";
// import { CURRENT_USER } from "../constants/User.constants";
// const userRef = ref(database, CHAT_USER);
// const roomRef = ref(database, CHAT_ROOM);
// const messagesRef = ref(database, CHAT_MSGS);


// // const firebaseDb = getDatabase();
// // const userRef = ref(firebaseDb, CHAT_USER);
// // const roomRef = ref(firebaseDb, CHAT_ROOM);
// // const messagesRef = ref(firebaseDb, CHAT_MSGS);

// const ChatService = {
//   signIn: (uid, name) => {
//     const user = { uid: uid, status: "online", displayName: name };

//     userRef
//       .orderByChild("uid")
//       .equalTo(uid)
//       .once("value", snapshot => {
//         if (snapshot.exists()) {
//           const existingUser = ChatService.snapshotToArray(snapshot).find(
//             x => x.uid === uid
//           );
//           if (existingUser !== undefined) {
//             let userRef = database.ref(CHAT_USER + existingUser.key);
//             userRef.update({ status: "online" });
//           }
//         } else {
//           const newUser = userRef.push();
//           newUser.set(user);
//         }
//         sessionStorage.setItem(CURRENT_USER, JSON.stringify(user));
//       });
//   },

//   signOut: async () => {
//     let uid = JSON.parse(sessionStorage.getItem(CURRENT_USER)).uid;
//     await userRef
//       .orderByChild("uid")
//       .equalTo(uid)
//       .once("value", snapshot => {
//         const existingUser = ChatService.snapshotToArray(snapshot).find(
//           x => x.uid === uid
//         );
//         if (existingUser !== undefined) {
//           let userRef = database.ref(CHAT_USER + existingUser.key);
//           userRef.update({ status: "offline" });
//         }
//       });
//     sessionStorage.removeItem(CURRENT_USER);
//   },

//   enterRoom: room => {
//     roomRef
//       .orderByChild("roomId")
//       .equalTo(room.roomId)
//       .once("value", snapshot => {
//         if (snapshot.exists()) {
//           // console.log("room exist === ", room.roomId)
//         } else {
//           const newRoom = database.ref(CHAT_ROOM).push();
//           newRoom.set(room);
//         }
//         return room.roomId;
//       });
//   },

//   getChats: id => {
//     var chats = messagesRef
//       .orderByChild("roomId")
//       .equalTo(id)
//       .on("value", result => {
//         return ChatService.snapshotToArray(result);
//       });
//     return chats;
//   },

//   sendChat: chat => {
//     const newMessage = messagesRef.push();
//     newMessage.set(chat);
//   },

//   snapshotToArray: snapshot => {
//     const returnArr = [];

//     snapshot.forEach(childSnapshot => {
//       const item = childSnapshot.val();
//       item.key = childSnapshot.key;
//       returnArr.push(item);
//     });

//     return returnArr;
//   }
// };

// export default ChatService;
