import React, { useContext, useEffect, useState, useCallback } from "react";
import UserService from "../../../services/UserService";
import styled from "styled-components";
import { Input, Button } from "../../styled-components";
import { UserContext } from "../../../providers/UserProvider";
import UsersClientApi from "../../../client-api/UsersClientApi";
import DishService from "../../../services/DishService";
import { SpinnerContext } from "../../../providers/SpinnerProvider";
import debounce from "lodash.debounce";
const Container = styled.div``;

function useDebounce(callback, delay) {
  const debouncedFn = useCallback(
    debounce((...args) => callback(...args), delay),
    [delay]
  );
  return debouncedFn;
}

function DishFilterComponent({
  dishes,
  setDishes,
  setLocation,
  filterRefresh,
  setFilterRefresh,
  ...props
}) {
  const spinner = useContext(SpinnerContext);
  const initialFilter = { distance: "5000", protein: "00.00", carb: "00.00" };
  const [filter, setFilter] = useState(initialFilter);
  const userData = useContext(UserContext);
  const [homeLocation, setHomeLocation] = useState(null);
  const [hlocation, setHlocation] = useState(false);
  const [lnglat, setLnglat] = useState(null);
  const [btnName, setBtnName] = useState("home");
  useEffect(() => {
    (async () => {
      const loc = await UserService.getUserLocation();
      setLnglat(loc);
    })();
  }, []);

  const debouncedSave = useDebounce((lnglat, hlocation, homeLocation) => {
    if (lnglat) {
      if (hlocation) {
        setLocation({
          distance: filter.distance,
          lat: homeLocation.lat,
          lng: homeLocation.lng
        });
      } else {
        setLocation({
          distance: filter.distance,
          lat: lnglat.lat,
          lng: lnglat.lng
        });
      }
    }
  }, 500);

  const handleInputChange = async event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    debouncedSave(lnglat, hlocation, homeLocation);
  };

  const resetFilters = () => {
    setLocation(null);
    setFilter(initialFilter);
    setFilterRefresh(!filterRefresh);
  };
  const setHomelatlng = async () => {
    var user_details;
    spinner.show();
    var user_details = await UsersClientApi.getUserByUid(userData.uid);
    if (
      user_details &&
      user_details.data.address &&
      user_details.data.address !== ""
    ) {
      const latlng = await DishService.getLatLng(user_details.data.address);
      setHomeLocation(latlng);
      setHlocation(!hlocation);
      setLocation({
        distance: filter.distance,
        lat: latlng.lat,
        lng: latlng.lng
      });
      setBtnName("current");
      spinner.hide();
    } else {
      spinner.hide();
      alert("Please add your adress in Profile page!");
    }
  };
  const setCurrentlatlng = async () => {
    spinner.show();
    setBtnName("home");
    setLocation({
      distance: filter.distance,
      lat: lnglat.lat,
      lng: lnglat.lng
    });
    setHlocation(!hlocation);
    spinner.hide();
  };
  return (
    <Container>
      <label className="col-sm-2 col-form-label">Distance</label>
      <div className="form-inline">
        <div className="form-group col-sm-12">
          <div className="col-sm-9">
            <input
              name="distance"
              type="range"
              min="0"
              max="5000"
              className="form-control-range"
              value={filter.distance}
              onChange={handleInputChange}
            />
          </div>
          <Input
            label="meters"
            name="distance"
            type="number"
            min="0"
            max="5000"
            placeholder="1000"
            value={filter.distance}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <Button
        secondary
        label="All Dishes"
        type="button"
        onClick={resetFilters}
      />
      {userData && (
        <Button
          secondary
          label={
            hlocation ? `Use ${btnName} location` : `Use ${btnName} location`
          }
          onClick={() =>
            btnName === "current" ? setCurrentlatlng() : setHomelatlng()
          }
          type="button"
        />
      )}
    </Container>
  );
}

export default DishFilterComponent;
