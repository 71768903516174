import React from "react";
import { Input, InputList, InputFile, Dropdown } from "../styled-components";

const SimpleDynamicForm = ({ formId, fieldConfig, handleSubmit }) => {
  const configArray = fieldConfig ? fieldConfig : [];

  const renderSwitch = (type, config) => {
    switch (type) {
      case "file": {
        return <InputFile value={config.value || ""} {...config} />;
      }
      case "list": {
        return <InputList value={config.value || ""} {...config} />;
      }
      case "select": {
        return <Dropdown value={config.value || ""} {...config} />;
      }
      default: {
        return <Input value={config.value || ""} {...config} />;
      }
    }
  };

  return (
    <div>
      <form id={formId} onSubmit={handleSubmit} encType="multipart/form-data">
        {configArray.map((config, i) => (
          <span key={i}>{renderSwitch(config.type, config)}</span>
        ))}
      </form>
    </div>
  );
};

export default SimpleDynamicForm;
