import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <BrowserRouter> 
    <App />
    </BrowserRouter>
  
);
// ReactDOM.render(
//   <>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </>,
//   document.getElementById("root")
// );


// serviceWorker.register();
//Updated according to react v18