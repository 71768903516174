import AuditLogClientApi from "../client-api/AuditLogClientApi";
import AuditLogModel from "../models/AuditLog.model";
import UserService from "./UserService";
import Moment from "moment";
import { User } from "../models/User.model";

const AuditLogService = {
  sendLog: async (status, operationTypeId, description) => {
    try {
      await AuditLogClientApi.saveLog(
        AuditLogService.createLog(status, operationTypeId, description)
      );
    } catch (error) {
      console.log("***Error from saveLog: ", error);
    }
  },

  createLog: (
    status,
    operationTypeId,
    description,
    participantId,
    location
  ) => {
    const currentUser = UserService.getFbCurrentUser();
    let desc = description ? description : `${status}: ${operationTypeId}`;
    let dateTime = Moment(new Date()).format("DD/MM/YYYY hh:mm:ss");
    let user = currentUser ? currentUser : User;
    let log = new AuditLogModel(
      status,
      operationTypeId,
      user.uid,
      user.displayName,
      participantId,
      location,
      dateTime,
      desc
    );

    return log;
  }
};

export default AuditLogService;
