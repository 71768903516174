import styled from "styled-components";

export const DishDetailsContainer = styled.div`
    font-size: calc(12px + 1vw)
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 450px;
    grid-template-rows: auto 15vh ;
    grid-template-areas:
    "left right"
    "left button-holder";
    
  hr {
    margin: 5px 0;
    border-top: 1px solid #50c23c;
  }


  @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto 10vh;
      grid-template-areas:
      "left"
      "right"
      "button-holder";
      font-size: 3vw;

      hr {
        margin: 8px 0;
      }
  }
`;

export const LeftSection = styled.div`
  grid-area: left;
`;

export const RightSection = styled.div`
  grid-area: right;
  background-color: #fff5f0;
  height: 100%;
`;

export const SliderContainer = styled.div`
  height: 300px;
  margin-top:25px;

  .slider {
    max-height: 300px;
  }

  .carousel .slide {
    background-color: white;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    margin: -10% auto auto auto;
  }

  @media screen and (max-width: 768px) {
    height: auto;

    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0;
    }
  }
`;

export const DetailsContainer = styled.div`
  grid-area: details;
  padding: 2em;
  margin-left:50px;

  .title-text {
    vertical-align: middle;
    font-weight: bold;
    font-size: calc(12px + 1.2vw);
  }

  .reset-text {
    font-size: calc(12px + 0.2vw);
    float: right;
    color: gray;
    text-decoration: none;
  }

  .date-pub {
    font-style: italic;
  }

  .chef-details {
    width: 20%;
    float: right;
    text-align: right;
    position: relative;
    z-index: 10;
    top: -80px;
    height: auto;
  }

  .dish-header {
    float: left;
    width: 80%;
  }

  .chef-icon {
    border: 3px solid #50c23c;
    object-fit: cover;
    width: 100%;
    max-width: 80px;
    height: auto;
  }

  .list-details {
    width: 100%;
    float: right;
  }

  ul,
  ol {
    margin-left: 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 1em;

    .chef-details {
      top: -40px;
      width: 30%;
    }

    .chef-icon {
      max-width: 50px;
    }

    .dish-header {
      width: 70%;
    }

    .date-pub {
      font-size: 2.5vw;
    }
  }
`;

export const SideBarDetails = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: auto;
  padding: 1em;

  .piechart-container {
    width: 100%;
    height: 250px;
    text-align: center;
  }

  .ask-button {
    background-color: #50c23c;
  }

  @media screen and (max-width: 768px) {
    .piechart-container {
      height: 130px;
      width: 50%;
      text-align: right;
      float: right;
    }

    .side-details {
      display: table-cell;
      vertical-align: middle;
      height: auto;
      width: 50%;
    }
  }
`;

export const ButtonHolder = styled.div`
  grid-area: button-holder;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #fff5f0;
  height: 15vh;
  display: table;
  z-index: 999;
  .buttons {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    height: 10vh;
  }
`;