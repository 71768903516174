import React from 'react';
import {  Routes, Route } from 'react-router-dom';
import routes from './routes';
import TitleBar from './components/title-bar';
import HomeRoute from './routes/Home.route';

const AppRoute = () => {

  return (
     <>
      <TitleBar />
      <div className="content">
         <Routes>
       
          {
          routes.map((route) => (
            // !{route.name == "LandingPage"} && 
            <Route key={route.name} path={route.url} element={
                <route.component />
             } />
            ))}
         
        </Routes>
       
      </div>
      <div className="footer">
      
      </div>
   </>
  );
};
export default AppRoute;
// import React, { useContext, useEffect, useState } from "react";
// import { Router,Routes, Route,useNavigate } from "react-router-dom"; 
// import routes from "./routes";


// import { UserContext } from "./providers/UserProvider";
// import { CURRENT_USER } from "./constants/User.constants";
// import TitleBar from "./components/title-bar";


// // const createHistory = require("history").createBrowserHistory;
// // const history = createBrowserRouter();



// function ValidateRoute({ requireAuth, ...props }) {
//   const user = window.sessionStorage.getItem(CURRENT_USER);
//   const userCtx = useContext(UserContext);
//   const [loading, setLoading] = useState(true);
//   const [isAuthenticated, setAuth] = useState(false);
//   const navigate = useNavigate();
//   console.log('ValidateRoute' + JSON.stringify(props));
//   useEffect(() => {
//     if (userCtx) {
//       setAuth(true);
//     } else if (user) {
//       setAuth(true);
//     }
//     setLoading(load => !load);
//   }, [userCtx, user]);

//   return requireAuth ? (
//     isAuthenticated ? (
//       <Route {...props} />
//     ) : loading ? null : (
//       // <redirect to={{ pathname: "/" }} /> 
//       // Corrected the usage of Redirect
//       navigate("/") 
//     )
//   ) : (
//     <Route {...props} />
//   );
// }

// function AppRoute() {
//   return (
//     <Routes>
    
//       <TitleBar />
//       <div className="content">
//         {  
//           routes.map(route => {
//             console.log("rendering route:" + JSON.stringify(route));
//             return (
//               <ValidateRoute
//                 key={route.name}
//                 path={route.url}
//                 exact={route.exact}
//                 requireAuth={route.requireAuth}
//                 component={route.component}
//               />
//             );
//           })
//         } 
//       </div>
      
//       <div className="footer">
        
//       </div>
    
//     </Routes>
//   );
// }

// export default AppRoute;
