import React, { useContext, useState, useEffect } from "react";
import { DishContext } from "../../providers/DishProvider";
import DishMap from "../dish/dish-map";
//import DishList from '../dish/dish-list';
import DishService from "../../services/DishService";

function DishesNearMe() {
  const allDishes = useContext(DishContext);
  const initialFilter = { distance: "1000", protein: "00.00", carb: "00.00" };
  const [dishes, setDishes] = useState(allDishes);
  const [filter, setFilter] = useState(initialFilter);
  const handleInputChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    filterData();
  };
  useEffect(() => {
    if (allDishes) {
      setDishes(allDishes);
    }
  }, [allDishes]);

  const filterData = () => {
    var filteredData = DishService.filterDishesByDistance(
      allDishes,
      filter.distance
    );
    var _dishes = [];
    if (parseInt(filter.protein) !== 0 || parseInt(filter.carb) !== 0) {
      filteredData.forEach(element => {
        if (
          parseInt(element.proteins) <= parseInt(filter.protein) ||
          parseInt(element.carbs) <= parseInt(filter.carb)
        ) {
          _dishes.push(element);
        }
      });
    } else {
      _dishes = filteredData;
    }
    setDishes(_dishes);
  };

  return (
    <>
      <h5>Dishes near me! </h5>
      <hr></hr>
      <div>
        <form>
          <div className="form-inline">
            <div className="form-group col-sm-12">
              <label className="col-sm-2 col-form-label">Distance</label>
              <div className="col-sm-2">
                <input
                  name="distance"
                  type="range"
                  min="0"
                  max="3000"
                  className="form-control-range"
                  value={filter.distance}
                  onChange={handleInputChange}
                />
              </div>
              <input
                name="distance"
                type="text"
                value={filter.distance}
                onChange={handleInputChange}
                className="col-sm-2 form-control"
                placeholder="Distance"
              />
              m
            </div>
            <div className="form-group col-sm-12">
              <label className="col-sm-2 col-form-label">Proteins</label>
              <div className="col-sm-2">
                <input
                  name="protein"
                  type="range"
                  min="0"
                  max="3000"
                  step="0.01"
                  className="form-control-range"
                  value={filter.protein}
                  onChange={handleInputChange}
                />
              </div>
              <input
                name="protein"
                type="text"
                value={filter.protein}
                onChange={handleInputChange}
                className="col-sm-2 form-control"
                placeholder="Proteins"
              />
              g
            </div>
            <div className="form-group col-sm-12">
              <label className="col-sm-2 col-form-label">Carbs</label>
              <div className="col-sm-2">
                <input
                  name="carb"
                  type="range"
                  min="0"
                  max="3000"
                  step="0.01"
                  className="form-control-range"
                  value={filter.carb}
                  onChange={handleInputChange}
                />
              </div>
              <input
                name="carb"
                type="text"
                value={filter.carb}
                onChange={handleInputChange}
                className="col-sm-2 form-control"
                placeholder="Carbs"
              />
              g
            </div>
          </div>
        </form>
      </div>
      <DishMap dishes={dishes}></DishMap>
    </>
  );
}

export default DishesNearMe;
