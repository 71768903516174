import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;

  & > .link {
    color: #f09133;
    cursor: pointer;
  }

  & > .link:after {
    text-decoration: none !important;
    padding: 0 8px;
    color: black;
    content: ">";
  }

  & > .link > .text-link:hover {
    font-weight: 500;
    text-decoration: underline;
  }

  & > hr {
    margin: 10px 0;
    border-top: 1px solid #50c23c;
  }
`;

/**
 * A Plaid-inspired custom input component
 *
 * @param {array} values - the value of each label to be iterated as links in the breadcrumbs
 */
const BreadCrumbs = ({ values }) => {
  const navigate = useNavigate();
  const lastItem = values[values.length - 1];
  values.pop();

  return (
    <Container>
      {values.map((text, i) => (
        <span key={i} className="link" onClick={() => navigate(-1)}>
          <span className="text-link">{text}</span>
        </span>
      ))}
      <b>{lastItem}</b>
      <hr />
    </Container>
  );
};

export default BreadCrumbs;
