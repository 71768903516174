import React,{useContext} from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import styled from "styled-components";
import { Button } from "../styled-components";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { DishContext } from "../../providers/DishProvider";
import toastr from "toastr";

const Container = styled.div`
  padding: 1em;
`;

const DishImg = styled.img`
  object-fit: cover;
  width: 100%;
  max-width: 130px;
  height: auto;
  margin-right: 1em;

  @media screen and (max-width: 40em) {
    max-width: 150px;
    display: block;
    margin: 0;
  }
`;

const StyledTable = styled(Table)`
  tr {
    box-shadow: none;
  }

  th {
    background-color: white;
    color: gray;
    text-align: center;
    padding: 0.5em 0em;
  }

  td {
    padding: 0.5em 0.5em;
    text-align: center;
  }

  .td-dish {
    text-align: left;
  }

  .item-header {
    background-color: #5050503a;
  }

  .item-box {
    border-radius: 0.25em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .separator {
    background-color: transparent;
    height: 10px;
  }

  .firstTd {
    text-align: left;
  }

  .lastTd {
    text-align: right;
  }

  @media screen and (max-width: 40em) {
    td {
      padding: 0em 0.5em;
    }
    tbody tr {
      border: 0;
    }

    .lastTd {
      text-align: left;
    }
  }
`;

const FavouritesTable = ({ data }) => {
  const dishContext = useContext(DishContext);
  const addToCart = dishContext.addCart;
  const currency = dishContext.currency;

  const handleReOrder = (name, dishId) => {
    addToCart(dishId);
    toastr.success(`${name} successfully added to your dish cart!`);
  };

  return (
    <Container>
      <StyledTable>
        <Thead>
          <Tr>
            <Th>Image</Th>
            <Th>Title</Th>
            <Th>Description</Th>
            <Th>Category</Th>
            <Th>Price</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map(dish => (
            <Tr
              key={dish._id}
              className="item-box"
              style={{
                boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
                borderRadius: "0.25em"
              }}
            >
              <Td className="td-dish">
                <DishImg src={dish.images[0]} alt="Dish thumbnail" />
              </Td>
              <Td>{dish.title}</Td>
              <Td>{dish.description}</Td>
              <Td>{dish.category}</Td>
              <Td>{currency} {dish.price}</Td>
              <Td>
                <Button
                  tertiary
                  onClick={() => handleReOrder(dish.title, dish._id)}
                >
                  Re Order
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </StyledTable>
    </Container>
  );
};

export default FavouritesTable;
