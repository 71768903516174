import React, { useState} from 'react';
import { Button, Modal } from 'antd';
import Display from '../../DisplayPopUp/Display';

import { useUserAuth } from "../Context/AuthUserContext";

const UpdatedLoginMethod = () => {
  const { logOut, Fbuser } = useUserAuth();
  const [showPopup, setShowPopup] = useState(false);
  
  const handleOpenPopup = () => {
   
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

 

  return (
    <>
      { !Fbuser &&(
        <div>
        <Button type="primary" onClick={handleOpenPopup}>
          Sign In
        </Button>
    
      <Modal visible={showPopup} onOk={handleClosePopup} onCancel={handleClosePopup}>
        <Display/>
      </Modal>
      </div>
    )}
    </>
  );
};

export default UpdatedLoginMethod;
