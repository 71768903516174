export default class UserModel {
  constructor(
    uid,
    name,
    email,
    phoneNumber,
    photo,
    country,
    address,
    loginIdentifier,
    fcmToken,
    favouriteDishes
  ) {
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.photo = photo;
    this.country = country;
    this.address = address;
    this.loginIdentifier = loginIdentifier;
    this.fcmToken=fcmToken;
    this.favouriteDishes = favouriteDishes;
  }
}

//================ User Class

export const User = {
  uid: "",
  name: "",
  email: "",
  phoneNumber: "",
  photo: "",
  country:"",
  address:"",
  loginIdentifier:"",
  fcmToken:"",
  favouriteDishes: [],

};

//fields in the document . collection has got document.
//populate the collection user with the field's loginidentifier and uid.