import axios from "axios";          
import { BASE_URL } from "../config/axios-config";

const client = axios.create({
  baseURL: BASE_URL
});
export const getOrdersByChef = id => client.get(`/orders/chef/${id}`);
export const getOrdersByUser = id => client.get(`/orders/user/${id}`);
export const saveOrders = (payload) => client.post(`/orders`, payload);
export const updateOrderStatus = (id, payload) =>
  client.put(`/orders/${id}`, payload);

const OrdersClientApi = {
  getOrdersByChef,
  getOrdersByUser,
  updateOrderStatus,
  saveOrders
};

export default OrdersClientApi;
