import React,{useState} from 'react';
import './LogInForm.css';
import { LockOutlined, MailOutlined, GoogleOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Form, Input} from 'antd';
import { Link,useNavigate} from 'react-router-dom';
import { useUserAuth } from '../../Context/AuthUserContext';



const LogInFormEmail = ({onButtonClick}) => {
    const[email,setEmail] = useState('');
    const[password,setPassword] = useState('');
    
    const[error,setError] = useState('');
    const {logIn,googleSignIn} = useUserAuth()
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
      e.preventDefault();
      setError("");
      try {
        
       await logIn(email, password);
        onButtonClick('close');
      } catch (err) {
        setError(err.message);
      }
    };
    

      const handleGoogleSignIn = async () => {
       
        try {
          await googleSignIn();
          navigate("/");
        } catch (error) {
          console.log(error.message);
        }
      };

      const handleResetPassword = ()=>{

      }
  
  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={handleSubmit}
    >
      <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>Sign In</h1>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your Email!',
            type: 'email',
          },
        ]}
      >
        <Input prefix={<MailOutlined className="site-form-item-icon" />}
         placeholder="Email" 
         value={email}
         onChange={(e)=>(setEmail(e.target.value))} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="default"
          htmlType="submit"
          className="login-form-button"
          onClick={handleSubmit}
        >
          Sign in
        </Button>
      </Form.Item>

      <Form.Item>
        <Button
          type="default"
          icon={<GoogleOutlined />}
          className="login-form-google-button"
          onClick={handleGoogleSignIn}
        >
          Sign in with Google
        </Button>
       
        </Form.Item>
        <Form.Item>
        
        <Button
          type="default"
          icon={<PhoneOutlined />}
          className="login-form-google-button"
          onClick={() => onButtonClick('phone')}
           > 
           Sign in with Phone
        </Button>
        
       <Link onClick={()=>onButtonClick('forgotpassword')}>Forgot Password?</Link>

        <div style={{ textAlign: 'left' }}>
          Don't have an Account?{" "}
          <Link onClick={() =>onButtonClick('signup')}>
            Sign Up
          </Link>
        
        </div>
      </Form.Item>
    </Form>
  )
}

export default LogInFormEmail