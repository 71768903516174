import React, { useState, useEffect } from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  margin: 15px 0;
  position: relative;

  .form-control {
    padding: 0;
  }

  .btn-outline-secondary {
    box-shadow: none;
    :hover {
      color: white;
      background-color: green;
    }
  }

  select {
    font-size: 0.9em !important;
    width: 100%;
    height: calc(1.5em + 0.65rem + 2px);
    padding: 0.35rem 0.75rem;
    border: 0;
    outline: none;
    border-radius: 0.3rem;
  }

  option {
    background-color: green !important;
    color: white !important;
    :checked {
      background: #f09133 !important;
    }
  }

  ${props =>
    props.focused &&
    `
      .input-group-text, .form-control, .btn-outline-secondary {
        border: 1px solid ${props.error ? "red" : "green"} !important;
      }
      .btn-outline-secondary{
        color: ${props.error ? "red" : "green"};
      }
    `}
  @media
    screen
    and
    (max-width: 768px) {
    input {
      font-size: 0.8em !important;
    }
  }
`;

const Label = styled.label`
  color: #757575;
  position: absolute;
  top: 7px;
  left: 15px;
  transition: all 0.2s ease;
  z-index: 500;

  ${props =>
      props.focused &&
      `
      top: 12px;
      font-size: 13px;
      transform: translateY(-23px) translateX(-5px);
      z-index: 501;
      background: white;
      padding: 0 8px;
      font-weight: 500;
      color: ${props.error ? "red" : "#f09133"};
    `}
    :after {
    ${props =>
      props.focused &&
      props.required &&
      !props.error &&
      ` content: '*';
          color:red;
      `}
  }
`;

/**
 * A custom input component
 * @param {string} label - the label used to designate info on how to fill out the input
 * @param {string} value - the value of the current selected field
 * @param {array} options - the array value for the options to be selected
 * @param {function} onChange - function called when the input value changes
 * @param {function} onFocus - function called when the input is focused
 * @param {function} onBlur - function called when the input loses focus
 * @param {function} setRef - function used to add this input as a ref for a parent component
 */
const Dropdown = ({
  value,
  label,
  options,
  onChange,
  onFocus,
  onBlur,
  setRef,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState("");

  const handleOnFocus = e => {
    setFocused(true);
    onFocus();
  };

  useEffect(() => {
    if (value === null || value === "") {
      setSelected("");
      return;
    }
    setSelected(value);
  }, [value]);

  const handleOnBlur = e => {
    setFocused(false);
    onBlur();
  };

  const handleOnChange = e => {
    validateValue(e);
    onChange(e);
  };

  const validateValue = e => {
    e.preventDefault();
    let value = e.target.value;
    if (props.required && !value) {
      setError(`${label} is required.`);
    } else {
      setError(null);
    }
  };

  const renderLabel = () => {
    if (label) {
      return (
        <Label focused={isFocused} error={error} required={props.required}>
          {error ? error : label}
        </Label>
      );
    }
    return null;
  };

  const isFocused = focused || String(selected).length; // || value !== null;  ;

  return (
    <InputContainer focused={isFocused} error={error} required={props.required}>
      <div className="input-group">
        <div className="form-control">
          {renderLabel()}
          <select
            value={selected || ""}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            ref={ref => setRef(ref)}
            {...props}
          >
            <option></option>
            {options &&
              options.length !== 0 &&
              options.map((val, i) => (
                <option key={i} value={val}>
                  {val}
                </option>
              ))}
          </select>
        </div>
      </div>
    </InputContainer>
  );
};

Dropdown.defaultProps = {
  value: "",
  label: "",
  options: [],
  onChange: text => {
    console.error(`Missing onChange prop: ${text}`);
  },
  onFocus: () => {},
  onBlur: () => {},
  setRef: () => {}
};

export default Dropdown;
