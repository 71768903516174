import React, { useState, useContext, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate} from "react-router-dom";
import Login from "./LoginComponent";
import chefIcon from "../../assets/chef.png";
import styled from "styled-components";
import { DishContext } from "../../providers/DishProvider";
import { useUserAuth } from "../Context/AuthUserContext";
import TermsConditions from "../TermsAndConditions/TermsConditions";

const StyledDropdown = styled(Dropdown)`
  *:hover,
  *:focus,
  *:active {
    outline: none;
    box-shadow: none !important;
    -webkit-appearance: none;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background: green;
  }
`;

const SignInLabel = styled.span`
  font-size: 80%;
  color: #f09133;
  cursor: pointer;
  font-weight: 600;
  margin-right: 5px;
  transition: all 0.2s;
  padding: 0.9em 0.2em;
  border-radius: 0.3em;

  :hover {
    color: white;
    background-color: green;
  }

  > img {
    margin-right: 5px;
  }
`;

function UserMenu() {
  
  const [modalOpen, isModalOpen] = useState(false);

  const { Fbuser} = useUserAuth();
 
  const {logOut} = useUserAuth();
  const value = useContext(DishContext);
  const [cart, setCart] = value.cart;
  const navigate = useNavigate()
  // const { logOut } = useUserAuth();
  useEffect(() => {
    if (Fbuser) {
      isModalOpen(false);
    }
  }, [Fbuser]);

  

  const handleLogOut = async() => {
    try {
      await logOut();
      setCart([]);
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      {Fbuser ? (
        <StyledDropdown>
          <Dropdown.Toggle variant={Fbuser ? "success" : null}>
            {Fbuser.photoURL != null ? (
              <>
                <img
                  style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                  src={Fbuser.photoURL}
                  alt="icon"
                />
              </>
            ) : (
              <>
                <img src={chefIcon} alt="icon" />
              </>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <b>{Fbuser.displayName} </b>
              <i className="fas fa-user-alt" />
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/profile")}>
              Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/my-orders")}>
              My Orders
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/my-kitchen")}>
              My Kitchen
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/my-messages")}>
              My Messages
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/terms-condition")}>
              Terms and Conditions
            </Dropdown.Item>
            <Dropdown.Item
              // onClick={() => {
              //   UserService.signOutUser();
              //   setCart([]);
              //   navigate("/");       //replace history.replace with navigate
              // }}
              onClick={handleLogOut}
            >
              Sign out
            </Dropdown.Item>
          </Dropdown.Menu>
        </StyledDropdown>
      ) : (
        <>
    
          <SignInLabel 
          onClick={(e) => {isModalOpen(!modalOpen);
          console.log("Existing Login Component",JSON.stringify(e))
          }}>
            <img src={chefIcon} alt="icon" /> Sign in
          </SignInLabel>
          <Login modalOpen={modalOpen} isModalOpen={isModalOpen} />
        </>
      )}
    </>
  );
}

export default UserMenu;