import { initializeApp } from "firebase/app";
import { getAuth,RecaptchaVerifier,updateProfile} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase,ref} from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";
import { GoogleAuthProvider } from "firebase/auth";

// export const firebaseConfig = {
//   apiKey: "AIzaSyDkq3uQxKiGSPXERrytKSfSlLhu0A1Poxc",
//   authDomain: "khlogin-01.firebaseapp.com",
//   databaseURL: "https://khlogin-01.firebaseio.com",
//   projectId: "khlogin-01",
//   storageBucket: "khlogin-01.appspot.com",
//   messagingSenderId: "405781304253",
//   appId: "1:405781304253:web:76a22701b292cd875978d4",
//   measurementId: "G-3VPBF38BJZ"
// };

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const database = getDatabase(app);
export const messaging = getMessaging(app);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("firebase-messaging-sw.js")
    .then((registration) => {

      // messaging.useServiceWorker(registration);
    });
    
}

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
 
  if (permission === "granted") {
    const token = await getToken(messaging, {
      // vapidKey:
      //   "BA2JWc3ii7A3SuE5IodKuhqT4qh8HEQ_I9EoPgkB5KsKFyAbej2CTUoEfmoaTCox5ddsUBU4mlJO2ksCpjjcxio",
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
   
    return token;
  }
};



export const getVerifier = () => {
  window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
    size: "invisible",
  });
  return window.recaptchaVerifier;
};

export const clearVerifier = () => {
  return window.recaptchaVerifier.clear();
};

export const googleProvider = new GoogleAuthProvider();

export const CHAT_DB = "chat";
export const CHAT_ROOM = `${CHAT_DB}/rooms/`;
export const CHAT_USER = `${CHAT_DB}/users/`;
export const CHAT_MSGS = `${CHAT_DB}/messages/`;
export  const update_Profile = updateProfile;
export { ref };
 








