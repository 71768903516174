import styled from "styled-components";

export const CartHeader = styled.ul`
  list-style-type: none;
  margin: auto;
  width: 90%;
  padding: 0;
  overflow: hidden;
  background-color: #a4d1a2;
`;
export const List = styled.div`
  float: right;
  display: block;
  color: white;
  padding: 0px 15px;
  text-decoration: none;
`;
export const Checkout = styled.div`
  background-color: #ff8c00;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin: 2px 1px;
  cursor: pointer;
`;

export const Remove = styled.button`
  background-color: grey;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 2px 1px;
  cursor: pointer;
`;
export const Increament = styled.button`
  background-color: #ff8c00;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 2px 1px;
  cursor: pointer;
`;
export const Decreament = styled.button`
  background: #008a32;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 2px 1px;
  cursor: pointer;
`;
export const Title = styled.p`
  color: #808080;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
`;
