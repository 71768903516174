import React, { useState, useEffect } from "react";
import { Camera } from "../camera";
import styled from "styled-components";
import uuid from "react-uuid";
const InputFileContainer = styled.div`
  margin: 15px 0;
  position: relative;

  input {
    font-size: 0.9em !important;
    width: 100%;
    height: calc(1.5em + 0.65rem + 2px);
    padding: 0.35rem 0.75rem;
    border: 0;
    outline: none;
    border-radius: 0.3rem;
  }

  .resetBtn {
    cursor: pointer;
    :hover {
      color: green;
    }
  }

  .file-input {
    display: inline-block;
    position: absolute;
    height: inherit;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }

  .form-control {
    padding: 0;
  }

  .form-control > input:disabled {
    background-color: white;
  }

  .btn-outline-secondary {
    box-shadow: none;
    :hover {
      color: white;
      background-color: green;
    }
  }

  .btn-txt {
    margin-right: 0.5em;
  }

  ${props =>
    props.focused &&
    `
      .input-group-text, .form-control, .btn-outline-secondary {
        border: 1px solid ${props.error ? "red" : "green"} !important;
      }
      .btn-outline-secondary{
        color: ${props.error ? "red" : "green"};
      }
    `}

  @media screen and (max-width: 768px) {
    input {
      font-size: 0.8em !important;
    }
    .btn-txt {
      display: none;
    }
  }
`;

const Label = styled.label`
  color: #757575;
  position: absolute;
  top: 7px;
  left: 15px;
  transition: all 0.2s ease;
  z-index: 500;

  ${props =>
      props.focused &&
      `
      top: 12px;
      font-size: 13px;
      transform: translateY(-23px) translateX(-5px);
      z-index: 501;
      background: white;
      padding: 0 8px;
      font-weight: 500;
      color: ${props.error ? "red" : "#f09133"};
    `}
    :after {
    ${props =>
      props.focused &&
      props.required &&
      !props.error &&
      ` content: '*';
          color:red;
      `}
  }
`;

const PreviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 25%);
  justify-content: left;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, 50%);
  }
`;

const ImgContainer = styled.div`
  background-color: #8fc28f;
  border: 1px dashed green;
  border-radius: 0.25rem;

  img {
    border-radius: 0.25rem;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
`;

const CameraContainer = styled.div`
  background-color: #8fc28f;
  border: 1px dashed green;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
`;
/**
 * A custom input file component
 * @param {string} label - the label used to designate info on how to fill out the input
 * @param {string} name - the name of the controlled input
 * @param {number} limit - the number of images that can be uploaded
 * @param {array} value - the value of the current field
 * @param {boolean} multiple - boolean value if input file can select more than 1 image
 * @param {function} onChange - function called when the input value changes
 * @param {function} onFocus - function called when the input is focused
 * @param {function} onBlur - function called when the input loses focus
 * @param {function} setRef - function used to add this input as a ref for a parent component
 */
const InputFile = ({
  label,
  name,
  value,
  limit,
  multiple,
  collection,
  setCollection,
  onChange,
  onFocus,
  onBlur,
  setRef,
  ...props
}) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [preview, setPreview] = useState([]);
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [count, setCount] = useState(0);

  useEffect(() => {
    // free memory when ever this component is unmounted
    return () => {
      if (preview && preview.length !== 0) {
        for (var i = 0; i < preview.length; i++) {
          URL.revokeObjectURL(preview[i]);
        }
      }
    };
  }, [preview]);

  useEffect(() => {
    if (value && value.length === 0 && count > 0) {
      reset();
    }
  }, [value]);

  const onSelectFile = e => {
    var fileList = selectedFile ? selectedFile : [];
    if (e instanceof Blob) {
      var file = new File([e], uuid(), { type: e.type });
      fileList.push(file);
      handleOnFocus();
    } else {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      fileList = [...fileList, ...e.target.files];
    }
    const MAX_LENGTH = limit ? limit : 0;

    setSelectedFile(fileList);
    if (fileList && fileList.length !== 0) {
      setError(null);
      var objectUrlArr = [];
      for (var i = 0; i < fileList.length; i++) {
        objectUrlArr.push(URL.createObjectURL(fileList[i]));
      }
      setPreview(objectUrlArr);
      setCollection(objectUrlArr)
    }
    if (fileList && fileList.length > MAX_LENGTH) {
      setError(`Cannot upload files more than ${MAX_LENGTH}.`);
    } else {
      setCount(i => i + 1);
      onChange({ [name]: fileList });
    }
  };

  const reset = () => {
    if (props.required) {
      setError(`${label} are required.`);
      setFocused(true);
    } else {
      setError(null);
      setFocused(false);
    }
    setCount(0);
    setCollection([]);
    setSelectedFile(undefined);
    setIsCameraOpen(false);
    setPreview(undefined);
  };

  const handleOnFocus = () => {
    setFocused(true);
    onFocus();
  };

  const handleOnBlur = () => {
    setFocused(selectedFile ? true : false);
    onBlur();
  };

  const renderLabel = () => {
    if (label) {
      return (
        <Label focused={isFocused} error={error} required={props.required}>
          {error ? error : label}
        </Label>
      );
    }
    return null;
  };

  const isFocused = focused;

  return (
    <InputFileContainer
      focused={isFocused}
      error={error}
      required={props.required}
    >
      {isCameraOpen && (
        <CameraContainer>
          <Camera
            onCapture={blob => {
              setIsCameraOpen(false);
              onSelectFile(blob);
            }}
            onClear={() => reset()}
          />
        </CameraContainer>
      )}
      {selectedFile && !isCameraOpen && preview && (
        <PreviewGrid>
          {preview.map((pic, i) => (
            <ImgContainer key={i}>
              <img src={pic} alt={label} />
            </ImgContainer>
          ))}
        </PreviewGrid>
      )}
      <div className="input-group">
        <div className="form-control">
          {renderLabel()}
          <input
            disabled
            placeholder={isFocused ? "Choose file" : ""}
            required={props.required}
            value={
              !selectedFile
                ? ""
                : selectedFile.length > 1
                ? `${selectedFile.length} files`
                : selectedFile[0].name || ""
            }
            type="text"
          />
        </div>
        <div className="input-group-append">
          {limit > 1 ? (
            <span className="input-group-text limit-txt">
              <sub className="btn-txt">max</sub>
              <b>{limit}</b>
            </span>
          ) : null}
          <span
            className={
              selectedFile ? "input-group-text resetBtn" : "input-group-text"
            }
            title={selectedFile ? "Reset" : null}
            onClick={selectedFile ? () => reset() : null}
          >
            <i className={selectedFile ? "fas fa-sync-alt" : "fas fa-images"} />
          </span>
          <button
            title="Browse"
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => setIsCameraOpen(false)}
          >
            <input
              required={props.required}
              className="file-input"
              accept="image/*"
              type="file"
              multiple={multiple ? "multiple" : null}
              onChange={onSelectFile}
            />
            <span className="btn-txt">Browse</span>
            <i className="	fas fa-upload" />
          </button>
          <button
            title="Take photo"
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            disabled={isCameraOpen}
            onClick={() => setIsCameraOpen(!isCameraOpen)}
            className="btn btn-outline-secondary"
            type="button"
          >
            <span className="btn-txt">Take photo</span>
            <i className="fas fa-camera" />
          </button>
        </div>
      </div>
    </InputFileContainer>
  );
};

InputFile.defaultProps = {
  label: "",
  name: "",
  value: [],
  limit: 1,
  multiple: false,
  onChange: text => {
    console.error(`Missing onChange prop: ${text}`);
  },
  setCollection: () => {},
  onFocus: () => {},
  onBlur: () => {},
  setRef: () => {}
};

export default InputFile;