import Cart from "../components/cart/Cart";


export default {
  name: "add to cart",
  component: Cart,
  url: "/add-to-cart",
  exact: true,
  requireAuth: true
};
