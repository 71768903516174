import React, { createContext, useState, useMemo } from "react";
import Spinner from '../components/spinner';

export const SpinnerContext = createContext();

export default function SpinnerProvider(props){
    const [visible, setVisible] = useState(false);

    const spinner = useMemo(() => ({
      show: () => setVisible(true),
      hide: () => setVisible(false),
    }), []);

    return <>
        {visible && <Spinner/>}
        <SpinnerContext.Provider value={spinner}>
            {props.children}
        </SpinnerContext.Provider>
    </>;
};
