import React from 'react';
import './TermsReviewConditions.css'

const TermsConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>

      <h2>I. Acceptance of Terms</h2>
      <p>
        Thank you for using Colab Kitchen. These Terms of Service (the "Terms")
        are intended to make you aware of your legal rights and responsibilities
        with respect to your access to and use of the Colab Kitchen website at
        colabkitchen.com (the "Site") and any related mobile or software
        applications ("Colab Kitchen Platform") including but not limited to
        delivery of information via the website whether existing now or in the
        future that link to the Terms (collectively, the "Services").
      </p>
      <p>
        <strong>
          These Terms are effective for all existing and future Colab Kitchen
          customers, including but without limitation to users having access to
          'home restaurant business page' to manage their claimed business
          listings.
        </strong>
      </p>
      <p>
        Please read these Terms carefully. By accessing or using the Colab
        Kitchen Platform, you agree to these Terms and conclude a legally
        binding contract with Colab Kitchen Limited. You may not use the
        Services if you do not accept the Terms or are unable to be bound by the
        Terms. Your use of the Colab Kitchen Platform is at your own risk,
        including the risk that you might be exposed to content that is
        objectionable or otherwise inappropriate.
      </p>
      <ul>
        <li>
          Clicking to accept or agree to the Terms, where it is made available
          to you by Colab Kitchen in the user interface for any particular
          Service, or
        </li>
        <li>
          Actually using the Services. In this case, you understand and agree
          that Colab Kitchen will treat your use of the Services as acceptance
          of the Terms from that point onwards.
        </li>
      </ul>

      <h2>II. Definitions</h2>
      <h3>Customer</h3>
      <p>
        "Customer" or "You" or "Your" refers to you, as a customer of the
        Services. A customer is someone who accesses or uses the Services for
        the purpose of sharing, displaying, hosting, publishing, transacting, or
        uploading information or views or pictures and includes other persons
        jointly participating in using the Services, including without
        limitation a user having access to 'restaurant business page' to manage
        claimed business listings or otherwise.
      </p>

      <h3>Content</h3>
      <p>
        "Content" will include (but is not limited to) reviews, images, photos,
        audio, video, location data, nearby places, and all other forms of
        information or data. "Your content" or "Customer Content" means content
        that you upload, share, or transmit to, through, or in connection with
        the Services, such as likes, ratings, reviews, images, photos, messages,
        chat communication, profile information, or any other materials that you
        publicly display or displayed in your account profile. "Colab Kitchen
        Content" means content that Colab Kitchen creates and makes available in
        connection with the Services, including, but not limited to, visual
        interfaces, interactive features, graphics, design, compilation,
        computer code, products, software, aggregate ratings, reports, and other
        usage-related data in connection with activities associated with your
        account and all other elements and components of the Services excluding
        Your Content and Third-Party Content. "Third-Party Content" means
        content that comes from parties other than Colab Kitchen and its
        customers, such as restaurant partners, and is available on the
        Services.
      </p>

      <h3>Restaurant(s)</h3>
      <p>
        "Restaurant" means the restaurants listed on the Colab Kitchen
        Platform.
      </p>

      <h2>III. Eligibility to Use the Services</h2>
      <ol>
        <li>
          You hereby represent and warrant that you are at least eighteen (18)
          years of age or above and are fully able and competent to understand
          and agree to the terms, conditions, obligations, affirmations,
          representations, and warranties set forth in these Terms.
        </li>
        <li>
          Compliance with Laws. You comply with all laws and regulations in the
          country in which you live when you access and use the Services. You
          agree to use the Services only in compliance with these Terms and
          applicable law, and in a manner that does not violate our legal rights
          or those of any third party(ies).
        </li>
      </ol>

      <h3>Ownership of Colab Kitchen Content and Proprietary Rights</h3>
      <p>
        We are the sole and exclusive copyright owners of the Services and our
        Content. We also exclusively own the copyrights, trademarks, service
        marks, logos, trade names, trade dress, and other intellectual and
        proprietary rights throughout the world (the "IP Rights") associated
        with the Services and Colab Kitchen Content, which may be protected by
        copyright, patent, trademark, and other applicable intellectual property
        and proprietary rights and laws. You acknowledge that the Services
        contain original works and have been developed, compiled, prepared,
        revised, selected, and arranged by us and others through the application
        of methods and standards of judgment developed and applied through the
        expenditure of substantial time, effort, and money and constitutes
        valuable intellectual property of us and such others. You further
        acknowledge that the Services may contain information designated as
        confidential by Colab Kitchen and that you shall not disclose such
        information without Colab Kitchen’s prior written consent.
      </p>

      <h3>Content Removal</h3>
      <p>
        We reserve the right, at any time and without prior notice, to remove,
        block, or disable access to any Content that we, for any reason or no
        reason, consider to be objectionable, in violation of the Terms or
        otherwise harmful to the Services or our Customers in our sole
        discretion. Subject to the requirements of applicable law, we are not
        obligated to return any of Your Content to you under any circumstances.
        Further, the Restaurant reserves the right to delete any images and
        pictures forming part of Customer Content from such Home Restaurant's
        listing page at its sole discretion.
      </p>

      <h2>IV. Food Ordering and Delivery</h2>
      <p>
        <strong>a. </strong>Colab Kitchen provides food ordering and delivery
        services by entering into contractual arrangements with restaurant
        partners ("Restaurant Partners") and Stores (as defined below) on a
        principal-to-principal basis for the purpose of listing their menu items
        or the Products (as defined below) for food ordering and delivery by the
        Customers on the Colab Kitchen Platform.
      </p>
      <p>
        <strong>b. </strong>The Customers can access the menu items or Products
        listed on the Colab Kitchen Platform and place orders against the
        Restaurant Partner(s)/Store(s) through Colab Kitchen.<br></br>
        
        <strong>Price for each item normally would be between Rs2/- to Rs 500/-</strong>
       
      </p>
      <p>
        <strong>c. </strong>Your request to order food and beverages or Products
        from a Restaurant Partner or a Store page on the Colab Kitchen Platform
        shall constitute an unconditional and irrevocable authorization issued
        in favor of Colab Kitchen to place orders for food and beverages or
        Products against the Restaurant Partner(s)/Store(s) on your behalf.
      </p>
      <p>
        <strong>d. </strong>Delivery of an order placed by you through the Colab
        Kitchen Platform may either be undertaken directly by the Restaurant
        Partner or the Store against whom you have placed an order or
        facilitated by Colab Kitchen through a third party who may be available
        to provide delivery services to you (“Delivery Partners”). In both these
        cases, Colab Kitchen is merely acting as an intermediary between you and
        the Delivery Partners, or you and the Home Restaurant Partner or the
        Store, as the case may be.<br></br>

        <strong>Delivery may take 5 mins to 2 hrs depending on various factors 
        like traffic, weather and status of cooks.</strong>
      
       </p>
      <p>
        <strong>e. </strong>The acceptance by a Delivery Partner of undertaking
        the delivery of your order shall constitute a contract of service under
        the Consumer Protection Act, 2019 or any successor legislation between
        you and the Delivery Partner, to which Colab Kitchen is not a party
        under any applicable law. It is clarified that Colab Kitchen does not
        provide any delivery or logistics services and only enables the delivery
        of food and beverages or Products ordered by the Customers through the
        Colab Kitchen Platform by connecting the Customers with the Delivery
        Partners or the Restaurant Partners or the Store, as the case may be.
        Colab Kitchen shall not be liable for any acts or omissions on the part
        of the Delivery Partner, including deficiency in service, wrong delivery
        of order, time taken to deliver the order, order package tampering, etc.
      </p>

      <h2>V. Cancellation and Refund Policy</h2>
      <ol>
        <li>
          <p>
            You acknowledge that (1) your cancellation, or attempted or
            purported cancellation, of an order or (2) cancellation due to any
            reasons not attributable to Colab Kitchen, a Restaurant Partner, or
            a Delivery Partner, shall amount to a breach of your unconditional
            and irrevocable authorization in favor of Colab Kitchen to place
            that order against the Restaurant Partner(s)/Store(s) on your behalf
            (“Authorization Breach”). In the event you commit an Authorization
            Breach, you shall be liable to pay the liquidated damages of an
            amount equivalent to the order value.You hereby authorize Colab Kitchen 
            to deduct or collect the amount payable as liquidated damages through such 
            means as Colab Kitchen may determine in its discretion, including without 
            limitation, by deducting such amount from any payment made towards your next Order
          </p>
        </li>
        <li>
          <p>
           There may be cases where Colab Kitchen is either unable to
           accept your order or cancels the order, due to reasons including without
           limitation, technical errors, unavailability of the item(s) ordered, or
           any other reason attributable to Colab Kitchen, Restaurant Partner/Store or 
           Delivery Partner. In such cases, Colab Kitchen shall not charge a cancellation 
           charge from you. If the order is cancelled after payment has been charged and 
           you are eligible for a refund of the order value or any part thereof, 
           the said amount will be reversed to you.
          </p>
        </li>
        <li>
          <p>
          No replacement / refund / or any other resolution 
          will be provided without Restaurant Partner’s/Store(s)’ permission.
          </p>
        </li>
        <li>
          <p>
          Any complaint, with respect to the order which shall 
          include instances but not be limited to food spillage, foreign objects in 
          food, delivery of the wrong order or food and beverages or Products, poor 
          quality, You will be required to share the proof of the same before any 
          resolution can be provided.
          </p>
        </li>
        <li>
          <p>
           You shall not be entitled to a refund in case instructions
           placed along with the order are not followed in the form and manner You had 
           intended. Instructions are followed by the Restaurant Partner /Store 
           on a best-efforts basis.
          </p>
        </li>
        <li>
        <p>
          All refunds shall be processed in the same manner 
          as they are received, unless refunds have been provided to You in the 
          form of credits, refund amount will reflect in your account based on 
          respective banks policies.<br></br> 

          <strong>Refund will be credited to the customer in 10 days time.</strong>
          
        </p> 
        </li>
        
      </ol>
      
      <h2>VI. Contact Information</h2>
       <p>
        Contact: 9599534149<br></br>
        Address: Kudiary , Jatni, Khurdha, Odisha 752050<br></br>
        Email: biswojit@keybytesystems.com<br></br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pronab@keybytesystems.com <br></br>
        Services : Providing food delivery service on web and mobile app platform.
        </p>
    
    </div>
  );
};

export default TermsConditions;
