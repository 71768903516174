export default class Dish {
  constructor(
    title,
    description,
    category,
    address,
    country,
    city,
    location,
    images,
    ingredients,
    instructions,
    nutrients,
    calories,
    price,
    currency,
    cookTime,
    createdAt,
    updatedAt,
    publishDate,
    chefId,
    status,
    LikedBy,
    Likes
  ) {
    this.title = title;
    this.description = description ? description : "";
    this.category = category ? category : "";
    this.address = address ? address : "";
    this.country = country ? country : "";
    this.city = city ? city : "";
    this.location = location ? location : { lat: "", lng: "" };
    this.images = images ? images : [];
    this.ingredients = ingredients ? ingredients : [];
    this.instructions = instructions ? instructions : [];
    this.nutrients = nutrients ? nutrients : { proteins: 0, carbs: 0, fats: 0 };
    this.calories = calories ? calories : "";
    this.price = price ? price : "";
    this.currency = currency ? currency : "";
    this.cookTime = cookTime ? cookTime : "";
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.publishDate = publishDate ? publishDate : "";
    this.chefId = chefId ? chefId : "";
    this.status = status;
    this.LikedBy = LikedBy ? LikedBy : [];
    this.Likes = Likes ? Likes : 0;
  }
}
//================ Dish Class

export const DishModel = {
  _id: "",
  title: "",
  description: "",
  category: "",
  address: "",
  country: "",
  city: "",
  location: {
    lat: "",
    lng: ""
  },
  images: [],
  chef: {
    id: "",
    name: "string",
   
  },
  ingredients: [],
  instructions: [],
  nutrients: {
    proteins: 0,
    carbs: 0,
    fats: 0
  },
  calories: "",
  price: "",
  currency:"",
  createdAt: "",
  updatedAt: "",
  publishDate: "",
  status: "",
  LikedBy: [], 
  Likes: 0
};
