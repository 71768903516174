import React, { useEffect, useContext, useState } from "react";
import { DishContext } from "../../providers/DishProvider";
import { SpinnerContext } from "../../providers/SpinnerProvider";
import DishMap from "../dish/dish-map";
import DishGrid from "../dish/dish-grid";
import DishService from "../../services/DishService";
import UserService from "../../services/UserService";
import SearchBar from "./search-bar";
import DishFilter from "./search-bar/DishFilterComponent";
import { DISH_STATUS } from "../../constants/Dish.constants";
import styled from "styled-components";
import PaginationComponent from "./pagination";

const FilterMapDiv = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  > div {
    vertical-align: top;
    display: inline-block;
    transition: 0.5s;
  }
  .filterDiv {
    ${props => ` width: ${props.filterOn ? "35%" : "0%"};`}
    height:auto;
    background-color: white;
  }
  .mapDiv {
    ${props => `  
      width: ${props.filterOn ? "65%" : "100%"};
      border-left: ${props.filterOn ? "1px solid rgba(0,0,0,.2)" : "0"}; ;
    `}
  }
  @media screen and (max-width: 768px) {
    > div {
      float: none;
      margin: 0px;
    }
    .filterDiv {
      ${props => `  width: ${props.filterOn ? "100%" : "0"};`}
    }
    .mapDiv {
      width: 100%;
    }
  }
`;

function LandingPage() {
  const value = useContext(DishContext);
  const [dishes, setDishes] = value.dishes;
  const [refresh] = value.refresh;
  const [text, setText] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [Location, setLocation] = useState(null);
  const [filter, setFilter] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [filterState, setFilterState] = useState({
    filterOn: false,
    location: { lat: 0, lng: 0 },
    allDishes: []
  });
  const spinner = useContext(SpinnerContext);
  useEffect(() => {
    setText(null);
    setPageNumber(0);
    setFilter(!filter);
    setLocation(null);
  }, [refresh]);

  useEffect(() => {
    (async () => {
      spinner.show();
      const data = await DishService.getAllDishes(
        { search: text, pageNumber: pageNumber, location: Location },
        spinner
      );

      spinner.hide();
      setTotalPage(data ? data.totalPage : 0);
      const location = await UserService.getUserLocation();
      if (data !== null) {
        const filteredData = data.dishData.filter(
          items => items.status === DISH_STATUS.APPROVED
        );

       
        setFilterState({
          ...filterState,
          ...{ location: location, allDishes: filteredData }
        });
        setDishes(filteredData);
      } else {
        setDishes([]);
      }
    })();
  }, [spinner, filter, pageNumber, Location]);

  
  return (
    <>
      <SearchBar
        filterOn={filterOn}
        setFilterOn={setFilterOn}
        setText={setText}
        setFilter={setFilter}
        filter={filter}
        setPageNumber={setPageNumber}
        totalPage={totalPage}
      />
   <DishGrid dishes={dishes}></DishGrid>
      {dishes.length > 0 && (
        <PaginationComponent
          dishLength={totalPage}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      )}
    </>
  );
}

export default LandingPage;
